import {
  createContext,
  useContext,
  useState,
  useLayoutEffect,
  useEffect
} from "react";

import { useColorMode } from "@chakra-ui/react";


// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import Cookies from "js-cookie";
import manager from "helpers/manager";
// import {  useNavigate } from "react-router-dom";

// The Material Dashboard 2 PRO React main context
const AppContext = createContext();

// Setting custom name for the context which is visible on react dev tools
AppContext.displayName = "AppContext";

function AppProvider({ children }) {
  //temporay local consts
  // const navigate = useNavigate();
  //define states
  const [isAuthenticated, setIsAuthenticated] = useState(0);
  const [userProfile, setUserProfile] = useState(null);
  const [displayTest, setDisplayTest] = useState(false);
  // color modes
  const { colorMode, toggleColorMode } = useColorMode();

  // temp state
  const [tempState, setTempState] = useState(null)
  const [newOption, setNewOption] = useState(null);
  // search states
  const [query, setQuery] = useState('');
  const [activeOnly, setActiveOnly] = useState(false);
  const [peopleResults, setPeopleResults] = useState([]);
  const [unionResults, setUnionResults] = useState([]);
  const [searchResults, setSearchResults] = useState({ people: [], unions: [] });
  const [searchQuery, setSearchQuery] = useState('');
  const [favouritesResults, setFavouritesResults] = useState([]);
  const [membersResults, setMembersResults] = useState([]);
  const [starAction, setStarAction] = useState({})
  const [personDetails, setPersonDetails] = useState([]);
  const [creditsDetails, setCreditsDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true)
  const [isMemberOf, setIsMemberOf] = useState([]);
  const [updateStatus, setUpdateStatus] = useState("");
  const emptyAllStates = () => {

    setIsMemberOf(null)
    setMembersResults([])
    setIsAuthenticated(0);
    setSearchQuery('');
    setUserProfile(null);
    setDisplayTest(false);
    setNewOption(null);
    setQuery(null);
    setActiveOnly(false);
    setPeopleResults(null);
    setUnionResults(null);
    setFavouritesResults(null);
    setSearchResults({ people: [], unions: [] });
    Cookies.remove("token");
  }

  const states = {
    creditsDetails,
    personDetails,
    isLoading,
    isMemberOf,
    starAction,
    membersResults,
    favouritesResults,
    searchQuery,
    colorMode,
    isAuthenticated,
    userProfile,
    displayTest,
    tempState,
    newOption,
    query,
    activeOnly,
    peopleResults,
    unionResults,
    searchResults,
    updateStatus,
  };

  const actions = {
    setCreditsDetails,
    setPersonDetails,
    setIsLoading,
    setIsMemberOf,
    setStarAction,
    setMembersResults,
    setFavouritesResults,
    toggleColorMode,
    setIsAuthenticated,
    setUserProfile,
    setDisplayTest,
    setTempState,
    emptyAllStates,
    setNewOption,
    setQuery,
    setActiveOnly,
    setPeopleResults,
    setUnionResults,
    setSearchResults,
    setSearchQuery,
    setUpdateStatus,
  };



  // useEffect(() => {
  //   const storedUserProfile = localStorage.getItem('UserProfile');
  //   if (storedUserProfile) {
  //     setUserProfile(JSON.parse(storedUserProfile));
  //     console.log(userProfile);

  //   }
  // }, []);

  // Update localStorage whenever user profile changes
  useEffect(() => {
    if (userProfile) {
      localStorage.setItem('userProfile', JSON.stringify(userProfile));
    }
    // console.log(userProfile);
  }, [userProfile]);

  // Trying to fetch data before rendering
  useLayoutEffect(() => {
    let fetchData;
    fetchData = async () => {
      const UserProfile_resp = await manager.getProfile();
      const UserProfile = await UserProfile_resp;

      setUserProfile(await UserProfile.json())
    };
    if (Cookies.get("token")) {
      fetchData();
    }
  }, [isAuthenticated]);

  const value = { states, actions };
  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}

function useApp() {
  const context = useContext(AppContext);
  return context;
}

// Typechecking props for the MaterialUIControllerProvider
AppProvider.propTypes = {
  children: PropTypes.node.isRequired // Update prop-types validation
};

export { AppProvider, useApp };
