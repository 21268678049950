import Cookies from "js-cookie";
import { backendRoot } from "backendInfo";
const manager = {
  ///USERS
  signIn: (email, password) => {
    const form = new FormData();

    form.append("email", email);
    form.append("password", password);
    return fetch(`${backendRoot}/account/auth/`, {
      method: "POST",
      headers: {
        Accept: "application/json",
      },
      body: form,
    });
  },
  resetPasswordEmail: (email) => {
    const form = new FormData();

    form.append("email", email);
    return fetch(`${backendRoot}/account/request-reset-email/`, {
      method: "POST",
      headers: {
        Accept: "application/json",
      },
      body: form,
    });
  },
  updatePassword: (password, uidb64, token) => {
    const form = new FormData();

    form.append("password", password);
    form.append("uidb64", uidb64);
    form.append("token", token);
    return fetch(`${backendRoot}/account/password-reset-complete/`, {
      method: "PATCH",
      headers: {
        Accept: "application/json",
      },
      body: form
    })
  },
  getProfile: () => {
    const token = Cookies.get("token");
    return fetch(`${backendRoot}/account/profile/`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    });
  },
  getMembersCount: () => {
    const token = Cookies.get("token");
    return fetch(`${backendRoot}/api/memberships/members_count/`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    });
  },
  getUnions: async (checkRole) => {
    const token = Cookies.get("token");
    const headers = {
      Accept: "application/json",
    };
    if (checkRole === "None" && token) {
      headers.Accept = "application/json";
    } else if (token) {
      headers.Authorization = "Bearer " + token;
    }
    const requestOptions = {
      method: "GET",
      headers: headers,
    };

    return fetch(`${backendRoot}/api/unions/`, requestOptions).then(
      (response) => response.json()
    );
  },

  getProducers: () => {
    const token = Cookies.get("token");
    return fetch(`${backendRoot}/api/memberships/producers/`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    });
  },

  getMembers: (unionId) => {
    const token = Cookies.get("token");
    let url = `${backendRoot}/api/memberships/members/`;
    let method = "GET";

    // If unionId is provided and not null or empty
    if (unionId) {
      url = `${backendRoot}/api/unions/${unionId}/memberships/`;
      method = "POST";
    }

    return fetch(url, {
      method: method,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    });
  },
  updateMembers: async (isActiveStatus, memberId) => {
    const token = Cookies.get("token");
    const form = new FormData();
    form.append("action_type", isActiveStatus);

    return fetch(`${backendRoot}/api/memberships/${memberId}/`, {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
      body: form,
    });
  },
  updateProfileDetaitls: async (id, updatedData) => {
    const token = Cookies.get("token");
    const form = new FormData();

    Object.entries(updatedData).forEach(([key, value]) => {
      if (typeof value === 'object' && value !== null) {
        Object.entries(value).forEach(([subKey, subValue]) => {
          form.append(subKey, subValue);
        });
      } else {
        form.append(key, value);
      }
    });

    return fetch(`${backendRoot}/api/persons/${id}/`, {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
      body: form,
    });
  },
  signUp: (
    email,
    password,
    confirmPassword,
    firstName,
    lastName,
    inviteCode
  ) => {
    const form = new FormData();
    form.append("email", email);
    form.append("password", password);
    form.append("confirm_password", confirmPassword);
    form.append("first_name", firstName);
    form.append("last_name", lastName);
    form.append("invite_code", inviteCode);

    return fetch(`${backendRoot}/account/register/`, {
      // Adjust the URL as needed
      method: "POST",
      headers: {
        Accept: "application/json",
      },
      body: form,
    });
  },

  updateProfile: (first_name, last_name) => {
    const token = Cookies.get("token");
    const form = new FormData();
    form.append("first_name", first_name);
    form.append("last_name", last_name);
    return fetch(`${backendRoot}/account/profile/`, {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
      body: form,
    });
  },
  deleteProfile: () => {
    const token = Cookies.get("token");
    return fetch(`${backendRoot}/account/profile/`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    });
  },
  changePassword: (password, confirm_password) => {
    const form = new FormData();
    form.append("password", password);
    form.append("confirm_password", confirm_password);
    const token = Cookies.get("token");
    return fetch(`${backendRoot}/account/profile/password/`, {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
      body: form,
    });
  },
  combinedSearch: ({
    searchQuery,
    // , active_only: isActive
  }) => {
    const token = Cookies.get("token");
    const queryParams = new URLSearchParams({
      q: searchQuery,
      // active_only: isActive ? 1 : 0,
    }).toString();
    return fetch(`${backendRoot}/api/search/?${queryParams}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    });
  },
  createInvite: (
    unionId,
    inviteeEmail,
    inviteDescription,
    inviteLink,
    membershipType,
    firstName,
    lastName
  ) => {
    const token = Cookies.get("token");
    const form = new FormData();
    form.append("union", unionId);
    form.append("invitee", inviteeEmail);
    form.append("description", inviteDescription);
    form.append("link", inviteLink);
    form.append("status", "1");
    form.append("membership_type", membershipType);
    form.append("firstName", firstName);
    form.append("lastName", lastName);

    return fetch(`${backendRoot}/api/invites/`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
      body: form,
    });
  },
  requestInvite: (
    inviteeEmail,
    inviteDescription,
    membershipType,
    union,
    firstName,
    lastName
  ) => {
    const form = new FormData();
    form.append("invitee", inviteeEmail);
    form.append("description", inviteDescription);
    form.append("status", "2");
    form.append("membership_type", membershipType);
    form.append("union", union);
    form.append("firstName", firstName);
    form.append("lastName", lastName);

    return fetch(`${backendRoot}/api/invites/`, {
      method: "POST",
      headers: {
        Accept: "application/json",
      },
      body: form,
    });
  },
  updateInvite: async (inviteId, action) => {
    const token = Cookies.get("token");
    const form = new FormData();
    form.append("action", action);

    return fetch(`${backendRoot}/api/invites/${inviteId}/`, {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
      body: form,
    });
  },
  getInvites: async (id) => {
    const token = Cookies.get("token");
    return fetch(`${backendRoot}/api/invites/`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    });
  },
  requestUnionAdmin: async (unionId) => {
    // const form = new FormData();
    // form.append("is_active", status);
    const token = Cookies.get("token");
    return fetch(`${backendRoot}/api/unions/${unionId}/join/`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
      // body: form,
    });
  },
  getFavorites: async () => {
    const token = Cookies.get("token");
    return fetch(`${backendRoot}/api/favorites/`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    });
  },

  createFavorite: async (is_active, id) => {
    const form = new FormData();
    form.append("person_receiver", id);
    form.append("is_active", is_active);
    const token = Cookies.get("token");
    return fetch(`${backendRoot}/api/favorites/`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
      body: form,
    });
  },
  updateAvailabilityStatus: async (isAvailable, memberId) => {
    const form = new FormData();
    form.append("is_active", isAvailable);
    const token = Cookies.get("token");
    return fetch(`${backendRoot}/api/persons/${memberId}/activate/`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
      body: form,
    });
  },
  deleteFavorite: async (id) => {
    const token = Cookies.get("token");
    return fetch(`${backendRoot}/api/favorites/${id}/`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    });
  },
  getPersonDetails: async (id) => {
    const token = Cookies.get("token");
    return fetch(`${backendRoot}/api/persons/${id}/`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    });
  },
  getUnionDetails: async (id) => {
    const token = Cookies.get("token");
    return fetch(`${backendRoot}/api/unions/${id}/`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    });
  },
  updateUnionDetaitls: async (id, updatedData) => {
    const token = Cookies.get("token");
    const form = new FormData();

    // Object.entries(updatedData).forEach(([key, value]) => {
    //   form.append(key, value);
    // });
    Object.entries(updatedData).forEach(([key, value]) => {
      if (typeof value === 'object' && value !== null) {
        Object.entries(value).forEach(([subKey, subValue]) => {
          form.append(subKey, subValue);
        });
      } else {
        form.append(key, value);
      }
    });

    return fetch(`${backendRoot}/api/unions/${id}/`, {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
      body: form,
    });
  },
  getCredits: async () => {
    const token = Cookies.get("token");
    return fetch(`${backendRoot}/api/credits/`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    });
  },
  createCredits: async (
    productionID,
    roleID,
    createNewProd,
    createNewRole,
    productionTitle,
    prod_start_year,
    prod_end_year,
    productionRole,
    productionLink,
    productionTitleType,
    credit_start_year,
    credit_end_year,
    currentlyWorking
  ) => {
    const form = new FormData();

    if (createNewProd === true) {
      form.append("create_new_prod", 1);
    }
    if (createNewRole === true) {
      form.append("create_new_role", 1);
    }

    if (productionID) {
      form.append("production_id", productionID);
    }
    if (roleID) {
      form.append("role_id", roleID);
    }
    if (productionRole) {
      form.append("my_role", productionRole);
    }
    if (prod_start_year && prod_end_year) {

      form.append("prod_start_year", prod_start_year);
      form.append("prod_end_year", prod_end_year);

    } else if (prod_start_year) {
      form.append("prod_start_year", prod_start_year);

    } else if (prod_end_year) {
      form.append("prod_end_year", prod_end_year);

    }
    if (credit_start_year && credit_end_year) {
      form.append("credit_start_year", credit_start_year);
      form.append("credit_end_year", credit_end_year);

    } else if (credit_start_year) {
      form.append("credit_start_year", credit_start_year);
    }
    if (currentlyWorking === true) {
      form.append("currently_working", 1);
    } else {
      form.append("currently_working", 0);
    }


    if (
      productionTitle &&
      productionRole &&
      productionTitleType ||
      (productionLink)
    ) {

      form.append("title", productionTitle);
      form.append("link", productionLink);

      form.append("title_type", productionTitleType);
      form.append("my_role", productionRole);

    }

    const token = Cookies.get("token");
    return fetch(`${backendRoot}/api/credits/`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
      body: form,
    });
  },
  UpdateCredits: async (id, productionID,
    roleID,
    createNewProd,
    createNewRole,
    productionTitle,
    prod_start_year,
    prod_end_year,
    productionRole,
    productionLink,
    productionTitleType,
    credit_start_year,
    credit_end_year,
    currentlyWorking
  ) => {
    const form = new FormData();

    if (createNewProd === true) {
      form.append("create_new_prod", 1);
    }
    if (createNewRole === true) {
      form.append("create_new_role", 1);
    }

    if (productionID) {
      form.append("production_id", productionID);
    }
    if (roleID) {
      form.append("role_id", roleID);
    }
    if (productionRole) {
      form.append("my_role", productionRole);
    }
    if (prod_start_year && prod_end_year) {

      form.append("prod_start_year", prod_start_year);
      form.append("prod_end_year", prod_end_year);

    } else if (prod_start_year) {
      form.append("prod_start_year", prod_start_year);

    } else if (prod_end_year) {
      form.append("prod_end_year", prod_end_year);

    }
    if (credit_start_year && credit_end_year) {
      form.append("credit_start_year", credit_start_year);
      form.append("credit_end_year", credit_end_year);

    } else if (credit_start_year) {
      form.append("credit_start_year", credit_start_year);
    }
    if (currentlyWorking === true) {
      form.append("currently_working", 1);
    } else {
      form.append("currently_working", 0);
    }


    if (
      productionTitle &&
      productionRole &&
      productionTitleType ||
      (productionLink)
    ) {

      form.append("title", productionTitle);
      form.append("link", productionLink);

      form.append("title_type", productionTitleType);
      form.append("my_role", productionRole);

    }

    const token = Cookies.get("token");
    return fetch(`${backendRoot}/api/credits/${id}/`, {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
      body: form,
    });
  },
  searchProductions: async (searchQuery) => {
    const token = Cookies.get("token");
    const queryParams = new URLSearchParams({
      q: searchQuery,
    }).toString();
    return fetch(`${backendRoot}/api/search/production/?${queryParams}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    });
  },
  searchRole: async (searchQuery) => {
    const token = Cookies.get("token");
    const queryParams = new URLSearchParams({
      q: searchQuery,
    }).toString();
    return fetch(`${backendRoot}/api/search/role/?${queryParams}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    });
  },
  deleteCredits: async (id) => {
    const token = Cookies.get("token");
    return fetch(`${backendRoot}/api/credits/${id}/`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    });
  },
  getCreditsForPerson: async (id) => {
    const token = Cookies.get("token");
    return fetch(`${backendRoot}/api/credits/${id}/person/`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    });
  },
  createProfilePhoto: async (id, photo) => {
    const token = Cookies.get("token");
    const form = new FormData();
    // form.append('person', id)
    form.append("image", photo);
    return fetch(`${backendRoot}/api/persons/${id}/upload-image/`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
      body: form,
    });
  },
  getPersonUnions: async (id) => {
    const token = Cookies.get("token");

    return fetch(`${backendRoot}/api/persons/${id}/unions/`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    });
  },
  uploadUnionsImage: async (id, profile_image) => {
    const token = Cookies.get("token");
    const form = new FormData();
    form.append("profile_image", profile_image);

    return fetch(`${backendRoot}/api/unions/${id}/upload_image/`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
      body: form,
    });
  }
};

export default manager;
