import { Flex, Spinner } from "@chakra-ui/react";

const Loader = () => {
  return (
    <>
      <Flex h="100%" w="100%" justifyContent="center" alignContent="center" >
        <Spinner
        //   display="flex"
          // justify-content="center"
          // align-items="center"

          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="gray.700"
          size="xl"
        //   marginLeft="auto"
          mt="25%"
        />
      </Flex>
    </>
  );
};
export default Loader;
