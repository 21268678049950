// AutosuggestStyles.js

const autosuggestStyles = {
    input: {
      width: "100%",
      padding: "0.5rem",
      border: "1px solid #CBD5E0", 
      borderRadius: "0.375rem",
      fontSize: "1rem", 
      color: "#1A202C", 
      backgroundColor: "transparent", 
      "&:focus": {
        borderColor: "#3965ff", 
      },
    },
    suggestionsList: {
      listStyleType: "none",
      padding: 15, 
      margin: 0, 
      border: "1px solid #CBD5E0", 
      borderTop: "none",
      borderRadius: "0 0 0.375rem 0.375rem", 
      backgroundColor: "#FFFFFF", 
      boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", 
      zIndex: 1, 
      "& li": {
        transition: "background-color 0.3s ease", 
        "&:hover": {
          backgroundColor: "#EDF2F7", 
          cursor: "pointer", 
        },
      },
    },
  };
  
  export default autosuggestStyles;
  