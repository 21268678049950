import { useState, useEffect } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import { toast } from "react-hot-toast";
// import Cookie from 'js-cookie';
import manager from "helpers/manager";
import DefaultAuth from "layouts/auth/Default";
import illustrationNight from "assets/img/auth/london.jpg";
import illustrationDay from "assets/img/auth/london-day.jpg";
import RequestInviteModal from "components/InviteModal/RequestInviteModa";
function SignUp() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [show, setShow] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const navigate = useNavigate();
  const textColor = useColorModeValue("rgb(77, 146, 154)", "white");
  const handleClick = () => setShow(!show);
  const handleConfirmClick = () => setShowConfirm(!showConfirm);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { onClose } = useDisclosure();
  const [passwordValidationMessage, setPasswordValidationMessage] =
    useState("");
  const location = useLocation();

  const [referenceCode, setReferenceCode] = useState("");
  const [isFirstName, setisFirstName] = useState("");
  const [isLastName, setisLastName] = useState("");
  const [isEmail, setisEmail] = useState("");

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    setReferenceCode(query.get("inviteCode"));
    setisFirstName(query.get("firstName"));
    setisLastName(query.get("lastName"));
    setisEmail(query.get("email"));
  }, [location]);

  const formFields = [
    {
      type: "input",
      label: "Email",
      placeholder: "Your email",
    },
    {
      type: "select",
      label: "Role",
      placeholder: "Select role",
      options: [
        { label: "User", value: "user" },
        { label: "Admin", value: "admin" },
        // Add more options as needed
      ],
    },
  ];

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleInviteSubmit = async ({
    email,
    description,
    membershipType,
    union,
    firstName,
    lastName,
  }) => {
    try {
      const response = await manager.requestInvite(
        email,
        description,
        membershipType,
        union,
        firstName,
        lastName
      );
      if (!response.ok) {
        throw new Error(toast.error("Invite already sent"));
      }
      onClose(); // Close the modal
      toast.success("Invite Request has been Initiated");
    } catch (error) {
      console.error("Error sending invite");
      // toast.success(error.message || "Error sending invite");
      // console.log(error.message, "message");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    const email = e.target[0].value;
    const firstName = e.target[1].value;
    const lastName = e.target[2].value;
    const password = e.target[3].value;
    const confirmPassword = e.target[4].value;
    const inviteCode = e.target[5].value;

    if (password !== confirmPassword) {
      setLoading(false);
      setError("Passwords do not match");
      return;
    }

    try {
      // Correctly pass all required parameters to your signUp method
      const response = await manager.signUp(
        email,
        password,
        confirmPassword,
        firstName,
        lastName,
        inviteCode
      ); // Adjust parameters as needed
      const data = await response.json();

      if (response.ok) {
        navigate("/home"); // Navigate to sign-in page or dashboard after successful sign-up
      } else {
        if (data && data.password && data.password.length > 0) {
          setError(data.password[0]); // Display the first password error
        } else if (data.invite_code == "invite_code is invalid") {
          setError("Invalid Invite Code");
          // toast.error("Invalid Invite Code");
        } else {
          // General error message if the response doesn't fit expected structure
          setError("An error occurred during sign up. Please try again.");
        }
      }
    } catch (error) {
      setError(error.message || "An error occurred during sign up.");
    } finally {
      setLoading(false);
    }
  };
  const validatePassword = (password) => {
    const hasNumeric = /\d/.test(password);
    const hasAlpha = /[a-zA-Z]/.test(password);
    const isAlphanumeric = hasNumeric && hasAlpha;
    const isNotJustNumeric = !/^\d+$/.test(password);

    if (password.length < 8) {
      setPasswordValidationMessage(
        "Password must be at least 8 characters long."
      );
    } else if (!isAlphanumeric || !isNotJustNumeric) {
      setPasswordValidationMessage(
        "Password must be alphanumeric and not just numeric."
      );
    } else {
      setPasswordValidationMessage(""); // Password meets all criteria
    }
  };

  return (
    <DefaultAuth
      illustrationBackgroundDay={illustrationDay}
      illustrationBackgroundNight={illustrationNight}
    >
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w="100%"
        mx={{ base: "auto", lg: "0px" }}
        me="auto"
        h="100%"
        alignItems="start"
        justifyContent="center"
        mb={{ base: "10px", md: "20px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "0", md: "5vh" }}
        flexDirection="column"
      >
        <Box me="auto">
          <Heading color={textColor} fontSize="36px" mb="10px">
            Sign Up
          </Heading>
          <Text
            mb="36px"
            ms="4px"
            color="gray.400"
            fontWeight="400"
            fontSize="md"
          >
            Create your account to get started!
          </Text>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: "100%", md: "420px" }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
          mb={{ base: "10px", md: "auto" }}
        >
          <form onSubmit={handleSubmit}>
            <FormControl>
              <FormLabel color={textColor} mb="8px">
                Email *
              </FormLabel>
              <Input
                isRequired
                variant="auth"
                type="email"
                placeholder="example@mail.com"
                mb="20px"
                value={isEmail}
                onChange={(e) => setisEmail(e.target.value)}
              />
              <FormLabel color={textColor} mb="8px">
                First Name *
              </FormLabel>
              <Input
                isRequired
                variant="auth"
                type="text"
                placeholder="John"
                mb="20px"
                value={isFirstName}
                onChange={(e) => setisFirstName(e.target.value)}
              />
              <FormLabel color={textColor} mb="8px">
                Last Name *
              </FormLabel>
              <Input
                isRequired
                variant="auth"
                type="text"
                placeholder="Doe"
                mb="20px"
                value={isLastName}
                onChange={(e) => setisLastName(e.target.value)}
              />
              <FormLabel color={textColor}>Password *</FormLabel>
              <InputGroup size="md">
                <Input
                  isRequired
                  variant="auth"
                  type={show ? "text" : "password"}
                  placeholder="Min. 8 characters"
                  mb="20px"
                  onChange={(e) => validatePassword(e.target.value)} // Add this line
                />
                <InputRightElement>
                  <Icon
                    _hover={{ cursor: "pointer" }}
                    as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                    onClick={handleClick}
                  />
                </InputRightElement>
              </InputGroup>
              {passwordValidationMessage && (
                <Text color="red" mb="20px">
                  {passwordValidationMessage}
                </Text> // Display validation message
              )}
              <FormLabel color={textColor}>Confirm Password *</FormLabel>
              <InputGroup size="md">
                <Input
                  isRequired
                  variant="auth"
                  type={showConfirm ? "text" : "password"}
                  placeholder="Confirm your password"
                  mb="20px"
                />
                <InputRightElement>
                  <Icon
                    _hover={{ cursor: "pointer" }}
                    as={showConfirm ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                    onClick={handleConfirmClick}
                  />
                </InputRightElement>
              </InputGroup>
              <FormLabel color={textColor}>Reference Code *</FormLabel>
              <Input
                isRequired
                placeholder="Invitation Code"
                variant="auth"
                mb="20px"
                value={referenceCode}
                onChange={(e) => setReferenceCode(e.target.value)}
              />

              <Button
                isLoading={loading}
                type="submit"
                colorScheme="teal"
                w="100%"
                h="50"
                mb="24px"
              >
                Sign Up
              </Button>
              {error && <Text color="red">{error}</Text>}
            </FormControl>
          </form>
          <Flex flexDirection="column" mt="0px">
            <Text color="gray.400" fontSize="14px">
              Already have an account?
              <NavLink to="/auth">
                <Text color={textColor} as="span" ms="5px" fontWeight="500">
                  Sign In
                </Text>
              </NavLink>
            </Text>
          </Flex>
          <Button
            mt="10px"
            colorScheme="teal"
            onClick={openModal}
            w="100%"
            h="50"
          >
            Request an Invite
          </Button>
        </Flex>

        <RequestInviteModal
          isOpen={isModalOpen}
          onClose={closeModal}
          title="Request an Invite"
          onSubmit={handleInviteSubmit}
          submitLabel="Submit Request"
          formFields={formFields}
        />
      </Flex>
    </DefaultAuth>
  );
}

export default SignUp;
