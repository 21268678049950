import { useState, useEffect } from "react";
import React from "react";
import { NavLink, useLocation, Link } from "react-router-dom";
import PropTypes from "prop-types"; // Import PropTypes
import { useNavigate } from "react-router-dom";
// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
  Tooltip,
} from "@chakra-ui/react";
// Custom components
import DefaultAuth from "layouts/auth/Default";
// Assets

import illustrationNight from "assets/img/auth/london.jpg";
import illustrationDay from "assets/img/auth/london-day.jpg";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import Cookie from "js-cookie";
import manager from "helpers/manager";
import { useApp } from "AppContext/AppProvider";

function SignIn({ onSignIn }) {
  const {
    states: { updateStatus },
  } = useApp();
  const ceo = useApp();
  // Chakra color mode
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  //consts or variables
  const navigate = useNavigate();
  const textColor = useColorModeValue("rgb(77, 146, 154)", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue(
    "rgb(77, 146, 154)",
    "secondaryGray.600"
  );
  const textColorBrand = useColorModeValue("rgb(77, 146, 154)", "white");
  const brandStars = useColorModeValue("rgb(77, 146, 154)", "brand.400");
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    if (query.size > 0) {
      ceo.actions.setUpdateStatus(query.get("update_status"));
    }
  }, [location]);

  // Inside the handleSubmit function in SignIn.js
  const handleSubmit = async (e) => {
    e.preventDefault();
    // setLoading(true);
    setError(null);
    const signin = await manager.signIn(e.target[0].value, e.target[1].value);
    const response = await signin.json();
    if (response.access) {
      Cookie.set("token", response.access);
      // setLoading(false);
      navigate("/home");
      onSignIn();
    } else {
      setLoading(false);
      setError("Invalid Credentials");
    }
  };

  //Check if there is token then try to signin
  const token = Cookie.get("token");
  if (token) {
    navigate("/home");
    return null;
  }

  return (
    <DefaultAuth
      illustrationBackgroundDay={illustrationDay}
      illustrationBackgroundNight={illustrationNight}
    >
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w="100%"
        mx={{ base: "auto", lg: "0px" }}
        me="auto"
        h="100%"
        alignItems="start"
        justifyContent="center"
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection="column"
      >
        <Box me="auto">
          <Heading color={textColor} fontSize="36px" mb="10px">
            Sign In
          </Heading>
          <Text
            mb="36px"
            ms="4px"
            color={textColorSecondary}
            fontWeight="400"
            fontSize="md"
          >
            Enter your email and password to sign in!
          </Text>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: "100%", md: "420px" }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
          mb={{ base: "20px", md: "auto" }}
        >
          <form onSubmit={handleSubmit}>
            <FormControl>
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                Email<Text color={brandStars}>*</Text>
              </FormLabel>
              <Input
                isRequired={true}
                variant="auth"
                fontSize="sm"
                ms={{ base: "0px", md: "0px" }}
                type="email"
                placeholder="example@mail.com"
                mb="24px"
                fontWeight="500"
                size="lg"
              />
              <FormLabel
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                display="flex"
              >
                Password<Text color={brandStars}>*</Text>
              </FormLabel>
              <InputGroup size="md">
                <Input
                  isRequired={true}
                  fontSize="sm"
                  placeholder="Min. 8 characters"
                  mb="24px"
                  size="lg"
                  type={show ? "text" : "password"}
                  variant="auth"
                  color="rgb(77, 146, 154)"
                />
                <InputRightElement display="flex" alignItems="center" mt="4px">
                  <Icon
                    color={textColorSecondary}
                    _hover={{ cursor: "pointer" }}
                    as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                    onClick={handleClick}
                  />
                </InputRightElement>
              </InputGroup>
              {/* <Tooltip label="Click to reset password"> */}
              <Link
                to={`/email-verification`}
                style={{
                  color: "blue",
                  cursor: "pointer",
                }}
              >
                <Text
                  ml="5px"
                  mb="24px"
                  fontSize="sm"
                  _hover={{
                    textDecoration: "underline",
                  }}
                >
                  Forgot Password
                </Text>
              </Link>
              {/* </Tooltip> */}

              <Button
                isLoading={loading}
                type="submit"
                fontSize="sm"
                variant="brand"
                fontWeight="500"
                w="100%"
                background="rgb(77, 146, 154)"
                h="50"
                mb="24px"
              >
                Sign In
              </Button>
              {error ? <font color="red">{error}</font> : <font></font>}
            </FormControl>
          </form>
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="start"
            maxW="100%"
            mt="0px"
          >
            <Text color="gray.400" fontWeight="400" fontSize="14px">
              Not registered yet?
              <NavLink to="/register">
                <Text
                  color={textColorBrand}
                  as="span"
                  ms="5px"
                  fontWeight="500"
                >
                  Create an Account
                </Text>
              </NavLink>
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default SignIn;

SignIn.propTypes = {
  onSignIn: PropTypes.func.isRequired, // Validate onSignIn prop as a function
};
