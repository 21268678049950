import { useState, useEffect } from "react";
import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import PropTypes from "prop-types"; // Import PropTypes
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";

// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import DefaultAuth from "layouts/auth/Default";
// Assets

import illustrationNight from "assets/img/auth/london.jpg";
import illustrationDay from "assets/img/auth/london-day.jpg";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import manager from "helpers/manager";
import { useApp } from "AppContext/AppProvider";
import { useParams } from "react-router-dom";

function ResetPassword() {
  // Chakra color mode
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const { uidb64, token } = useParams();

  //consts or variables
  const navigate = useNavigate();
  const textColor = useColorModeValue("rgb(77, 146, 154)", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue(
    "rgb(77, 146, 154)",
    "secondaryGray.600"
  );
  const textColorBrand = useColorModeValue("rgb(77, 146, 154)", "white");
  const brandStars = useColorModeValue("rgb(77, 146, 154)", "brand.400");
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);

  // Inside the handleSubmit function in SignIn.js
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const newPassword = e.target[0].value;
    const confirmPassword = e.target[1].value;

    if (newPassword !== confirmPassword) {
      setError("Passwords do not match");
      setIsLoading(false);
      return;
    } else if (newPassword.length < 8) {
      setError("password must be at least 8 characters");
      setIsLoading(false);
      return;
    }

    setError(null);
    try {
      const data = await manager.updatePassword(newPassword, uidb64, token);
      if (data.ok) {
        toast.success("password updated successfully");
        setIsLoading(false);
        navigate("/auth");
      } else {
        toast.error("Invalid Credentials");
      }
    } catch {
      setError("Invalid Credentials");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <DefaultAuth
      illustrationBackgroundDay={illustrationDay}
      illustrationBackgroundNight={illustrationNight}
    >
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w="100%"
        mx={{ base: "auto", lg: "0px" }}
        me="auto"
        h="100%"
        alignItems="start"
        justifyContent="center"
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection="column"
      >
        <Box me="auto">
          <Heading color={textColor} fontSize="36px" mb="10px">
            Reset Password
          </Heading>
          <Text
            mb="36px"
            ms="4px"
            color={textColorSecondary}
            fontWeight="400"
            fontSize="md"
          >
            Enter your New Password
          </Text>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: "100%", md: "420px" }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
          mb={{ base: "20px", md: "auto" }}
        >
          <form onSubmit={handleSubmit}>
            <FormControl>
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                New Password<Text color={brandStars}>*</Text>
              </FormLabel>
              <InputGroup size="md">
                <Input
                  isRequired={true}
                  fontSize="sm"
                  placeholder="Enter your password"
                  mb="24px"
                  size="lg"
                  type={show ? "text" : "password"}
                  variant="auth"
                  color="rgb(77, 146, 154)"
                />
                <InputRightElement display="flex" alignItems="center" mt="4px">
                  <Icon
                    color={textColorSecondary}
                    _hover={{ cursor: "pointer" }}
                    as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                    onClick={handleClick}
                  />
                </InputRightElement>
              </InputGroup>
              <FormLabel
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                display="flex"
              >
                Confirm Password<Text color={brandStars}>*</Text>
              </FormLabel>
              <InputGroup size="md">
                <Input
                  isRequired={true}
                  fontSize="sm"
                  placeholder="Enter your password"
                  mb="24px"
                  size="lg"
                  type={show ? "text" : "password"}
                  variant="auth"
                  color="rgb(77, 146, 154)"
                />
                <InputRightElement display="flex" alignItems="center" mt="4px">
                  <Icon
                    color={textColorSecondary}
                    _hover={{ cursor: "pointer" }}
                    as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                    onClick={handleClick}
                  />
                </InputRightElement>
              </InputGroup>

              <Button
                isLoading={isLoading}
                type="submit"
                fontSize="sm"
                variant="brand"
                fontWeight="500"
                w="100%"
                background="rgb(77, 146, 154)"
                h="50"
                mb="24px"
              >
                Submit
              </Button>
              {error ? <font color="red">{error}</font> : <font></font>}
            </FormControl>
          </form>
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="start"
            maxW="100%"
            mt="0px"
          >
            <Text color="gray.400" fontWeight="400" fontSize="14px">
              Not registered yet?
              <NavLink to="/register">
                <Text
                  color={textColorBrand}
                  as="span"
                  ms="5px"
                  fontWeight="500"
                >
                  Create an Account
                </Text>
              </NavLink>
            </Text>
          </Flex>
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="start"
            maxW="100%"
            mt="0px"
          >
            <Text color="gray.400" fontWeight="400" fontSize="14px">
              Back To
              <NavLink to="/auth">
                <Text
                  color={textColorBrand}
                  as="span"
                  ms="5px"
                  fontWeight="500"
                >
                  Sign in{" "}
                </Text>
              </NavLink>
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default ResetPassword;
