import React, { useState, useEffect } from "react";
import "assets/css/App.css";
import { Navigate, Route, Routes } from "react-router-dom";
import SignIn from "views/auth/signIn";
import SignUp from "views/auth/signup";
import Auth from "components/Auth";
import Home from "views/home";
import Unions from "views/unions";
import Members from "views/members";
import Producers from "views/producers";
import UserProfile from "views/profile";
import Invites from "views/Invites/invites";
import SidebarComponent from "components/sidebar/SidebarComponent";
import Favourites from "views/Favourites";
import Loader from "components/table/loader";
import ToastContainer from "components/Toast/toast";
import UnionProfilePage from "views/UnionProfilePage/UnionProfilePage";
import EmailConfirmation from "views/auth/emailConfirmation"
import ResetPassword from "views/auth/ResetPassword/ResetPassword";




function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    // Clean up the timer to prevent memory leaks
    return () => clearTimeout(timer);
  }, []);

  const handleSignIn = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  };

  return (
    <>
      {/* Display loader if isLoading is true */}
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <SidebarComponent />
          <ToastContainer />
          <Routes>
            <Route
              path="/"
              element={
                <Auth>
                  <Navigate to="/home" />
                </Auth>
              }
            />

            <Route exact path="/home" element={<Auth><Home /></Auth>} />
            <Route exact path="/auth" element={<SignIn onSignIn={handleSignIn} />} />
            <Route exact path="/email-verification" element={<EmailConfirmation />} />
            <Route exact path="/account/password-reset/:uidb64/:token/" element={<ResetPassword />} />
            <Route exact path="/register" element={<SignUp />} />
            <Route exact path="/members" element={<Auth><Members /></Auth>} />
            <Route exact path="/unions" element={<Auth><Unions /></Auth>} />
            <Route exact path="/producers" element={<Auth><Producers /></Auth>} />
            <Route exact path="/user-profile" element={<Auth><UserProfile /></Auth>} />
            <Route exact path="/person-detail/:id" element={<Auth><UserProfile /></Auth>} />
            <Route exact path="/union-profile/:id" element={<Auth><UnionProfilePage /></Auth>} />
            <Route exact path="/Invites" element={<Auth><Invites /></Auth>} />
            <Route exact path="/favourites" element={<Auth><Favourites /></Auth>} />

            {/* <Route exact path="/rtl" element={<RTLLayout />} /> */}
          </Routes>
        </>
      )}
    </>
  );
}

export default App;
