import { useState } from "react";
import { useApp } from "AppContext/AppProvider";
import { TbMovie } from "react-icons/tb";
import { BsBuildingsFill } from "react-icons/bs";
import { SmallCloseIcon, EmailIcon } from "@chakra-ui/icons";
import {
  Button,
  useBreakpointValue,
  Drawer,
  DrawerContent,
  DrawerBody,
  DrawerOverlay
} from "@chakra-ui/react";
import AdminNavbarLinks from "../navbar/NavbarLinksAdmin";
import {
  Box,
  VStack,
  Text,
  useColorModeValue,
  Heading,
  Flex,
  useDisclosure,
} from "@chakra-ui/react";
import { FaHome, FaUser, FaGlobe, FaUsers } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";

const SideBarForAdmin = ({ ...rest }) => {
  const ceo = useApp();
  const location = useLocation();
  const isMobile = useBreakpointValue({ base: true, md: false });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [selected, setSelected] = useState("Home");

  const handleItemClick = (itemName) => {
    setSelected(itemName);
    onClose();
  };

  let sidebarBg = useColorModeValue("#2D3748", "#1A202C");
  let sidebarTextAndIconColor = "white";

  const closeDrawer = () => {
    setDrawerOpen(onClose());
  };
  const toggleDrawer = () => {
    isDrawerOpen ? onClose() : onOpen();
  };

  if (isMobile) {
    return ceo.states.isAuthenticated === 0 ? (
      ""
    ) : (
      <>
        {closeDrawer && (
          <Box
            bg={sidebarBg}
            // w="16%"
            w="20"
            pos="fixed"
            top="0" left="0"
            h="100%"
          // zIndex="10"

          // {...rest}
          >
            <VStack p="4" justify="space-between" h="full">
              <VStack align="center" w="full">
                <Button
                  onClick={toggleDrawer}
                  bg="#2d3748"
                  color="white"
                  colorScheme="white"
                  fontSize="24px"
                  rounded="none"
                  _hover={{ bg: "#2d3748" }}
                >
                  ☰
                </Button>

                {/* Home Link */}
                <Link
                  to="/home"
                  style={{ textDecoration: "none", width: "100%" }}
                  onClick={() => handleItemClick("Home")}
                >
                  <Flex
                    bg={selected === "Home" ? "teal" : "transparent"}
                    mt="5"
                    mx="auto"
                    justifyContent="center"
                    _hover={{
                      bg: "teal",
                      color: "white",
                    }}
                    p="2"
                    borderRadius="15px"
                  >
                    <FaHome color={sidebarTextAndIconColor} />
                  </Flex>
                </Link>

                {/* Members Link */}
                {/* <Link
                  to="/members"
                  style={{ textDecoration: "none", width: "100%" }}
                  onClick={() => {
                    handleItemClick("Members");
                  }}
                >
                  <Flex
                    mt="5"
                    mx="auto"
                    justifyContent="center"
                    _hover={{
                      bg: "teal",
                      color: "white",
                    }}
                    p="2"
                    borderRadius="15px"
                    bg={selected === "Members" ? "teal" : "transparent"}
                  >
                    <FaUsers color={sidebarTextAndIconColor} />
                  </Flex>
                </Link> */}

                {/* Guides Link */}
                <Link
                  to="/unions"
                  style={{ textDecoration: "none", width: "100%" }}
                  onClick={() => {
                    handleItemClick("Unions");
                  }}
                >
                  <Flex
                    bg={selected === "Unions" ? "teal" : "transparent"}
                    mt="5"
                    mx="auto"
                    justifyContent="center"
                    _hover={{
                      bg: "teal",
                      color: "white",
                    }}
                    p="2"
                    borderRadius="15px"
                  >
                    <BsBuildingsFill color={sidebarTextAndIconColor} />
                  </Flex>
                </Link>

                {/* Procedure Link */}
                <Link
                  to="/producers"
                  style={{ textDecoration: "none", width: "100%" }}
                  onClick={() => {
                    handleItemClick("Procedure");
                  }}
                >
                  <Flex
                    bg={selected === "Procedure" ? "teal" : "transparent"}
                    mt="5"
                    mx="auto"
                    justifyContent="center"
                    _hover={{
                      bg: "teal",
                      color: "white",
                    }}
                    p="2"
                    borderRadius="15px"
                  >
                    <TbMovie color={sidebarTextAndIconColor} />
                  </Flex>
                </Link>
                {/* get Invite link */}
                <Link
                  to="/Invites"
                  style={{ textDecoration: "none", width: "100%" }}
                  onClick={() => {
                    handleItemClick("Invites");
                  }}
                >
                  <Flex
                    bg={selected === "Invites" ? "teal" : "transparent"}
                    mt="5"
                    mx="auto"
                    justifyContent="center"
                    _hover={{
                      bg: "teal",
                      color: "white",
                    }}
                    p="2"
                    borderRadius="15px"
                  >
                    <EmailIcon color={sidebarTextAndIconColor} />
                  </Flex>
                </Link>

                {/* User Profile Link */}
                <Link
                  to="/user-profile"
                  style={{ textDecoration: "none", width: "100%" }}
                  onClick={() => {
                    handleItemClick("Profile");
                  }}
                >
                  <Flex
                    bg={selected === "Profile" ? "teal" : "transparent"}
                    mt="5"
                    mx="auto"
                    justifyContent="center"
                    _hover={{
                      bg: "teal",
                      color: "white",
                    }}
                    p="2"
                    borderRadius="15px"
                  >
                    <FaUser color={sidebarTextAndIconColor} />
                  </Flex>
                </Link>
              </VStack>
              <AdminNavbarLinks open={false} setSelected={setSelected} />
            </VStack>
          </Box>
        )}

        <Drawer isOpen={isOpen} placement="left" onClose={onClose} size="xs">
          <DrawerOverlay />

          <DrawerContent bg={sidebarBg} color="white"  >

            <DrawerBody  >
              <Box bg={sidebarBg} pos="relative" w="95%" h="95%" zIndex={10}>
                <Button
                  onClick={onClose}
                  bg="#2d3748"
                  color="white"
                  fontSize="26"
                  rounded="none"
                  ml="90%"
                >
                  {<SmallCloseIcon />}
                </Button>
                <VStack p="4" justify="space-between" h="full" align="start">
                  <VStack w="full">
                    <Link
                      to="/"
                      style={{
                        textDecoration: "none",
                      }}
                      onClick={() => {
                        handleItemClick("Home");
                      }}
                    >
                      <Flex align="center">
                        <FaGlobe
                          fontSize="25"
                          color={sidebarTextAndIconColor}
                        />
                        <Heading
                          size="md"
                          color={sidebarTextAndIconColor}
                          ml="2"
                        >
                          ReelWorld
                        </Heading>
                      </Flex>
                    </Link>

                    {/* Home Link */}
                    <Link
                      to="/home"
                      style={{
                        textDecoration: "none",
                        width: "100%",
                        outline: "none",
                      }}
                      onClick={() => handleItemClick("Home")}
                    >
                      <Flex
                        mt="5"
                        ml="1"
                        _hover={{
                          bg: "teal",
                          color: "white",
                        }}
                        p="3"
                        mx="4"
                        borderRadius="15px"
                        bg={selected === "Home" ? "teal" : "transparent"}
                      >
                        <FaHome size={16} color={sidebarTextAndIconColor} />
                        <Text
                          fontSize="sm"
                          color={sidebarTextAndIconColor}
                          ml="2"
                        >
                          Home
                        </Text>
                      </Flex>
                    </Link>

                    {/* Members Link */}
                    {/* <Link
                      onClick={() => handleItemClick("Members")}
                      to="/members"
                      style={{ textDecoration: "none", width: "100%" }}
                    >
                      <Flex
                        align="center"
                        mt="5"
                        ml="1"
                        _hover={{
                          bg: "teal",
                          color: "white",
                        }}
                        bg={selected === "Members" ? "teal" : "transparent"}
                        p="3"
                        mx="4"
                        borderRadius="15px"
                      >
                        <FaUsers size={16} color={sidebarTextAndIconColor} />
                        <Text
                          fontSize="sm"
                          color={sidebarTextAndIconColor}
                          ml="2"
                        >
                          Members
                        </Text>
                      </Flex>
                    </Link> */}

                    {/* Guides Link */}
                    <Link
                      onClick={() => handleItemClick("Unions")}
                      to="/unions"
                      style={{ textDecoration: "none", width: "100%" }}
                    >
                      <Flex
                        align="center"
                        mt="5"
                        ml="1"
                        _hover={{
                          bg: "teal",
                          color: "white",
                        }}
                        p="3"
                        mx="4"
                        borderRadius="15px"
                        bg={selected === "Unions" ? "teal" : "transparent"}
                      >
                        <BsBuildingsFill
                          size={16}
                          color={sidebarTextAndIconColor}
                        />
                        <Text
                          fontSize="sm"
                          color={sidebarTextAndIconColor}
                          ml="2"
                        >
                          My Unions
                        </Text>
                      </Flex>
                    </Link>

                    {/* Procedure Link */}
                    <Link
                      onClick={() => handleItemClick("Procedure")}
                      to="/producers"
                      style={{ textDecoration: "none", width: "100%" }}
                    >
                      <Flex
                        align="center"
                        mt="5"
                        ml="1"
                        _hover={{
                          bg: "teal",
                          color: "white",
                        }}
                        p="3"
                        mx="4"
                        borderRadius="15px"
                        bg={selected === "Procedure" ? "teal" : "transparent"}
                      >
                        <TbMovie size={16} color={sidebarTextAndIconColor} />
                        <Text
                          fontSize="sm"
                          color={sidebarTextAndIconColor}
                          ml="2"
                        >
                          Producers
                        </Text>
                      </Flex>
                    </Link>
                    {/* get Invite link */}
                    <Link
                      onClick={() => handleItemClick("Invites")}
                      to="/Invites"
                      style={{ textDecoration: "none", width: "100%" }}
                    >
                      <Flex
                        bg={selected === "Invites" ? "teal" : "transparent"}
                        mt="5"
                        ml="1"
                        _hover={{
                          bg: "teal",
                          color: "white",
                        }}
                        p="3"
                        mx="4"
                        borderRadius="15px"
                      >
                        <EmailIcon
                          size={16}
                          mt="0.5"
                          color={sidebarTextAndIconColor}
                        />
                        <Text
                          fontSize="sm"
                          color={sidebarTextAndIconColor}
                          ml="2"
                        >
                          Invites
                        </Text>
                      </Flex>
                    </Link>

                    {/* User Profile Link */}
                    <Link
                      onClick={() => handleItemClick("Profile")}
                      to="/user-profile"
                      style={{ textDecoration: "none", width: "100%" }}
                    >
                      <Flex
                        bg={selected === "Profile" ? "teal" : "transparent"}
                        mt="5"
                        ml="1"
                        _hover={{
                          bg: "teal",
                          color: "white",
                        }}
                        p="3"
                        mx="4"
                        borderRadius="15px"
                      >
                        <FaUser size={16} color={sidebarTextAndIconColor} />
                        <Text
                          fontSize="sm"
                          color={sidebarTextAndIconColor}
                          ml="2"
                        >
                          My Profile
                        </Text>
                      </Flex>
                    </Link>

                    {/* </VStack> */}
                  </VStack>

                  <AdminNavbarLinks open={true} setSelected={setSelected} />
                </VStack>
              </Box>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </>
    );
  }

  return ceo.states.isAuthenticated === 0 ? (
    ""
  ) : (
    <Box
      bg={sidebarBg}
      borderColor={sidebarBg}
      // w="16%"
      w="60"
      pos="fixed"
      top="0"
      h="full"
    // {...rest}
    // zIndex={10}
    >
      <VStack justify="space-between" h="full" align="stretch">
        <VStack>
          {/* ReelWorld Logo */}

          <Link
            to="/"
            style={{ textDecoration: "none", width: "100%" }}
            onClick={() => {
              handleItemClick("Home");
            }}
          >
            <Flex p="4" align="center" mt="2" ml="1">
              <FaGlobe fontSize="24" color={sidebarTextAndIconColor} />
              <Heading
                size="md"
                textAlign="center"
                color={sidebarTextAndIconColor}
                ml="2"
              >
                ReelWorld
              </Heading>
            </Flex>
          </Link>

          {/* Home Link */}
          <Link
            to="/home"
            style={{ textDecoration: "none", width: "100%" }}
            onClick={() => {
              handleItemClick("Home");
            }}
          >
            <Flex
              bg={selected === "Home" ? "teal" : "transparent"}
              mt="5"
              justifyContent="flex-start"
              _hover={{
                bg: "teal",
                color: "white",
              }}
              _active={{
                bg: "teal",
                color: "white",
              }}
              p="3"
              mx="4"
              borderRadius="15px"
              cursor="pointer"
            >
              <FaHome size={20} color={sidebarTextAndIconColor} />
              <Text fontSize="sm" color={sidebarTextAndIconColor} ml="2">
                Home
              </Text>
            </Flex>
          </Link>

          {/* Members Link */}
          {/* <Link
            to="/members"
            style={{ textDecoration: "none", width: "100%" }}
            onClick={() => {
              handleItemClick("Members");
            }}
          >
            <Flex
              mt="3"
              justifyContent="flex-start"
              bg={selected === "Members" ? "teal" : "transparent"}
              _hover={{
                bg: "teal",
                color: "white",
              }}
              p="3"
              mx="4"
              borderRadius="15px"
              cursor="pointer"
            >
              <FaUsers size={20} color={sidebarTextAndIconColor} />
              <Text fontSize="sm" color={sidebarTextAndIconColor} ml="2">
                Members
              </Text>
            </Flex>
          </Link> */}

          {/* Unions Link */}
          <Link
            to="/unions"
            style={{ textDecoration: "none", width: "100%" }}
            onClick={() => {
              handleItemClick("Unions");
            }}
          >
            <Flex
              mt="3"
              justifyContent="flex-start"
              bg={selected === "Unions" ? "teal" : "transparent"}
              _hover={{
                bg: "teal",
                color: "white",
              }}
              p="3"
              mx="4"
              borderRadius="15px"
              cursor="pointer"
            >
              <BsBuildingsFill size={20} color={sidebarTextAndIconColor} />
              <Text fontSize="sm" color={sidebarTextAndIconColor} ml="2">
                My Unions
              </Text>
            </Flex>
          </Link>

          {/* Procedure Link */}
          <Link
            to="/producers"
            style={{ textDecoration: "none", width: "100%" }}
            onClick={() => {
              handleItemClick("Procedure");
            }}
          >
            <Flex
              mt="3"
              justifyContent="flex-start"
              bg={selected === "Procedure" ? "teal" : "transparent"}
              _hover={{
                bg: "teal",
                color: "white",
              }}
              p="3"
              mx="4"
              borderRadius="15px"
              cursor="pointer"
            >
              <TbMovie size={20} color={sidebarTextAndIconColor} />
              <Text fontSize="sm" color={sidebarTextAndIconColor} ml="2">
                Producers
              </Text>
            </Flex>
          </Link>

          {/* Invites */}
          <Link
            to="/invites"
            style={{ textDecoration: "none", width: "100%" }}
            onClick={() => {
              handleItemClick("Invites");
            }}
          >
            <Flex
              mt="3"
              justifyContent="flex-start"
              bg={selected === "Invites" ? "teal" : "transparent"}
              _hover={{
                bg: "teal",
                color: "white",
              }}
              p="3"
              mx="4"
              borderRadius="15px"
              cursor="pointer"
            >
              <EmailIcon size={20} mt="0.5" color={sidebarTextAndIconColor} />
              <Text fontSize="sm" color={sidebarTextAndIconColor} ml="2">
                Invites
              </Text>
            </Flex>
          </Link>


          {/* User Profile Link */}
          <Link
            to="/user-profile"
            style={{ textDecoration: "none", width: "100%" }}
            onClick={() => {
              handleItemClick("Profile");
            }}
          >
            <Flex
              mt="3"
              justifyContent="flex-start"
              bg={selected === "Profile" ? "teal" : "transparent"}
              _hover={{
                bg: "teal",
                color: "white",
              }}
              p="3"
              mx="4"
              borderRadius="15px"
              cursor="pointer"
            >
              <FaUser size={20} color={sidebarTextAndIconColor} />
              <Text fontSize="sm" color={sidebarTextAndIconColor} ml="2">
                My Profile
              </Text>
            </Flex>
          </Link>
        </VStack>

        <Flex
          justifyContent="flex-start"
          w="100%"
          p="4"
          mx="3"
          cursor="pointer"
        >
          <AdminNavbarLinks open={true} setSelected={setSelected} />
        </Flex>
      </VStack>
    </Box>
  );
};

export default SideBarForAdmin;
