import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  TableContainer,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Avatar,
  Text,
  useDisclosure,
  Flex,
  Tooltip,
  Heading,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import { FaCaretDown } from "react-icons/fa";
import PropTypes from "prop-types";
import { ImStarEmpty } from "react-icons/im";
import { MdStar } from "react-icons/md";
import { toast } from "react-hot-toast";
import Loader from "components/table/loader";
import Navbar from "components/navbar/NavbarAdmin";
import manager from "../../helpers/manager";
import InviteRequestModal from "components/InviteModal/InviteRequestModal";
import { useApp } from "AppContext/AppProvider";
import { SearchResultTable } from "components/table/SearchResultsTable";
import {
  ViewIcon,
  ViewOffIcon,
  DeleteIcon,
  RepeatClockIcon,
} from "@chakra-ui/icons";
import { BsPersonFillCheck } from "react-icons/bs";
import { BsPersonFillDash } from "react-icons/bs";
import { AddIcon } from "@chakra-ui/icons";
import { useNavigate, Link } from "react-router-dom";
import DeleteConfirmationPop from "./DeleteConfirmationPop/DeleteConfirmationPop";
import { backendRoot } from "backendInfo";

const Members = (props) => {
  const { unionId } = props;
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isHidden, setIsHidden] = useState({});
  const [isActive, setIsActive] = useState({});
  const [memberToDelete, setMemberToDelete] = useState(null);
  const [unionAdmins, setUnionAdmins] = useState([]);
  const [unionMembers, setUnionMembers] = useState([]);
  const [isClicked, setIsClicked] = useState(false);
  const {
    isOpen: isDeleteConfirmationOpen,
    onOpen: openDeleteConfirmation,
    onClose: closeDeleteConfirmation,
  } = useDisclosure();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const ceo = useApp();

  const fetchMembersListing = async () => {
    setIsLoading(true);
    try {
      const response = await manager.getMembers(unionId);
      if (!response.ok) throw new Error("Failed to fetch members information");
      const data = await response.json();
      if (unionId) {
        const isUnionAdmin = data.filter(
          (member) => member.person.user.role === "admin"
        );
        setUnionAdmins(isUnionAdmin);
        const isUnionMember = data.filter(
          (member) => member.person.user.role === "member"
        );
        setUnionMembers(isUnionMember);
      }
      ceo.actions.setMembersResults(data);
      const initialIsActive = {};
      data.forEach((member) => {
        initialIsActive[member.id] = member.status == 2;
      });
      setIsActive(initialIsActive);
      const initialIsHidden = {};
      data.forEach((member) => {
        initialIsHidden[member.id] = member.is_hidden;
      });
      setIsHidden(initialIsHidden);
    } catch (error) {
      setError(error.message || "Error fetching members details");
    } finally {
      setIsLoading(false);
    }
  };

  const updateMember = async (newStatus, memberId) => {
    try {
      setIsClicked(true);
      const response = await manager.updateMembers(newStatus, memberId);
      if (response.ok) {
        const data = await response.json();
        if (
          data.status == "Membership hidden successfully" ||
          data.status == "Membership unhidden successfully"
        ) {
          if (data.status == "Membership hidden successfully") {
            toast.success("membership hidden successfully");
          } else if (data.status == "Membership unhidden successfully") {
            toast.success("membership unhidden successfully");
          }

          setIsHidden((prevVisibility) => ({
            ...prevVisibility,
            [memberId]: !prevVisibility[memberId],
          }));
        } else if (
          data.status == "Membership deactivated successfully" ||
          data.status == "Membership activated successfully"
        ) {
          if (data.status == "Membership deactivated successfully") {
            toast.success("membership deactivated successfully");
          } else if (data.status == "Membership activated successfully") {
            toast.success("membership activated successfully");
          }
          setIsActive((prevVisibility) => ({
            ...prevVisibility,
            [memberId]: !prevVisibility[memberId],
          }));
        } else if (data.status == "Reminder sent successfully") {
          toast.success("Reminder sent successfully");
        }
      }
    } catch (error) {
      setError(error.message || "Error fetching members details");
    } finally {
      setIsClicked(false);
    }
  };
  const handleDeleteConfirmation = async () => {
    if (memberToDelete) {
      try {
        const response = await manager.updateMembers("delete", memberToDelete);
        if (response.ok) {
          const data = await response.json();

          if (data.status === "Membership deleted successfully") {
            const updatedMembers = unionMembers.filter(
              (member) => member.id !== memberToDelete
            );
            setUnionMembers(updatedMembers);
            closeDeleteConfirmation();
            setMemberToDelete(null);
            toast.success("Membership deleted successfully");
          }
        }
      } catch (error) {
        console.log(error.message || "Error deleting member");
      }
    }
  };

  const handleButtonAction = (actionType, memberId) => {
    let newStatus = "";

    switch (actionType) {
      case "visibility":
        newStatus = isHidden[memberId] ? "unhide" : "hide";
        updateMember(newStatus, memberId);
        break;

      case "active":
        newStatus = isActive[memberId] ? "inactive" : "active";
        updateMember(newStatus, memberId);
        break;

      case "delete":
        setMemberToDelete(memberId);
        openDeleteConfirmation();
        break;
      case "reminder":
        newStatus = "reminder";
        updateMember(newStatus, memberId);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    fetchMembersListing();
    ceo.actions.setSearchResults({ people: [], unions: [] });
    ceo.actions.setSearchQuery("");
  }, []);

  const handleInviteSubmit = async ({
    email,
    description,
    link,
    unionId,
    membershipType,
    firstName,
    lastName,
  }) => {
    try {
      const response = await manager.createInvite(
        unionId,
        email,
        description,
        link,
        membershipType,
        firstName,
        lastName
      );
      if (!response.ok) {
        const errorText = await response.text();
        try {
          const errorJson = JSON.parse(errorText);
          throw new Error(errorJson.message || "Failed to send invite");
        } catch {
          throw new Error(errorText || "Failed to send invite");
        }
      }

      let inviteDetails = await response.json();

      onClose(); // Close the modal
      toast.success("Invite sent successfully!");
    } catch (error) {
      toast.success(error.message || "Error sending invite");
    }
  };

  const toggleFavourite = async (is_active, id) => {
    const getfavoriteMember = unionMembers.filter(
      (member) => member.person.id === id
    );

    try {
      if (getfavoriteMember[0].is_favorite === false) {
        const response = await manager.createFavorite(is_active, id);
        setUnionMembers((prevMembers) =>
          prevMembers.map((member) => {
            if (member.person.id === id) {
              return { ...member, is_favorite: true };
            }
            return member;
          })
        );

        if (response.ok) {
          const data = await response.json();

          await ceo.actions.setFavouritesResults([data]);
          setUnionMembers((prevMembers) =>
            prevMembers.map((member) => {
              if (member.person.id === id) {
                return { ...member, favorite_id: data.id };
              }
              return member;
            })
          );
        } else {
          console.log("Did not get add favorites response");
        }
      } else {
        const deleteFavorite = unionMembers.filter(
          (member) => member.person.id === id
        );
        const deleteFavoritesID = deleteFavorite[0].favorite_id;

        const response = await manager.deleteFavorite(deleteFavoritesID);
        setUnionMembers((prevMembers) =>
          prevMembers.map((member) => {
            if (member.person.id === id) {
              return { ...member, is_favorite: false };
            }
            return member;
          })
        );

        if (response.ok) {
          console.log("Deleted Favourites successfully");
        } else {
          console.log("Did not Get delete response");
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const hasSearchQuery = ceo.states.searchQuery === "";

  const userProfile = ceo.states.userProfile;
  const checkRole = userProfile ? userProfile.role : null;

  const hasSearchResults =
    ceo.states.searchResults.people.length > 0 ||
    ceo.states.searchResults.unions.length > 0;

  const handleCopyEmail = (email) => {
    navigator.clipboard.writeText(email);
    toast.success("Email copied to clipboard!");
  };

  return (
    <>
      <Navbar />

      <Box marginRight="1%" mt="24">
        {!isLoading ? (
          <>
            {checkRole === "admin" && (
              <>
                <Flex justify="flex-end">
                  <Button colorScheme="teal" onClick={onOpen} mt="4">
                    Send Invite
                  </Button>
                </Flex>
                <InviteRequestModal
                  isOpen={isOpen}
                  onClose={onClose}
                  onSubmit={handleInviteSubmit}
                />
              </>
            )}

            {/* {!hasSearchResults && hasSearchQuery ? ( */}
            <>
              <Heading marginTop="10" as="h2" size="lg">
                Administrators
              </Heading>
              {unionAdmins.length > 0 ? (
                <>
                  <Box overflowX="auto">
                    <TableContainer>
                      <Table
                        variant="simple"
                        size="sm"
                        borderWidth="1px"
                        borderRadius="md"
                        mb="4"
                        borderBottom="1px"
                        borderColor="gray.300"
                        mt="5"
                      >
                        <Thead>
                          <Tr>
                            <Th
                              borderBottom="1px"
                              borderColor="gray.300"
                              textAlign="center"
                              minWidth="150px"
                            >
                              Profile Photo
                            </Th>
                            <Th
                              borderBottom="1px"
                              borderColor="gray.300"
                              textAlign="center"
                              minWidth="150px"
                            >
                              Class
                            </Th>
                            <Th
                              borderBottom="1px"
                              borderColor="gray.300"
                              textAlign="center"
                              minWidth="150px"
                            >
                              Description
                            </Th>
                            <Th
                              borderBottom="1px"
                              borderColor="gray.300"
                              textAlign="center"
                              minWidth="150px"
                            >
                              Name
                            </Th>
                            <Th
                              borderBottom="1px"
                              borderColor="gray.300"
                              textAlign="center"
                              minWidth="150px"
                            >
                              Phone
                            </Th>
                            <Th
                              borderBottom="1px"
                              borderColor="gray.300"
                              textAlign="center"
                              minWidth="150px"
                            >
                              Email
                            </Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {unionAdmins.map((member) => (
                            <Tr key={member.id}>
                              <Td
                                borderBottom="1px"
                                borderColor="gray.300"
                                textAlign="center"
                                minWidth="150px"
                              >
                                <Link
                                  key={member.id}
                                  to={`/person-detail/${member.person.user.id}`}
                                  style={{
                                    textDecoration: "none",
                                    width: "100%",
                                  }}
                                >
                                  <Avatar src={member.person.profile_photo} />
                                </Link>
                              </Td>

                              <Td
                                borderBottom="1px"
                                borderColor="gray.300"
                                textAlign="center"
                                minWidth="150px"
                              >
                                <Link
                                  key={member.id}
                                  to={`/person-detail/${member.person.user.id}`}
                                  style={{
                                    textDecoration: "none",
                                    width: "100%",
                                  }}
                                >
                                  {member.person.job_classification || "N/A"}
                                </Link>
                              </Td>
                              <Td
                                borderBottom="1px"
                                borderColor="gray.300"
                                textAlign="center"
                                minWidth="150px"
                              >
                                <Link
                                  key={member.id}
                                  to={`/person-detail/${member.person.user.id}`}
                                  style={{
                                    textDecoration: "none",
                                    width: "100%",
                                  }}
                                >
                                  {member.person.bio || "N/A"}
                                </Link>
                              </Td>
                              <Td
                                borderBottom="1px"
                                borderColor="gray.300"
                                textAlign="center"
                                minWidth="150px"
                              >
                                <Link
                                  key={member.id}
                                  to={`/person-detail/${member.person.user.id}`}
                                  style={{
                                    textDecoration: "none",
                                    width: "100%",
                                  }}
                                >
                                  {member.person.user.first_name}{" "}
                                  {member.person.user.last_name}
                                </Link>
                              </Td>
                              <Td
                                borderBottom="1px"
                                borderColor="gray.300"
                                textAlign="center"
                                minWidth="150px"
                              >
                                <Link
                                  key={member.id}
                                  to={`/person-detail/${member.person.user.id}`}
                                  style={{
                                    textDecoration: "none",
                                    width: "100%",
                                  }}
                                >
                                  {member.person.phone_number || "N/A"}
                                </Link>
                              </Td>
                              <Td
                                borderBottom="1px"
                                borderColor="gray.300"
                                textAlign="center"
                                minWidth="150px"
                                onClick={() =>
                                  handleCopyEmail(member.person.user.email)
                                }
                                style={{ cursor: "pointer" }}
                              >
                                <Tooltip label="Click to copy">
                                  <Text
                                    _hover={{ textDecoration: "underline" }} // Underline on hover
                                  >
                                    {member.person.user.email}
                                  </Text>
                                </Tooltip>
                              </Td>
                            </Tr>
                          ))}
                        </Tbody>
                      </Table>
                    </TableContainer>
                  </Box>
                  <DeleteConfirmationPop
                    isOpen={isDeleteConfirmationOpen}
                    onClose={closeDeleteConfirmation}
                    onConfirm={handleDeleteConfirmation}
                  />
                </>
              ) : (
                <Flex align="center" justify="center" mt="4">
                  <Text fontSize="lg" color="gray.500">
                    No Admin Found.
                  </Text>
                </Flex>
              )}
              <Heading marginTop="10" as="h2" size="lg">
                Members
              </Heading>
              {unionMembers.length > 0 ? (
                <>
                  <Box overflowX="auto">
                    <TableContainer>
                      <Table
                        variant="simple"
                        size="sm"
                        borderWidth="1px"
                        borderRadius="md"
                        mb="4"
                        borderBottom="1px"
                        borderColor="gray.300"
                        mt="5"
                      >
                        {/* {tableHeading} */}
                        <Thead>
                          <Tr>
                            <Th
                              borderBottom="1px"
                              borderColor="gray.300"
                              textAlign="center"
                              minWidth="150px"
                            >
                              Profile Photo
                            </Th>
                            <Th
                              borderBottom="1px"
                              borderColor="gray.300"
                              textAlign="center"
                              minWidth="150px"
                            >
                              Class
                            </Th>
                            <Th
                              borderBottom="1px"
                              borderColor="gray.300"
                              textAlign="center"
                              minWidth="150px"
                            >
                              Description
                            </Th>
                            <Th
                              borderBottom="1px"
                              borderColor="gray.300"
                              textAlign="center"
                              minWidth="150px"
                            >
                              Name
                            </Th>
                            <Th
                              borderBottom="1px"
                              borderColor="gray.300"
                              textAlign="center"
                              minWidth="150px"
                            >
                              Phone
                            </Th>
                            <Th
                              borderBottom="1px"
                              borderColor="gray.300"
                              textAlign="center"
                              minWidth="150px"
                            >
                              Email
                            </Th>

                            {(checkRole === "admin" ||
                              checkRole === "producer") && (
                              <Th
                                borderBottom="1px"
                                borderColor="gray.300"
                                textAlign="center"
                                minWidth="88px"
                                maxWidth="88px"
                              >
                                Actions
                              </Th>
                            )}
                          </Tr>
                        </Thead>
                        <Tbody>
                          {unionMembers.map((member) => (
                            <Tr
                              key={member.id}
                              style={{
                                filter: member.is_member ? "none" : "blur(5px)",
                                userSelect: member.is_member ? "auto" : "none",
                              }}
                            >
                              <Td
                                borderBottom="1px"
                                borderColor="gray.300"
                                textAlign="center"
                                minWidth="150px"
                              >
                                {member.is_member ? (
                                  <Link
                                    key={member.id}
                                    to={`/person-detail/${member.person.user.id}`}
                                    style={{
                                      textDecoration: "none",
                                      width: "100%",
                                    }}
                                  >
                                    <Avatar src={member.person.profile_photo} />
                                  </Link>
                                ) : (
                                  <Avatar src={member.person.profile_photo} />
                                )}
                              </Td>

                              <Td
                                borderBottom="1px"
                                borderColor="gray.300"
                                textAlign="center"
                                minWidth="150px"
                              >
                                {member.is_member ? (
                                  <Link
                                    key={member.id}
                                    to={`/person-detail/${member.person.user.id}`}
                                    style={{
                                      textDecoration: "none",
                                      width: "100%",
                                    }}
                                  >
                                    {member.person.job_classification || "N/A"} 
                                  </Link>
                                ) : (
                                  <span>
                                    {member.person.job_classification || "N/A"}
                                  </span>
                                )}
                              </Td>
                              <Td
                                borderBottom="1px"
                                borderColor="gray.300"
                                textAlign="center"
                                minWidth="150px"
                              >
                                {member.is_member ? (
                                  <Link
                                    key={member.id}
                                    to={`/person-detail/${member.person.user.id}`}
                                    style={{
                                      textDecoration: "none",
                                      width: "100%",
                                    }}
                                  >
                                    {member.person.bio || "N/A"}
                                  </Link>
                                ) : (
                                  <span>{member.person.bio || "N/A"}</span>
                                )}
                              </Td>
                              <Td
                                borderBottom="1px"
                                borderColor="gray.300"
                                textAlign="center"
                                minWidth="150px"
                              >
                                {member.is_member ? (
                                  <Link
                                    key={member.id}
                                    to={`/person-detail/${member.person.user.id}`}
                                    style={{
                                      textDecoration: "none",
                                      width: "100%",
                                    }}
                                  >
                                    {member.person.user.first_name}{" "}
                                    {member.person.user.last_name}
                                  </Link>
                                ) : (
                                  <span>
                                    {member.person.user.first_name}{" "}
                                    {member.person.user.last_name}
                                  </span>
                                )}
                              </Td>
                              <Td
                                borderBottom="1px"
                                borderColor="gray.300"
                                textAlign="center"
                                minWidth="150px"
                              >
                                {member.is_member ? (
                                  <Link
                                    key={member.id}
                                    to={`/person-detail/${member.person.user.id}`}
                                    style={{
                                      textDecoration: "none",
                                      width: "100%",
                                    }}
                                  >
                                    {member.person.phone_number || "N/A"}
                                  </Link>
                                ) : (
                                  <span>{member.person.phone_number || "N/A"}</span>
                                )}
                              </Td>
                              <Td
                                borderBottom="1px"
                                borderColor="gray.300"
                                textAlign="center"
                                minWidth="150px"
                                onClick={() =>
                                  member.is_member &&
                                  handleCopyEmail(member.person.user.email)
                                }
                                style={{
                                  cursor: member.is_member
                                    ? "pointer"
                                    : "default",
                                }}
                              >
                                {member.is_member ? (
                                  <Text
                                    _hover={{ textDecoration: "underline" }} // Underline on hover
                                  >
                                    <Tooltip label="Click to copy">
                                      {member.person.user.email || "N/A"}
                                    </Tooltip>
                                  </Text>
                                ) : (
                                  <span>{member.person.user.email || "N/A"}</span>
                                )}
                              </Td>

                              {checkRole === "admin" && (
                                <Td
                                  borderBottom="1px"
                                  borderColor="gray.300"
                                  textAlign="center"
                                  mx="auto"
                                  whiteSpace="nowrap"
                                  minWidth="150px"
                                >
                                  <Menu>
                                    <MenuButton
                                      as={Button}
                                      size="sm"
                                      style={{ border: "1px solid " }}
                                      isDisabled={!member.is_member}
                                    >
                                      <Flex align="center">
                                        Actions <FaCaretDown />
                                      </Flex>
                                    </MenuButton>
                                    <MenuList>
                                      <MenuItem
                                        onClick={() =>
                                          handleButtonAction(
                                            "visibility",
                                            member.id
                                          )
                                        }
                                      >
                                        <Box display="inline-block" mr="2">
                                          <Flex align="center">
                                            <Button
                                              color="white"
                                              bgColor="teal.500"
                                              variant="outline"
                                              _hover={{
                                                bgColor: "teal.600",
                                              }}
                                              size="sm"
                                              borderRadius="xl"
                                            >
                                              {isHidden[member.id] ? (
                                                <ViewOffIcon boxSize={4} />
                                              ) : (
                                                <ViewIcon boxSize={4} />
                                              )}
                                            </Button>
                                            <Text
                                              ml="2"
                                              textAlign="center"
                                              fontSize="md"
                                            >
                                              {isHidden[member.id]
                                                ? "Hidden"
                                                : "Visible"}
                                            </Text>
                                          </Flex>
                                        </Box>
                                        {/* </Tooltip> */}
                                      </MenuItem>
                                      <MenuItem
                                        onClick={() =>
                                          handleButtonAction(
                                            "active",
                                            member.id
                                          )
                                        }
                                        isDisabled={
                                          isClicked[member.id] || isClicked
                                        }
                                      >
                                        <Box display="inline-block" mr="2">
                                          <Flex align="center">
                                            <Button
                                              color="white"
                                              bgColor="teal.500"
                                              _hover={{
                                                bgColor: "teal.600",
                                              }}
                                              variant="outline"
                                              size="sm"
                                              borderRadius="xl"
                                            >
                                              {isActive[member.id] === true ? (
                                                <BsPersonFillCheck />
                                              ) : (
                                                <BsPersonFillDash />
                                              )}
                                            </Button>
                                            <Text
                                              ml="2"
                                              textAlign="center"
                                              fontSize="md"
                                            >
                                              {isActive[member.id]
                                                ? "Active"
                                                : "InActive"}
                                            </Text>
                                          </Flex>
                                        </Box>
                                      </MenuItem>
                                      <MenuItem
                                        onClick={() =>
                                          handleButtonAction(
                                            "delete",
                                            member.id
                                          )
                                        }
                                      >
                                        <Box display="inline-block" mr="2">
                                          <Flex align="center">
                                            <Button
                                              color="white"
                                              bgColor="teal.500"
                                              _hover={{
                                                bgColor: "teal.600",
                                              }}
                                              variant="outline"
                                              size="sm"
                                              borderRadius="xl"
                                            >
                                              <DeleteIcon />
                                            </Button>
                                            <Text
                                              ml="2"
                                              textAlign="center"
                                              fontSize="md"
                                            >
                                              Delete
                                            </Text>
                                          </Flex>
                                        </Box>
                                      </MenuItem>
                                      <MenuItem
                                        onClick={() =>
                                          handleButtonAction(
                                            "reminder",
                                            member.id
                                          )
                                        }
                                        isDisabled={
                                          isClicked[member.id] || isClicked
                                        }
                                      >
                                        <Box display="inline-block" mr="2">
                                          <Flex align="center">
                                            <Button
                                              color="white"
                                              bgColor="teal.500"
                                              _hover={{
                                                bgColor: "teal.600",
                                              }}
                                              variant="outline"
                                              size="sm"
                                              borderRadius="xl"
                                            >
                                              <RepeatClockIcon />
                                            </Button>
                                            <Text
                                              ml="2"
                                              textAlign="center"
                                              fontSize="md"
                                            >
                                              Reminder
                                            </Text>
                                          </Flex>
                                        </Box>
                                      </MenuItem>
                                    </MenuList>
                                  </Menu>
                                </Td>
                              )}
                              {checkRole === "producer" && (
                                <Td
                                  borderBottom="1px"
                                  borderColor="gray.300"
                                  textAlign="center"
                                >
                                  {checkRole === "producer" && (
                                    <Button
                                      disabled={!member.is_member}
                                      onClick={() =>
                                        toggleFavourite(
                                          member.person.is_active,
                                          member.person.id
                                        )
                                      }
                                      fontSize="35px"
                                    >
                                      {member.is_favorite ? (
                                        <div style={{ color: "#f3c677" }}>
                                          <MdStar />
                                        </div>
                                      ) : (
                                        <div style={{ color: "#2c7a7b" }}>
                                          <ImStarEmpty />
                                        </div>
                                      )}
                                    </Button>
                                  )}
                                </Td>
                              )}
                            </Tr>
                          ))}
                        </Tbody>
                      </Table>
                    </TableContainer>
                  </Box>
                  <DeleteConfirmationPop
                    isOpen={isDeleteConfirmationOpen}
                    onClose={closeDeleteConfirmation}
                    onConfirm={handleDeleteConfirmation}
                  />
                </>
              ) : (
                <Flex align="center" justify="center" mt="4">
                  <Text fontSize="lg" color="gray.500">
                    No Members Found.
                  </Text>
                </Flex>
              )}
            </>
            {/* // ) : ( */}
            {/* // <> */}
            {/* <Box overflowX="auto"> */}
            {/* Search Results Table */}
            {/* <SearchResultTable searchResults={ceo.states.searchResults} /> */}
            {/* </Box> */}
            {/* </> */}
            {/* // )} */}
          </>
        ) : (
          <Loader />
        )}
      </Box>
    </>
  );
};
Members.propTypes = {
  unionId: PropTypes.number, // Assuming unionId is a string, adjust as needed
};
export default Members;
