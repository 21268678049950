import {
  Box,
  Text,
  Flex,
  Button,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import { useApp } from "AppContext/AppProvider";
import Navbar from "components/navbar/NavbarAdmin";
import { SearchResultTable } from "components/table/SearchResultsTable";
import manager from "helpers/manager";
import { useState, useEffect } from "react";
import { toast } from "react-hot-toast";
import Loader from "components/table/loader";
import RequestInviteModal from "components/InviteModal/RequestInviteModa";
import { Link } from "react-router-dom";

// import DefaultAuth from "layouts/auth/Default";
const Home = () => {
  const ceo = useApp();
  const { states } = useApp();
  const { searchResults } = states;
  const [isLoading, setIsLoading] = useState(true);
  const [isAvailable, setIsAvailable] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [membersAvailable, setMembersAvailable] = useState(0);
  const [totalMembers, setTotalMembers] = useState(0);
  const [statusUpdate, setStatusUpdate] = useState(null);

  const { onClose } = useDisclosure();

  useEffect(() => {
    if (ceo.states.updateStatus != null) {
      if (ceo.states.updateStatus == "1") {
        setStatusUpdate(true);
        ceo.actions.setUpdateStatus(null) 
      } else if (ceo.states.updateStatus == "0") {
        setStatusUpdate(false);
        ceo.actions.setUpdateStatus(null)
      }
    }
  }, [ceo.states.updateStatus]);

  useEffect(() => {
    if (statusUpdate !== null) {
      updateStatus();
    }
  }, [statusUpdate]);

  const updateStatus = async () => {
    const memberId = userProfile.id;

    try {
      const response = await manager.updateAvailabilityStatus(
        statusUpdate,
        memberId
      );
      if (response.ok) {
        setIsAvailable(statusUpdate);
        fetchMemberStatus();
        setStatusUpdate(null)

        toast.success("Thank you! your availability has been updated");
            } else {
        console.log("Error fetching status");
      }
    } catch (error) {
      console.log(error, "Error in fetching the status");
    }
  };


  const memberStatusUpdate = async () => {
    const memberId = userProfile.id;

    try {
      const response = await manager.updateAvailabilityStatus(
        !isAvailable,
        memberId
      );
      if (response.ok) {
        // const data = await response.json();

        setIsAvailable(!isAvailable);
        toast.success("Thank you! your availability has been updated");
      } else {
        console.log("Error fetching status");
      }
    } catch (error) {
      console.log(error, "Error in fetching the status");
    }
  };
  const fetchMemberStatus = async () => {
    setIsLoading(true);
    const memberId = userProfile.id;
    try {
      const response = await manager.getPersonDetails(memberId);
      if (response.ok) {
        const data = await response.json();
        setIsAvailable(data.is_active);
      } else {
        console.log("Error fetching status");
      }
    } catch (error) {
      console.log(error, "Error in fetching the status");
    } finally {
      setIsLoading(false);
    }
  };
  const getMemberCount = async () => {
    setIsLoading(true);
    try {
      const response = await manager.getMembersCount();
      if (response.ok) {
        const data = await response.json();
        setMembersAvailable(data.available);
        setTotalMembers(data.total);
      } else {
        console.log("Error fetching members count");
      }
    } catch (error) {
      console.err(error, "Error in fetching members count");
    } finally {
      setIsLoading(false);
    }
  };
  const formFields = [
    {
      type: "input",
      label: "Email",
      placeholder: "Your email",
    },
    {
      type: "select",
      label: "Role",
      placeholder: "Select role",
      options: [
        { label: "User", value: "user" },
        { label: "Admin", value: "admin" },
        // Add more options as needed
      ],
    },
  ];
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleInviteSubmit = async ({
    email,
    description,
    membershipType,
    union,
    firstName,
    lastName,
  }) => {
    try {
      const response = await manager.requestInvite(
        email,
        description,
        membershipType,
        union,
        firstName,
        lastName
      );
      if (!response.ok) {
        throw new Error(toast.error("Invite already sent"));
      }
      onClose(); // Close the modal
      toast.success("Invite Request has been Initiated");
    } catch (error) {
      console.error("Error sending invite");
      // toast.success(error.message || "Error sending invite");
      // console.log(error.message, "message");
    }
  };

  useEffect(() => {
    fetchMemberStatus();
    getMemberCount();
    ceo.actions.setSearchResults({ people: [], unions: [] });
    ceo.actions.setSearchQuery("");
  }, []);

  // Getting the User Role
  const userProfile = ceo.states.userProfile;
  const checkRole = userProfile ? userProfile.role : null;

  return (
    <>
      <Navbar />

      <Box mt="20" ml={{ base: "90px", md: "250px" }}>
        {searchResults.people.length === 0 &&
        searchResults.unions.length === 0 &&
        !states.searchQuery ? (
          <>
            {!isLoading ? (
              <>
                <>
                  {ceo.states.userProfile.role === "None" ? (
                    <>
                      <Flex align="center" justify="center" mt="4">
                        <Text fontSize="lg" color="gray.500">
                          Welcome! Admin Deleted Your MemberShip. Please Contact
                          Admin
                        </Text>
                      </Flex>
                      <Flex justify="flex-end">
                        <Button
                          colorScheme="teal"
                          onClick={openModal}
                          mt="4"
                          mr="3"
                        >
                          Request Invite
                        </Button>
                      </Flex>
                      <RequestInviteModal
                        isOpen={isModalOpen}
                        onClose={closeModal}
                        title="Request an Invite"
                        onSubmit={handleInviteSubmit}
                        submitLabel="Submit Request"
                        formFields={formFields}
                      />
                    </>
                  ) : (
                    <>
                      <Flex
                        // align="center" justify="center"
                        mt="4"
                      >
                        <Text fontSize="3xl" color="gray.500">
                          Welcome, {userProfile?.first_name}!
                        </Text>
                      </Flex>
                    </>
                  )}
                </>
                {checkRole === "admin" && (
                  <Text mt="1">
                    {membersAvailable} of your {totalMembers} members are
                    currently available for work
                  </Text>
                )}

                {checkRole === "member" && (
                  <>
                    <Text fontSize="lg" color="gray.500">
                      {isAvailable
                        ? "You're currently available for work"
                        : "You're currently not available for work, which means you won't show up on your unions availability roster"}
                    </Text>
                    <Text fontSize="lg" color="gray.500">
                      {isAvailable
                        ? "No longer available for work? Click the button below to change your status."
                        : "Are you now available for work? Click the button below to let us know"}
                    </Text>
                    <Tooltip
                      label={
                        isAvailable
                          ? "Available to work"
                          : "Not available to work"
                      }
                    >
                      <Button
                        color="white"
                        bgColor={isAvailable ? "teal.500" : "red.500"}
                        _hover={{
                          bgColor: isAvailable ? "teal.600" : "red.600",
                        }}
                        variant="outline"
                        size="sm"
                        borderRadius="xl"
                        mt="1"
                        onClick={() => memberStatusUpdate(isAvailable)}
                      >
                        {isAvailable ? "Available" : "Not Available"}
                      </Button>
                    </Tooltip>
                    <Text fontSize="lg" color="gray.500" mt="1">
                      {"While you're here, take a look at"}{" "}
                      <Link
                        key={userProfile.id}
                        to={`/person-detail/${userProfile.id}`}
                        style={{
                          textDecoration: "underline",
                          width: "100%",
                          color: "blue",
                          cursor: "pointer",
                        }}
                      >
                        your profile
                      </Link>{" "}
                      {
                        "and make sure it's up-to-date with your latest information!"
                      }
                    </Text>
                  </>
                )}
              </>
            ) : (
              <Loader />
            )}
          </>
        ) : (
          <>
            {ceo.states.userProfile.role === "None" ? (
              <>
                <Flex align="center" justify="center" mt="4">
                  <Text fontSize="lg" color="gray.500">
                    Welcome! Admin Deleted Your MemberShip. Please Contact Admin
                  </Text>
                </Flex>
              </>
            ) : (
              <>
                <SearchResultTable searchResults={searchResults} />
              </>
            )}
          </>
        )}
        {/* Conditionally render the SearchResultTable if there are results */}
      </Box>
      {/* <DefaultAuth /> */}
    </>
  );
};

export default Home;
