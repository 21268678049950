import React from "react";
import ReactDOM from "react-dom";
import "assets/css/App.css";
import { BrowserRouter } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "theme/theme";
import { ThemeEditorProvider } from "@hypertheme-editor/chakra-ui";
import App from "App";
import { AppProvider } from "AppContext/AppProvider";

ReactDOM.render(
  <BrowserRouter>
  <ChakraProvider theme={theme}>
    {/* <React.StrictMode> */}
      <ThemeEditorProvider>
      <AppProvider>
        <App />
      </AppProvider>
      </ThemeEditorProvider>
    {/* </React.StrictMode> */}
  </ChakraProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
