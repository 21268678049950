import { useEffect, useState } from "react";
import manager from "helpers/manager";
import Navbar from "components/navbar/NavbarAdmin";
import Loader from "components/table/loader";
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  Button,
  Flex,
  Tooltip,
  useDisclosure,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import { FaCaretDown } from "react-icons/fa";
import { toast } from "react-hot-toast";
import { DeleteIcon, RepeatClockIcon, CheckIcon } from "@chakra-ui/icons";
import { useApp } from "AppContext/AppProvider";
import InviteRequestModal from "components/InviteModal/InviteRequestModal";
import DeleteConfirmationPop from "views/members/DeleteConfirmationPop/DeleteConfirmationPop";
import { SearchResultTable } from "components/table/SearchResultsTable";

const Invites = () => {
  const [inviteDetails, setInviteDetails] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [inviteToDelete, setInviteToDelete] = useState(null);
  const [isClicked, setIsClicked] = useState(false);

  const {
    isOpen: isDeleteConfirmationOpen,
    onOpen: openDeleteConfirmation,
    onClose: closeDeleteConfirmation,
  } = useDisclosure();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const ceo = useApp();

  const fetchInvites = async () => {
    try {
      setIsLoading(true);
      const response = await manager.getInvites();
      if (!response.ok) throw new Error("Failed to fetch invites information");
      const data = await response.json();
      setInviteDetails(data);
    } catch (error) {
      setError(error.message || "Error fetching invites details");
    } finally {
      setIsLoading(false);
    }
  };
  const handleStatusUpdate = async (inviteId, action) => {
    try {
      setIsClicked(true);
      if (action === "delete") {
        setInviteToDelete(inviteId), openDeleteConfirmation();
      } else {
        const response = await manager.updateInvite(inviteId, action);
        if (!response.ok) throw new Error("Failed to update invite status");
        const responseData = await response.json();
        if (responseData.status === "Reminder sent") {
          toast.success("Reminder sent successfully");
        } else if (responseData.status === "Invite deleted") {
          toast.success("Invite deleted successfully");
          setInviteDetails((prevDetails) =>
            prevDetails.filter((invite) => invite.id !== inviteId)
          );
        } else if (
          responseData.status ===
          "Invite accepted by the admin email sent to the user."
        ) {
          toast.success("request to invite accepted");
          setInviteDetails((prevDetails) =>
            prevDetails.map((invite) =>
              invite.id === inviteId ? { ...invite, status: 1 } : invite
            )
          );
        } else if (responseData.status === "Invite accepted") {
          toast.success("membership accepted");
          setInviteDetails((prevDetails) =>
            prevDetails.map((invite) =>
              invite.id === inviteId ? { ...invite, status: 3 } : invite
            )
          );
        }
      }
    } catch (error) {
      console.error("Error updating invite status:", error);
    } finally {
      setIsClicked(false);
    }
  };
  const handleDeleteConfirmation = async () => {
    if (inviteToDelete) {
      try {
        const response = await manager.updateInvite(inviteToDelete, "delete");
        if (response.ok) {
          const data = await response.json();
          closeDeleteConfirmation();
          setInviteToDelete(null);
          if (data.status == "Invite deleted") {
            setInviteDetails((prevDetails) =>
              prevDetails.filter((invite) => invite.id !== inviteToDelete)
            );
            toast.success("Invite deleted successfully");
          }
        }
      } catch (error) {
        console.log(error.message || "Error deleting member");
      }
    }
  };
  useEffect(() => {
    fetchInvites();
    ceo.actions.setSearchResults({ people: [], unions: [] });
    ceo.actions.setSearchQuery("");
  }, []);

  const handleInviteSubmit = async ({
    email,
    description,
    link,
    unionId,
    membershipType,
    firstName,
    lastName,
  }) => {
    try {
      const response = await manager.createInvite(
        unionId,
        email,
        description,
        link,
        membershipType,
        firstName,
        lastName
      );
      if (!response.ok) {
        const errorText = await response.text();
        try {
          const errorJson = JSON.parse(errorText);
          throw new Error(errorJson.message || "Failed to send invite");
        } catch {
          throw new Error(errorText || "Failed to send invite");
        }
      }

      let newInviteeDetails = await response.json();
      setInviteDetails((prevDetails) => [...prevDetails, newInviteeDetails]);
      onClose(); // Close the modal
      toast.success("Invite sent successfully!");
    } catch (error) {
      console.error("Error sending invite:", error);
      toast.error(error.message || "Error sending invite");
    }
  };
  // Getting the User Role
  const userProfile = ceo.states.userProfile;
  const checkRole = userProfile ? userProfile.role : null;

  const hasSearchResults =
    ceo.states.searchResults.people.length > 0 ||
    ceo.states.searchResults.unions.length > 0;

  const hasSearchQuery = ceo.states.searchQuery === "";

  //copy email to clipboard
  const handleCopyEmail = (email) => {
    navigator.clipboard.writeText(email);
    toast.success("Email copied to clipboard!");
  };
  return (
    <>
      <Navbar />
      <Box
        ml={{ base: "90px", md: "250px" }}
        overflowX="auto"
        marginRight="1%"
        mt="20"
      >
        <div>
          {!isLoading ? (
            <>
              {!hasSearchResults && hasSearchQuery ? (
                <>
                  {checkRole === "admin" && (
                    <>
                      <Flex justify="flex-end">
                        <Button colorScheme="teal" onClick={onOpen} mt="4">
                          Send Invite
                        </Button>
                      </Flex>
                      <InviteRequestModal
                        isOpen={isOpen}
                        onClose={onClose}
                        onSubmit={handleInviteSubmit}
                      />
                    </>
                  )}
                  {inviteDetails && inviteDetails.length > 0 ? (
                    <>
                      <Table
                        variant="simple"
                        size="sm"
                        borderWidth="1px"
                        borderRadius="md"
                        mb="4"
                        borderBottom="1px"
                        borderColor="gray.300"
                        mt="5"
                      >
                        <Thead>
                          <Tr>
                            <Th
                              borderBottom="1px"
                              borderColor="gray.300"
                              textAlign="center"
                            >
                              Invitee Email
                            </Th>
                            <Th
                              borderBottom="1px"
                              borderColor="gray.300"
                              textAlign="center"
                            >
                              Invitee Name
                            </Th>

                            <Th
                              borderBottom="1px"
                              borderColor="gray.300"
                              textAlign="center"
                            >
                              Union Name
                            </Th>
                            <Th
                              borderBottom="1px"
                              borderColor="gray.300"
                              textAlign="center"
                            >
                              Status
                            </Th>
                            <Th
                              borderBottom="1px"
                              borderColor="gray.300"
                              textAlign="center"
                            >
                              Membership Type
                            </Th>
                            <Th
                              borderBottom="1px"
                              borderColor="gray.300"
                              textAlign="center"
                            >
                              Actions
                            </Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {inviteDetails.map((invites) => (
                            <Tr key={invites.id} ml="5px">
                              <Td
                                borderBottom="1px"
                                borderColor="gray.300"
                                p="20px"
                                textAlign="center"
                                onClick={() => handleCopyEmail(invites.invitee)}
                                style={{ cursor: "pointer" }}
                              >
                                <Tooltip label="Click to copy">
                                  <Text
                                    _hover={{ textDecoration: "underline" }}
                                  >
                                    {invites.invitee || "N/A"}
                                  </Text>
                                </Tooltip>
                              </Td>
                              <Td
                                borderBottom="1px"
                                borderColor="gray.300"
                                p="20px"
                                textAlign="center"
                              >
                                {invites.first_name && invites.last_name
                                  ? `${invites.first_name} ${invites.last_name}`
                                  : invites.first_name
                                  ? invites.first_name
                                  : invites.last_name
                                  ? invites.last_name
                                  : "N/A"}
                              </Td>
                              <Td
                                borderBottom="1px"
                                borderColor="gray.300"
                                textAlign="center"
                              >
                                {invites.union?.name || "N/A"}
                              </Td>
                              <Td
                                borderBottom="1px"
                                borderColor="gray.300"
                                textAlign="center"
                              >
                                {invites.status === 1
                                  ? "Sent"
                                  : invites.status === 2
                                  ? "Requested"
                                  : invites.status === 3
                                  ? "Accepted"
                                  : ""}
                              </Td>
                              <Td
                                borderBottom="1px"
                                borderColor="gray.300"
                                textAlign="center"
                              >
                                {invites.membership_type === 1
                                  ? "Admin"
                                  : invites.membership_type === 2
                                  ? "Member"
                                  : invites.membership_type === 3
                                  ? "Producer"
                                  : "-"}
                              </Td>

                              <Td
                                borderBottom="1px"
                                borderColor="gray.300"
                                textAlign="center"
                              >
                                {invites.status === 3 ? (
                                  "-" // Render "-" for status 3
                                ) : (
                                  <>
                                    {invites.status === 1 ? (
                                      <>
                                        <Menu>
                                          <MenuButton
                                            as={Button}
                                            size="sm"
                                            style={{ border: "1px solid " }}
                                          >
                                            <Flex align="center">
                                              Actions <FaCaretDown />
                                            </Flex>
                                          </MenuButton>
                                          <MenuList>
                                            <MenuItem
                                              onClick={() =>
                                                handleStatusUpdate(
                                                  invites.id,
                                                  "reminder"
                                                )
                                              }
                                              isDisabled={
                                                isClicked[invites.id] ||
                                                isClicked
                                              }
                                            >
                                              {/* <Tooltip
                                            label="Reminder"
                                            fontSize="md"
                                          > */}
                                              <Box
                                                display="inline-block"
                                                mr="2"
                                              >
                                                <Flex align="center">
                                                  <Button
                                                    color="white"
                                                    bgColor="teal.500"
                                                    variant="outline"
                                                    _hover={{
                                                      bgColor: "teal.600",
                                                    }}
                                                    size="sm"
                                                    borderRadius="xl"
                                                  >
                                                    <RepeatClockIcon />
                                                  </Button>
                                                  <Text
                                                    ml="2"
                                                    textAlign="center"
                                                    fontSize="md"
                                                  >
                                                    Reminder
                                                  </Text>
                                                </Flex>
                                              </Box>
                                              {/* </Tooltip> */}
                                            </MenuItem>
                                            <MenuItem
                                              onClick={() =>
                                                handleStatusUpdate(
                                                  invites.id,
                                                  "delete"
                                                )
                                              }
                                            >
                                              {/* <Tooltip label="Delete" fontSize="md"> */}
                                              <Box
                                                display="inline-block"
                                                mr="2"
                                              >
                                                <Flex align="center">
                                                  <Button
                                                    color="white"
                                                    bgColor="teal.500"
                                                    variant="outline"
                                                    _hover={{
                                                      bgColor: "teal.600",
                                                    }}
                                                    size="sm"
                                                    borderRadius="xl"
                                                  >
                                                    <DeleteIcon />
                                                  </Button>
                                                  <Text
                                                    ml="2"
                                                    textAlign="center"
                                                    fontSize="md"
                                                  >
                                                    Delete
                                                  </Text>
                                                </Flex>
                                              </Box>
                                              {/* </Tooltip> */}
                                            </MenuItem>
                                          </MenuList>
                                        </Menu>
                                      </>
                                    ) : (
                                      <>
                                        <Menu>
                                          <MenuButton
                                            as={Button}
                                            size="sm"
                                            style={{ border: "1px solid " }}
                                          >
                                            <Flex align="center">
                                              Actions <FaCaretDown />
                                            </Flex>
                                          </MenuButton>
                                          <MenuList>
                                            <MenuItem
                                              onClick={() =>
                                                handleStatusUpdate(
                                                  invites.id,
                                                  "accept"
                                                )
                                              }
                                              isDisabled={
                                                isClicked[invites.id] ||
                                                isClicked
                                              }
                                            >
                                              {/* <Tooltip label="Accept" fontSize="md"> */}
                                              <Box
                                                display="inline-block"
                                                mr="2"
                                              >
                                                <Flex align="center">
                                                  <Button
                                                    color="white"
                                                    bgColor="teal.500"
                                                    variant="outline"
                                                    _hover={{
                                                      bgColor: "teal.600",
                                                    }}
                                                    size="sm"
                                                    borderRadius="xl"
                                                  >
                                                    <CheckIcon />
                                                  </Button>
                                                  <Text
                                                    ml="2"
                                                    textAlign="center"
                                                    fontSize="md"
                                                  >
                                                    Accept
                                                  </Text>
                                                </Flex>
                                              </Box>
                                              {/* </Tooltip> */}
                                            </MenuItem>
                                            <MenuItem
                                              onClick={() =>
                                                handleStatusUpdate(
                                                  invites.id,
                                                  "delete"
                                                )
                                              }
                                            >
                                              {/* <Tooltip label="Delete" fontSize="md"> */}
                                              <Box
                                                display="inline-block"
                                                mr="2"
                                              >
                                                <Flex align="center">
                                                  <Button
                                                    color="white"
                                                    bgColor="teal.500"
                                                    variant="outline"
                                                    _hover={{
                                                      bgColor: "teal.600",
                                                    }}
                                                    size="sm"
                                                    borderRadius="xl"
                                                  >
                                                    <DeleteIcon />
                                                  </Button>
                                                  <Text
                                                    ml="2"
                                                    textAlign="center"
                                                    fontSize="md"
                                                  >
                                                    Delete
                                                  </Text>
                                                </Flex>
                                              </Box>
                                              {/* </Tooltip> */}
                                            </MenuItem>
                                          </MenuList>
                                        </Menu>
                                      </>
                                    )}
                                  </>
                                )}
                              </Td>
                            </Tr>
                          ))}
                        </Tbody>
                      </Table>
                      <DeleteConfirmationPop
                        isOpen={isDeleteConfirmationOpen}
                        onClose={closeDeleteConfirmation}
                        onConfirm={handleDeleteConfirmation}
                      />
                    </>
                  ) : (
                    <Flex align="center" justify="center" mt="4">
                      <Text fontSize="lg" color="gray.500">
                        No Invites Found.
                      </Text>
                    </Flex>
                  )}
                </>
              ) : (
                <>
                  <Box overflowX="auto">
                    {/* Search Results Table */}
                    <SearchResultTable
                      searchResults={ceo.states.searchResults}
                    />
                  </Box>
                </>
              )}
            </>
          ) : (
            <Loader />
          )}
        </div>
      </Box>
    </>
  );
};
export default Invites;
