import React, { useState, useEffect } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    FormControl,
    FormLabel,
    Input,
    Select,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import manager from 'helpers/manager';
import { useApp } from '../../AppContext/AppProvider'


const MEMBERSHIP_TYPE_OPTIONS = [
    { value: 2, label: 'member' },
    { value: 3, label: 'producer' },
];

function RequestInviteModal({ isOpen, onClose, onSubmit }) {
    const ceo = useApp();
    // Getting the User Role
    const userProfile = ceo.states.userProfile;
    const checkRole = userProfile ? userProfile.role : null;
    const [email, setEmail] = useState('');
    const [description, setDescription] = useState('');
    const [selectedMembershipType, setSelectedMembershipType] = useState('');
    const [unions, setUnions] = useState([]); // State to hold union options
    const [selectedUnion, setSelectedUnion] = useState(''); // New state for selected union
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');


    useEffect(() => {
        // Update state values when modal is opened
        if (isOpen && userProfile) {
            setEmail(userProfile.email || '');
            setFirstName(userProfile.first_name || '');
            setLastName(userProfile.last_name || '');
        }
    }, [isOpen, userProfile]);


    useEffect(() => {
        manager.getUnions(checkRole).then(data => {
            setUnions(data.map(union => ({ value: union.id, label: union.name })));
        }).catch(error => {
            console.error("Failed to fetch unions:", error);
        });
    }, []);

    const handleSubmit = () => {
        if (!email || !selectedMembershipType || !selectedUnion || !firstName || !lastName) {
            alert("Please enter all required fields.");
            return;
        }
        onSubmit({
            email,
            description,
            membershipType: selectedMembershipType,
            union: selectedUnion,
            firstName,
            lastName
        });

        setEmail('');
        setDescription('');
        setSelectedMembershipType('');
        setSelectedUnion('');
        onClose();
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Request Invite</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                    <FormControl isRequired>
                        <FormLabel>Email</FormLabel>
                        <Input
                            placeholder="example@mail.com"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            disabled={ceo.states.isAuthenticated === 1}
                        />
                    </FormControl>
                    <FormControl mt={4} isRequired>
                        <FormLabel>First Name</FormLabel>
                        <Input
                            placeholder="First Name"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            disabled={ceo.states.isAuthenticated === 1}
                        />
                    </FormControl>
                    <FormControl mt={4} isRequired>
                        <FormLabel >Last Name</FormLabel>
                        <Input
                            placeholder="Last Name "
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            disabled={ceo.states.isAuthenticated === 1}
                        />
                    </FormControl>
                    <FormControl mt={4} isRequired>
                        <FormLabel>Membership Type</FormLabel>
                        <Select
                            placeholder="Select membership type"
                            value={selectedMembershipType}
                            onChange={(e) => setSelectedMembershipType(e.target.value)}
                        >
                            {MEMBERSHIP_TYPE_OPTIONS.map((type) => (
                                <option key={type.value} value={type.value}>{type.label}</option>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl mt={4} isRequired>
                        <FormLabel>Union</FormLabel>
                        <Select
                            placeholder="Select union"
                            value={selectedUnion}
                            onChange={(e) => setSelectedUnion(e.target.value)}
                        >
                            {unions.map((union) => (
                                <option key={union.value} value={union.value}>{union.label}</option>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl mt={4}>
                        <FormLabel>Description</FormLabel>
                        <Input
                            placeholder="what project are you working on? what studio?"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                        />
                    </FormControl>

                </ModalBody>
                <ModalFooter>
                    <Button colorScheme="teal" mr={3} onClick={handleSubmit}>
                        Send
                    </Button>
                    <Button variant="ghost" onClick={onClose}>Cancel</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}

RequestInviteModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export default RequestInviteModal;
