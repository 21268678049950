import React, { useState, useEffect } from "react";
import {
  Box,
  Text,
  Flex,
  HStack,
  Avatar,
  Button,
  Icon,
  Stack,
  Skeleton,
  Image,
  Input,
  Heading,
  color,
  Tooltip,
} from "@chakra-ui/react";
import Navbar from "components/navbar/NavbarAdmin";
// import DefaultAuth from "layouts/auth/Default";
import { useApp } from "../../AppContext/AppProvider";
import manager from "../../helpers/manager";
import Loader from "components/table/loader";
import { useParams, Link } from "react-router-dom";
import { toast } from "react-hot-toast";
import Members from "views/members";
import { MdEdit } from "react-icons/md";
import { SearchResultTable } from "components/table/SearchResultsTable";

const UnionProfilePage = () => {
  const ceo = useApp();
  const { states } = useApp();
  const { searchResults } = states;
  const [isLoading, setIsLoading] = useState(true);
  const [unionsDetails, setUnionsDetails] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [originalUnionDetails, setOriginalUnionDetails] = useState([]);
  const [avatarPreview, setAvatarPreview] = useState(null);
  const [photoCheck, setPhotoCheck] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [avatarPhoto, setAvatarPhoto] = useState(null);
  const [isLoadingPhoto, setIsLoadingPhoto] = useState(null);

  const [error, setError] = useState(null);
  const [isClicked, setIsClicked] = useState(false);

  const { id } = useParams();
  const fetchUnionDetails = async (id) => {
    try {
      setIsLoading(true)
      const response = await manager.getUnionDetails(id);
      setIsLoadingPhoto(true);
      if (response.ok) {
        const data = await response.json();
        setUnionsDetails(data);
        setOriginalUnionDetails(data);
        setAvatarPreview(data.profile_photo);
        // setAvatarPhoto(data.profile_photo);
      }
    } catch (error) {
      console.log("Error In getting the Persons Details", error);
    }finally{
      setIsLoading(false)

      setIsLoadingPhoto(false);
    }
  };

  const requestAdminUnion = async () => {
    try {
      setIsClicked(true);
      
      const unionId = unionsDetails.id;
      const response = await manager.requestUnionAdmin(unionId);
      if (response.ok) {
        const data = await response.json();
        if (data.message === "Invite requested") {
          toast.success("Request Initiated");
        } else if (data.error === "Invite already sent") {
          toast.error("Request Already Submitted");
        }
      } else {
        throw new Error("Failed to fetch Unions information");
      }
    } catch (error) {
      setError(error.message || "Error fetching unions details");
    } finally {
      setIsClicked(false);
    }
  };

  useEffect(() => {
    ceo.actions.setSearchResults({ people: [], unions: [] });
    ceo.actions.setSearchQuery("");
    
  }, []); // Run only once on component mount

  useEffect(() => {
    fetchUnionDetails(id);
  }, []);

  const handleEditUnions = () => {
    setEditMode(!editMode);
    setUnionsDetails(originalUnionDetails);
    setAvatarPreview(originalUnionDetails.profile_photo);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    // setDataForUpdate((prevData) => ({ ...prevData, [name]: value }));

    setUnionsDetails((prevProfile) => ({
      ...prevProfile,
      [name]: value,
    }));
  };

  const handleAvatarChange = (e) => {
    const file = e.target.files[0];

    const allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
    if (file.size > 200 * 1024) {
      toast.error("File size should be less than 200KB");
      return;
    }
    if (!allowedExtensions.exec(file.name)) {
      // Check if the file extension is not allowed
      toast.error(
        "Only JPG, JPEG, and PNG files are allowed for profile photo"
      );
    } else {
      setAvatarPhoto(file);
      // Update the URL for displaying the image preview
      setAvatarPreview(URL.createObjectURL(file));
    }
  };

  const handleSaveChanges = async () => {
    try {
      // Check if profile photo has been changed
      if (avatarPhoto) {
        // Upload new profile photo
        setIsUploading(true);
        setIsLoadingPhoto(true);
        const responsePhoto = await manager.uploadUnionsImage(
          unionsDetails.id,
          avatarPhoto
        );
        if (responsePhoto.ok) {
          const dataPhoto = await responsePhoto.json();
          setUnionsDetails(dataPhoto);
          setOriginalUnionDetails(dataPhoto);
          setAvatarPhoto(null);
          setAvatarPreview(dataPhoto.profile_photo);
          setEditMode(!editMode);

          // setAvatarPreview(photoCheck);

          toast.success("Image Uploaded Successfully");
        } else {
          console.error("Failed to upload photo");
        }
      }

      // Check if other union details have been changed
      const updatedDetails = { ...unionsDetails };
      delete updatedDetails.profile_photo; // Remove profile photo from the update object
      const hasChanges = Object.keys(updatedDetails).some(
        (key) => updatedDetails[key] !== originalUnionDetails[key]
      );
      if (hasChanges) {
        setIsUploading(true);
        const response = await manager.updateUnionDetaitls(
          unionsDetails.id,
          unionsDetails
        );

        if (response.ok) {
          const data = await response.json();
          setUnionsDetails(data);
          setOriginalUnionDetails(data);
          setEditMode(!editMode);
          toast.success("Union Profile updated successfully");
        } else {
          const data = await response.json();
          if (data.email && data.email[0] === "Enter a valid email address.") {
            toast.error("Enter a valid email address. Ex@gmail.com");
          }
        }
      }
    } catch (error) {
      console.log("Error saving user profile", error);
    } finally {
      setIsUploading(false); // Set uploading state to false
    }
  };

  const checkRole = ceo.states.userProfile.role;
  const checkMember = ceo.states.isMemberOf;
  const handleCopyEmail = (email) => {
    navigator.clipboard.writeText(email);
    toast.success("Email copied to clipboard!");
  };
  const hasSearchResults =
    ceo.states.searchResults.people.length > 0 ||
    ceo.states.searchResults.unions.length > 0;

  const hasSearchQuery = ceo.states.searchQuery === "";

  return (
    <>
      <Navbar />
      <Box ml={{ base: "90px", md: "250px" }} marginTop="90px">
        {!isLoading ? (
          <>
            {!hasSearchResults && hasSearchQuery ? (
              <>
                <Flex
                  flexDirection={{ base: "column", md: "row" }} // Stack vertically on small screens, horizontally on medium and larger screens
                >
                  <HStack align="start" spacing="8">
                    {editMode ? (
                      <label
                        htmlFor="avatarInput"
                        style={{ position: "relative" }}
                      >
                        <Box
                          cursor="pointer"
                          boxSize={{ base: "140px", md: "180px" }}
                          borderRadius="full"
                          overflow="hidden"
                          position="relative"
                        >
                          {isUploading ? ( // Show loader if uploading
                            <Loader />
                          ) : (
                            <Avatar
                              size="2xl"
                              boxSize="100%"
                              src={avatarPreview}
                              _hover={{
                                bg: "black",
                              }}
                            />
                          )}
                        </Box>
                        <Input
                          id="avatarInput"
                          type="file"
                          accept="image/*"
                          display="none"
                          onChange={handleAvatarChange}
                        />
                        <MdEdit
                          style={{
                            fontSize: "30px",
                            position: "relative",
                            bottom: "10px",
                            right: "5px",
                            left: "10px",
                            backgroundColor: "gray",
                            borderRadius: "50%",
                            padding: "5px",
                            color: "white",
                          }}
                        />
                      </label>
                    ) : (
                      <>
                        <Avatar
                          onLoad={() => {
                            // Check if profile photo exists or is null, if yes, set isLoadingPhoto to false
                            if (
                              unionsDetails.profile_photo === null ||
                              unionsDetails.profile_photo
                            ) {
                              setIsLoadingPhoto(false);
                            }
                          }}
                          size="2xl"
                          boxSize={{ base: "140px", md: "180px" }}
                          display={isLoadingPhoto ? "none" : "block"}
                          name={
                            `${unionsDetails.name || ""}` || ""
                          }
                          src={unionsDetails.profile_photo}
                        />

                        {isLoadingPhoto && (
                          <Box boxSize={{ base: "140px", md: "180px" }}>
                            <Loader />
                          </Box>
                        )}
                      </>
                    )}

                    <Stack align="start">
                      {editMode ? (
                        <>
                          <Input
                            size="sm"
                            type="text"
                            name="description"
                            value={unionsDetails.description || ""}
                            placeholder={
                              unionsDetails.description
                                ? unionsDetails.description
                                : "Enter a Description"
                            }
                            onChange={handleChange}
                          />
                          <Input
                            size="sm"
                            type="text"
                            name="last_name"
                            value={unionsDetails.name || ""}
                            placeholder={"Please Enter a Name"}
                            onChange={handleChange}
                          />
                          <Input
                            size="sm"
                            type="email"
                            name="email"
                            value={unionsDetails.email || ""}
                            placeholder={"Please enter Email"}
                            onChange={handleChange}
                          />
                          <Input
                            size="sm"
                            type="text"
                            name="phone_number"
                            value={unionsDetails.phone_number || ""}
                            placeholder={"Enter a Phone Number"}
                            onChange={handleChange}
                          />
                          <Input
                            size="sm"
                            type="text"
                            name="address"
                            value={unionsDetails.address || ""}
                            placeholder={"Enter a Address"}
                            onChange={handleChange}
                          />
                          <Input
                            size="sm"
                            type="text"
                            name="website_link"
                            value={unionsDetails.website_link || ""}
                            placeholder={"Enter a Website Link"}
                            onChange={handleChange}
                          />
                          <Flex>
                            <Button
                              mt="5px"
                              colorScheme="black"
                              variant="outline"
                              size="sm"
                              borderRadius="xl"
                              color="white"
                              _hover={{
                                bgColor: "transparent",
                                color: "#4d929a",
                                borderColor: "#4d929a",
                                borderRadius: "lg",
                              }}
                              backgroundColor="#4d929a"
                              onClick={handleSaveChanges}
                              isDisabled={isUploading}
                            >
                              Save Profile
                            </Button>
                            <Button
                              mt="5px"
                              colorScheme="black"
                              variant="outline"
                              size="sm"
                              borderRadius="xl"
                              ml={{ base: 0, md: 2 }}
                              onClick={handleEditUnions}
                              _hover={{
                                borderRadius: "lg",
                                color: "blackAlpha.600",
                              }}
                            >
                              Cancel
                            </Button>
                          </Flex>
                        </>
                      ) : (
                        <>
                          <div>
                            <Text fontSize="2xl" fontWeight="bold">
                              {unionsDetails.name}{" "}
                            </Text>

                            <Text fontSize="sm">
                              {unionsDetails.description}
                            </Text>
                            <Tooltip label="Click to copy">
                              <Text
                                fontSize="sm"
                                onClick={() =>
                                  handleCopyEmail(unionsDetails.email)
                                }
                                style={{ cursor: "pointer" }}
                                _hover={{ textDecoration: "underline" }}
                              >
                                {unionsDetails.email}
                              </Text>
                            </Tooltip>

                            <Text fontSize="sm">
                              {unionsDetails.phone_number}
                            </Text>

                            <Text fontSize="sm">{unionsDetails.address}</Text>
                            <Link
                              target="_blank"
                              to={`${unionsDetails.website_link}`}
                              key={unionsDetails.id}
                              style={{
                                color: "blue",
                                cursor: "pointer",
                                textDecoration: "underline",
                                fontStyle: "italic",
                              }}
                            >
                              <Text ml="5px" mt="2px" fontSize="sm">
                                {unionsDetails.website_link && "Website Link"}
                              </Text>
                            </Link>
                            {checkRole === "producer" && (
                              <Button
                                color="white"
                                bgColor={"teal.500"}
                                _hover={{ bgColor: "teal.600" }}
                                variant="outline"
                                size="sm"
                                borderRadius="xl"
                                mr="1"
                                mt="1"
                                style={{
                                  display: checkMember?.includes(
                                    unionsDetails.id
                                  )
                                    ? "none"
                                    : "block",
                                }}
                                onClick={() =>
                                  requestAdminUnion(unionsDetails.id)
                                }
                                isDisabled={
                                  isClicked[unionsDetails.id] || isClicked
                                }
                              >
                                Request Access
                              </Button>
                            )}
                            {checkRole === "admin" && (
                              <Button
                                mt="5px"
                                colorScheme="black"
                                variant="outline"
                                size="sm"
                                borderRadius="xl"
                                // ml={{ base: 0, md: 2 }}
                                _hover={{
                                  borderRadius: "lg",
                                  color: "blackAlpha.600",
                                }}
                                onClick={handleEditUnions}
                              >
                                Edit Profile
                              </Button>
                            )}
                          </div>
                        </>
                      )}
                    </Stack>
                  </HStack>
                </Flex>

                <Box marginTop="-20">
                  <Members unionId={unionsDetails.id} />
                </Box>
              </>
            ) : (
              <>
                <Box overflowX="auto">
                  {/* Search Results Table  */}
                  <SearchResultTable searchResults={ceo.states.searchResults} />
                </Box>
              </>
            )}
          </>
        ) : (
          <>
            <Loader />
          </>
        )}
      </Box>
    </>
  );
};

export default UnionProfilePage;
