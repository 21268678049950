
// export const backendRoot = 'http://127.0.0.1:8000'

export const backendRoot = 'https://trw-prod-356ed585f73f.herokuapp.com'

export const verifyTokenPath = 'account/token/verify/'

export const getTokenPath = 'account/token/'

export const signUpPath = 'account/signup/'

export const userInfoPath = 'account/user/'
