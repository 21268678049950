/* global PropTypes */
import React, { useState, useEffect } from "react";
import {
  Text,
  Flex,
  // Button,
  Switch,
  Box,
} from "@chakra-ui/react";
import PropTypes from "prop-types";
import { useApp } from "AppContext/AppProvider";
import Loader from "./loader";
import { DynamicTable } from "./DynamicTable";

export function SearchResultTable({ searchResults }) {
  const ceo = useApp();

  const [isLoading, setIsLoading] = useState(true); // State to manage loading spinner
  const [isActiveFilter, setIsActiveFilter] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1500);

    return () => clearTimeout(timer); // Cleanup timer on component unmount
  }, []);

  if (isLoading) {
    return (
      <Flex>
        <Loader />
      </Flex>
    );
  }

  if (
    !searchResults ||
    (searchResults.people.length === 0 &&
      searchResults.unions.length === 0 &&
      searchResults.credits.length === 0 &&
      searchResults.favorites.length === 0)
  ) {
    return (
      <Flex align="center" justify="center" mt="4">
        <Text fontSize="lg" color="gray.500">
          No Match Found.
        </Text>
      </Flex>
    );
  }
  const handleFilterToggle = () => {
    setIsActiveFilter((prevState) => {
      if (prevState === null) {
        return true; // Initially set to true to filter active items
      }
      return !prevState; // Toggle filter state
    });
  };
  const applyFilter = (data) => {
    if (isActiveFilter === null || isActiveFilter === false) {
      return data; // Return original data if filter is not set
    }
    return data.filter((item) => item.is_active === isActiveFilter);
  };

  const userProfile = ceo.states.userProfile;
  const checkRole = userProfile ? userProfile.role : null;

  const { people, unions, credits, favorites } = searchResults;

  let filteredPeople = [];
  if (checkRole === "producer") {
    const favoriteIds = favorites.map(
      (favorite) => favorite.person_receiver.id
    );
    filteredPeople = people.filter((person) => favoriteIds.includes(person.id));
  }

  return (
    <>
      <Flex justify="center" mt="4">
        <Switch
          isChecked={isActiveFilter}
          onChange={handleFilterToggle}
          colorScheme="teal"
          variant="outline"
          size="sm"
          borderRadius="xl"
          ml="3"
          mt="3"
        />
        <Box
          ml="2"
          borderRadius="10px"
          paddingX="4"
          paddingY="2"
          bg={isActiveFilter ? "teal.500" : "transparent"}
          borderColor={isActiveFilter ? "green.500" : "transparent"}
        >
          <Text color={isActiveFilter ? "white" : "gray"}>Active</Text>
        </Box>
      </Flex>

      {(checkRole === "admin" ||
        checkRole === "member" ||
        checkRole === "producer") && (
        <>
          <DynamicTable
            data={applyFilter(people)}
            headers={[
              "Profile Photo",
              "Class",
              "Description",
              "Name",
              "Phone Number",
              "Email",
            ]}
            heading="People"
          />
          <DynamicTable
            data={applyFilter(credits)}
            headers={[
              "Title",
              "Role",
              "Production Type",
              "Start Date",
              "End Date",
            ]}
            heading="Credits"
          />
          <DynamicTable
            data={applyFilter(unions)}
            headers={[
              "Profile Photo",
              "Name",
              "Description",

              "Website Link",
              "Email",
            ]}
            heading="Unions"
          />
        </>
      )}
      {checkRole === "producer" && (
        <>
          <DynamicTable
            data={applyFilter(filteredPeople)}
            headers={[
              "Profile Photo",
              "Class",
              "Description",
              "Name",
              "Phone Number",
              "Email",
            ]}
            heading="Favorites"
            people={people}
          />
        </>
      )}
    </>
  );
}

SearchResultTable.propTypes = {
  searchResults: PropTypes.shape({
    people: PropTypes.arrayOf(PropTypes.object),
    unions: PropTypes.arrayOf(PropTypes.object),
    credits: PropTypes.arrayOf(PropTypes.object),
    favorites: PropTypes.arrayOf(PropTypes.object),
  }),
};
