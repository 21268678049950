import React, { useState, useEffect } from "react";
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  Flex,
  Button,
  Icon,
  Heading,
  Tooltip,
  Avatar,
} from "@chakra-ui/react";
import { FaCaretDown } from "react-icons/fa";
import PropTypes from "prop-types";
import { useApp } from "AppContext/AppProvider";
import Loader from "./loader";
import { ImStarEmpty } from "react-icons/im";
import { MdStar } from "react-icons/md";
import manager from "../../helpers/manager";
import { Link } from "react-router-dom";
import { toast } from "react-hot-toast";

export function DynamicTable({ data, headers, heading, people }) {
  const ceo = useApp();

  const userProfile = ceo.states.userProfile;
  const checkRole = userProfile ? userProfile.role : null;

  const toggleFavourite = async (is_active, id) => {
    const getfavoriteMember = data.filter((person) => person.id === id);
    const getfavoriteMemberIndex = data.findIndex((person) => person.id === id);

    try {
      if (getfavoriteMember[0].is_favorite === false) {
        const response = await manager.createFavorite(is_active, id);

        if (response.ok) {
          const dataCreateFavorite = await response.json();
          data[getfavoriteMemberIndex].is_favorite = true;

          data[getfavoriteMemberIndex].favorite_id = dataCreateFavorite.id;
          await ceo.actions.setFavouritesResults([dataCreateFavorite]);
        } else {
          console.log("Did not get add favorites response");
        }
      } else {
        const deleteFavorite = data.filter((member) => member.id === id);
        const deleteFavoritesID = deleteFavorite[0].favorite_id;

        data[getfavoriteMemberIndex].is_favorite = false;
        data[getfavoriteMemberIndex].favorite_id = null;

        const response = await manager.deleteFavorite(deleteFavoritesID);

        const updateFavorite = ceo.states.favouritesResults
          ? ceo.states.favouritesResults.filter(
              (member) => member.person_receiver.id !== id
            )
          : [];
        ceo.actions.setFavouritesResults(updateFavorite);

        if (response.ok) {
          console.log("Deleted Favourites successfully");
        } else {
          console.log("Did not Get delete response");
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleCopyEmail = (email) => {
    navigator.clipboard.writeText(email);
    toast.success("Email copied to clipboard!");
  };
  return (
    <>
      <Heading as="h2" fontSize="2xl" color="gray.700" marginBottom="1%">
        {heading}
      </Heading>
      {data.length > 0 ? (
        <Box overflowX="auto" marginRight="1%" mt="5">
          <Table
            variant="simple"
            size="sm"
            borderWidth="1px"
            borderRadius="md"
            mb="4"
            borderBottom="1px"
            borderColor="gray.300"
          >
            <Thead>
              <Tr>
                {headers.map((header, index) => (
                  <Th
                    key={index}
                    borderBottom="1px"
                    borderColor="gray.300"
                    textAlign="center"
                  >
                    {header}
                  </Th>
                ))}
                {heading === "People" &&
                  (checkRole === "admin" || checkRole === "producer") && (
                    <>
                      <Th
                        borderBottom="1px"
                        borderColor="gray.300"
                        textAlign="center"
                      >
                        Actions
                      </Th>
                    </>
                  )}
              </Tr>
            </Thead>
            <Tbody>
              {data.map((item, index) => (
                <Tr key={index}>
                  {headers.map((header, index) => (
                    <Td
                      key={index}
                      borderBottom="1px"
                      borderColor="gray.300"
                      textAlign="center"
                      p="3"
                    >
                      {(heading === "Favorites" || heading === "People") && (
                        <>
                          {header === "Email" && (
                            // Check if the header is "Email" and if email exists
                            <>
                              <Tooltip label="Click to copy">
                                <Text
                                  onClick={() =>
                                    handleCopyEmail(item.user.email)
                                  }
                                  style={{ cursor: "pointer" }}
                                  _hover={{ textDecoration: "underline" }}
                                >
                                  {item.user.email ? item.user.email : "N/A"}
                                </Text>
                              </Tooltip>
                            </>
                          )}
                          <Link
                            key={item.id}
                            to={`/person-detail/${item?.user?.id}`}
                            style={{
                              textDecoration: "none",
                              width: "100%",
                            }}
                          >
                            {header === "Profile Photo" ?
                                (
                                <Avatar src={item.profile_photo} />
                              )  : header === "Name" ? (
                              (item?.user?.first_name && item?.user?.last_name) ? (
                                  `${item.user.first_name} ${item.user.last_name}`
                              ) : (
                                  item?.user?.first_name || item?.user?.last_name || "N/A"
                              )
                          )  : header === "Description" ? (
                              item.bio || "N/A"
                            ) : header === "Class" ? (
                              item.job_classification || "N/A"
                            ) : (
                              header === "Phone Number" &&
                              (item.phone_number || "N/A")
                            )}
                          </Link>
                        </>
                      )}
                      {heading === "Credits" && (
                        <>
                          {header === "Production Type"
                            ? item.production.title_type
                            : header === "Title"
                            ? item.production.title
                            : header === "Role"
                            ? item.role.title
                            : header === "End Date"
                            ? item.end_year || "N/A"
                            : header === "Start Date"
                            ? item.start_year || "N/A"
                            : "N/A"}
                        </>
                      )}

                      {heading === "Unions" && (
                        <>
                          {header === "Email" && item.email ? (
                            <Tooltip label="Click to copy">
                              <Text
                                onClick={() =>
                                  handleCopyEmail(
                                    item[header.toLowerCase().replace("_", "")]
                                  )
                                }
                                style={{ cursor: "pointer" }}
                                _hover={{ textDecoration: "underline" }}
                              >
                                {item.email}
                              </Text>
                            </Tooltip>
                          ) : header === "Profile Photo" &&
                            item.profile_photo ? (
                            <Link
                              key={item.id}
                              to={`/union-profile/${item.id}`}
                              style={{
                                textDecoration: "none",
                                width: "100%",
                              }}
                            >
                              <Avatar
                                src={item.profile_photo}
                                // alt={item.user.first_name}
                              />
                            </Link>
                          ) : // Render other values as is
                          item[header.toLowerCase().replace("_", "")] !==
                              undefined &&
                            item[header.toLowerCase().replace("_", "")] !==
                              "" &&
                            item[header.toLowerCase().replace("_", "")] !==
                              null ? (
                            <Link
                              key={item.id}
                              to={`/union-profile/${item.id}`}
                              style={{
                                textDecoration: "none",
                                width: "100%",
                              }}
                            >
                              {item[header.toLowerCase().replace("_", "")]}
                            </Link>
                          ) : (
                            <>N/A</>
                          )}
                        </>
                      )}
                      {/* Check if the header is "Production Type" */}
                    </Td>
                  ))}
                  {heading === "People" && checkRole === "admin" && (
                    <>
                      <Td
                        borderBottom="1px"
                        borderColor="gray.300"
                        textAlign="center"
                        p="3"
                      >
                        <Button
                          colorScheme="black"
                          variant="outline"
                          size="sm"
                          borderRadius="xl"
                        >
                          Actions <FaCaretDown />
                        </Button>
                      </Td>
                    </>
                  )}
                  {heading === "People" && checkRole === "producer" && (
                    <>
                      <Td
                        borderBottom="1px"
                        borderColor="gray.300"
                        textAlign="center"
                      >
                        <Button
                          onClick={() =>
                            toggleFavourite(item.is_active, item.id)
                          }
                          fontSize="30px"
                        >
                          {item.is_favorite ? (
                            <div style={{ color: "#f3c677" }}>
                              <MdStar />
                            </div>
                          ) : (
                            <div style={{ color: "#2c7a7b" }}>
                              <ImStarEmpty />
                            </div>
                          )}
                        </Button>
                      </Td>
                    </>
                  )}
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      ) : (
        <>
          <Flex align="center" justify="center" mt="4">
            <Text fontSize="lg" color="gray.500">
              No {heading} Found.
            </Text>
          </Flex>
        </>
      )}
    </>
  );
}

DynamicTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  headers: PropTypes.arrayOf(PropTypes.string).isRequired,
  heading: PropTypes.string.isRequired,
  checkRole: PropTypes.string,
  ceo: PropTypes.object,
  people: PropTypes.arrayOf(PropTypes.object),
};
