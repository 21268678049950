// Chakra Imports
// import { useState, useEffect } from "react";
import {
	Box,
	Flex,
	useColorModeValue,
	// useBreakpointValue,
	// Switch,
	// Text
} from "@chakra-ui/react";

import PropTypes from "prop-types";
import React from "react";
import { SearchBar } from "./searchBar/SearchBar";

export default function AdminNavbar(props) {
	// const [isActive, setIsActive] = useState(() => {
	// 	const storedState = JSON.parse(localStorage.getItem("switchState"));
	// 	return storedState !== null ? storedState : false;
	// });

	// const { secondary, message, brandText } = props;
	// Here are all the props that may change depending on navbar's type or state.(secondary, variant, scrolled)

	let navbarBg = useColorModeValue("#2D3748", "#1A202C");

	// const handleToogle = () => {
	// 	setIsActive((prev) => (!prev))

	// }
	// useEffect(() => {
	// 	localStorage.setItem("switchState", JSON.stringify(isActive));
	// }, [isActive]);




	return (
		<Box as="nav" pos="fixed" w="100%" top="0" left="0" bg={navbarBg} p="3" ml={{ base: 20, md: 60 }}
			zIndex={10}
		// pos={navbarPosition}
		>
			<Flex
				transitionDelay="0s, 0s, 0s, 0s"
				transitionDuration="0.25s, 0.25s, 0.25s, 0s"
				transitionProperty="box-shadow, background-color, filter, border"
				transitionTimingFunction="linear, linear, linear, linear"
			>
				<SearchBar />



			</Flex>
		</Box>

	);
}

AdminNavbar.propTypes = {
	brandText: PropTypes.string,
	variant: PropTypes.string,
	secondary: PropTypes.bool,
	fixed: PropTypes.bool,
	onOpen: PropTypes.func,
};
