import React, { useState, useEffect } from "react";
import Loader from "components/table/loader";
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Avatar,
  Button,
  Text,
  useDisclosure,
  Flex,
  Tooltip,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import { useApp } from "AppContext/AppProvider";
import { FaCaretDown } from "react-icons/fa";
import Navbar from "components/navbar/NavbarAdmin";
import manager from "../../helpers/manager";
import InviteRequestModal from "components/InviteModal/InviteRequestModal";
import { SearchResultTable } from "components/table/SearchResultsTable";
import { toast } from "react-hot-toast";
import { DeleteIcon } from "@chakra-ui/icons";
import { Link } from "react-router-dom";
import DeleteConfirmationPop from "views/members/DeleteConfirmationPop/DeleteConfirmationPop";

const Producers = () => {
  const [error, setError] = useState(null);
  const [producersDetails, setProducersDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [producerToDelete, setProducerToDelete] = useState(null);
  const {
    isOpen: isDeleteConfirmationOpen,
    onOpen: openDeleteConfirmation,
    onClose: closeDeleteConfirmation,
  } = useDisclosure();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const ceo = useApp();

  const fetchProducersListing = async () => {
    setIsLoading(true);
    try {
      const response = await manager.getProducers();
      if (!response.ok)
        throw new Error("Failed to fetch producers information");
      const data = await response.json();
      setProducersDetails(data);
    } catch (error) {
      setError(error.message || "Error fetching producers details");
    } finally {
      setIsLoading(false);
    }
  };
  // console.log("Producers", producersDetails)

  const handleDeleteConfirmation = async () => {
    if (producerToDelete) {
      try {
        const response = await manager.updateMembers(
          "delete",
          producerToDelete
        );
        if (response.ok) {
          const data = await response.json();
          closeDeleteConfirmation();
          setProducerToDelete(null);
          if (data.status == "Membership deleted successfully") {
            const updatedProducers = producersDetails.filter(
              (producer) => producer.id !== producerToDelete
            );
            setProducersDetails(updatedProducers);
            toast.success("Membership deleted successfully");
          }
        }
      } catch (error) {
        console.log(error.message || "Error deleting member");
      }
    }
  };

  useEffect(() => {
    fetchProducersListing();
    ceo.actions.setSearchResults({ people: [], unions: [] });
    ceo.actions.setSearchQuery("");
  }, []);
  const handleInviteSubmit = async ({
    email,
    description,
    link,
    unionId,
    membershipType,
    firstName,
    lastName,
  }) => {
    try {
      const response = await manager.createInvite(
        unionId,
        email,
        description,
        link,
        membershipType,
        firstName,
        lastName
      );
      if (!response.ok) {
        const errorText = await response.text();
        try {
          const errorJson = JSON.parse(errorText);
          throw new Error(errorJson.message || "Failed to send invite");
        } catch {
          throw new Error(errorText || "Failed to send invite");
        }
      }

      let inviteDetails = await response.json();
      toast.success("Invite sent successfully!");
    } catch (error) {
      console.error("Error sending invite:", error);
      toast.success(error.message || "Error sending invite");
    }
  };

  // Getting the User Role
  const userProfile = ceo.states.userProfile;
  const checkRole = userProfile ? userProfile.role : null;

  const hasSearchResults =
    ceo.states.searchResults.people.length > 0 ||
    ceo.states.searchResults.unions.length > 0;
  const hasSearchQuery = ceo.states.searchQuery === "";

  //copy email to clipboard
  const handleCopyEmail = (email) => {
    navigator.clipboard.writeText(email);
    toast.success("Email copied to clipboard!");
  };
  return (
    <>
      <Navbar />
      <Box ml={{ base: "90px", md: "250px" }} marginRight="1%" mt="20">
        <div>
          {!isLoading ? (
            <>
              {!hasSearchResults && hasSearchQuery ? (
                <>
                  {producersDetails && producersDetails.length > 0 ? (
                    <>
                      {checkRole === "admin" && (
                        <>
                          <Flex justify="flex-end">
                            <Button colorScheme="teal" onClick={onOpen} mt="4">
                              Send Invite
                            </Button>
                          </Flex>
                          <InviteRequestModal
                            isOpen={isOpen}
                            onClose={onClose}
                            onSubmit={handleInviteSubmit}
                          />
                        </>
                      )}
                      <>
                        <Box overflowX="auto">
                          <Table
                            variant="simple"
                            size="sm"
                            borderWidth="1px"
                            borderRadius="md"
                            mb="4"
                            borderBottom="1px"
                            borderColor="gray.300"
                            mt="5"
                          >
                            <Thead>
                              <Tr>
                                <Th
                                  borderBottom="1px"
                                  borderColor="gray.300"
                                  textAlign="center"
                                >
                                  Profile Photo
                                </Th>
                                <Th
                                  borderBottom="1px"
                                  borderColor="gray.300"
                                  textAlign="center"
                                >
                                  Class
                                </Th>
                                <Th
                                  borderBottom="1px"
                                  borderColor="gray.300"
                                  textAlign="center"
                                >
                                  Description
                                </Th>
                                <Th
                                  borderBottom="1px"
                                  borderColor="gray.300"
                                  textAlign="center"
                                >
                                  Name
                                </Th>
                                <Th
                                  borderBottom="1px"
                                  borderColor="gray.300"
                                  textAlign="center"
                                >
                                  Phone
                                </Th>
                                <Th
                                  borderBottom="1px"
                                  borderColor="gray.300"
                                  textAlign="center"
                                >
                                  Email
                                </Th>
                                <Th
                                  borderBottom="1px"
                                  borderColor="gray.300"
                                  textAlign="center"
                                >
                                  Union
                                </Th>
                                <Th
                                  borderBottom="1px"
                                  borderColor="gray.300"
                                  textAlign="center"
                                >
                                  Actions
                                </Th>
                              </Tr>
                            </Thead>
                            <Tbody>
                              {producersDetails.map((producer) => (
                                <Tr key={producer.person.user.id}>
                                  <Td
                                    borderBottom="1px"
                                    borderColor="gray.300"
                                    textAlign="center"
                                  >
                                    <Link
                                      key={producer.person.user.id}
                                      to={`/person-detail/${producer.person.user.id}`}
                                      style={{
                                        textDecoration: "none",
                                        width: "100%",
                                      }}
                                    >
                                      <Avatar src={producer.person.profile_photo} />
                                    </Link>
                                  </Td>
                                  <Td
                                    borderBottom="1px"
                                    borderColor="gray.300"
                                    textAlign="center"
                                  >
                                    <Link
                                      key={producer.person.user.id}
                                      to={`/person-detail/${producer.person.user.id}`}
                                      style={{
                                        textDecoration: "none",
                                        width: "100%",
                                      }}
                                    >
                                      {producer.person.job_classification || "N/A"}
                                    </Link>
                                  </Td>
                                  <Td
                                    borderBottom="1px"
                                    borderColor="gray.300"
                                    textAlign="center"
                                  >
                                    <Link
                                      key={producer.person.user.id}
                                      to={`/person-detail/${producer.person.user.id}`}
                                      style={{
                                        textDecoration: "none",
                                        width: "100%",
                                      }}
                                    >
                                      {producer.person.bio  || "N/A"}
                                    </Link>
                                  </Td>

                                  <Td
                                    borderBottom="1px"
                                    borderColor="gray.300"
                                    textAlign="center"
                                  >
                                    <Link
                                      key={producer.person.user.id}
                                      to={`/person-detail/${producer.person.user.id}`}
                                      style={{
                                        textDecoration: "none",
                                        width: "100%",
                                      }}
                                    >
                                      {producer.person.user.first_name}{" "}
                                      {producer.person.user.last_name}
                                    </Link>
                                  </Td>
                                  <Td
                                    borderBottom="1px"
                                    borderColor="gray.300"
                                    textAlign="center"
                                  >
                                    <Link
                                      key={producer.person.user.id}
                                      to={`/person-detail/${producer.person.user.id}`}
                                      style={{
                                        textDecoration: "none",
                                        width: "100%",
                                      }}
                                    >
                                      {producer.person.phone_number  || "N/A"}
                                    </Link>
                                  </Td>
                                  <Td
                                    borderBottom="1px"
                                    borderColor="gray.300"
                                    textAlign="center"
                                    onClick={() =>
                                      handleCopyEmail(
                                        producer.person.user.email
                                      )
                                    }
                                    style={{ cursor: "pointer" }}
                                  >
                                    {/* <Link
                                      key={producer.person.user.id}
                                      to={`/person-detail/${producer.person.user.id}`}
                                      style={{
                                        textDecoration: "none",
                                        width: "100%",
                                      }}
                                    > */}
                                    <Tooltip label="Click to copy">
                                      <Text
                                        _hover={{
                                          textDecoration: "underline",
                                        }}
                                      >
                                        {producer.person.user.email || "N/A"}
                                      </Text>
                                    </Tooltip>
                                    {/* </Link> */}
                                  </Td>
                                  <Td
                                    borderBottom="1px"
                                    borderColor="gray.300"
                                    textAlign="center"
                                  >
                                    <Link
                                      key={producer.person.user.id}
                                      to={`/person-detail/${producer.person.user.id}`}
                                      style={{
                                        textDecoration: "none",
                                        width: "100%",
                                      }}
                                    >
                                      {producer.union.name || "N/A"}
                                    </Link>
                                  </Td>

                                  <Td
                                    borderBottom="1px"
                                    borderColor="gray.300"
                                    textAlign="center"
                                  >
                                    <Menu>
                                      <MenuButton
                                        as={Button}
                                        size="sm"
                                        style={{ border: "1px solid " }}
                                      >
                                        <Flex align="center">
                                          Actions <FaCaretDown />
                                        </Flex>
                                      </MenuButton>
                                      <MenuList>
                                        <MenuItem
                                          onClick={() => {
                                            setProducerToDelete(producer.id);
                                            openDeleteConfirmation();
                                          }}
                                        >
                                          {/* <Tooltip label="Delete" fontSize="md"> */}
                                          <Flex align="center">
                                            <Button
                                              color="white"
                                              bgColor="teal.500"
                                              _hover={{
                                                bgColor: "teal.600",
                                              }}
                                              variant="outline"
                                              size="sm"
                                              borderRadius="xl"
                                            >
                                              <DeleteIcon />
                                            </Button>
                                            <Text
                                              ml="2"
                                              fontSize="md"
                                              textAlign="center"
                                            >
                                              Delete
                                            </Text>
                                          </Flex>
                                          {/* </Tooltip> */}
                                        </MenuItem>
                                        {/* Add other menu items for additional actions */}
                                      </MenuList>
                                    </Menu>
                                  </Td>
                                </Tr>
                              ))}
                            </Tbody>
                          </Table>
                        </Box>
                        <DeleteConfirmationPop
                          isOpen={isDeleteConfirmationOpen}
                          onClose={closeDeleteConfirmation}
                          onConfirm={handleDeleteConfirmation}
                        />
                      </>
                    </>
                  ) : (
                    <Flex align="center" justify="center" mt="4">
                      <Text fontSize="lg" color="gray.500">
                        No Producer Found.
                      </Text>
                    </Flex>
                  )}
                </>
              ) : (
                <>
                  <Box overflowX="auto">
                    {/* Search Results Table */}
                    <SearchResultTable
                      searchResults={ceo.states.searchResults}
                    />
                  </Box>
                </>
              )}
            </>
          ) : (
            <Loader />
          )}
        </div>
      </Box>
    </>
  );
};

export default Producers;
