import { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Text,
} from "@chakra-ui/react";
import PropTypes from "prop-types";

const DeleteConfirmationPop = ({ isOpen, onClose, onConfirm }) => {
  const [isConfirming, setIsConfirming] = useState(false);
  const handleConfirmClick = async () => {
    setIsConfirming(true); // Disable the button
    await onConfirm(); // Wait for the confirmation action

    // Reset the confirmation state after the action is completed
    setIsConfirming(false);
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Confirmation</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text>Are you sure you want to delete this?</Text>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="teal" mr={3} onClick={handleConfirmClick} isLoading={isConfirming}>
            Confirm
          </Button>
          <Button variant="outline" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DeleteConfirmationPop;

DeleteConfirmationPop.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};
