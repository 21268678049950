import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Avatar,
  Text,
  Flex,
  Tooltip,
} from "@chakra-ui/react";
import { useApp } from "AppContext/AppProvider";
import Navbar from "components/navbar/NavbarAdmin";
import { FaCaretDown } from "react-icons/fa";
import { MdStar } from "react-icons/md";
import { SearchResultTable } from "components/table/SearchResultsTable";
import Loader from "components/table/loader";
import manager from "helpers/manager";
import { Link } from "react-router-dom";
import { toast } from "react-hot-toast";

const Favourites = () => {
  const ceo = useApp();

  const [isLoading, setIsLoading] = useState(true);

  const fetchFavouritesListing = async () => {
    setIsLoading(true);

    try {
      const response = await manager.getFavorites();
      if (response.ok) {
        const data = await response.json();
        ceo.actions.setFavouritesResults(data);
      } else {
        console.log("Error fetching favorites");
      }
    } catch (error) {
      console.log(error, "Error in fetching the favorites");
    } finally {
      setIsLoading(false);
    }
  };

  const toggleFavourite = async (id) => {
    try {
      const deleteFavorite = ceo.states.favouritesResults.filter(
        (favorite) => favorite.person_receiver.id === id
      );

      if (deleteFavorite) {
        const deleteFavoritesID = deleteFavorite[0].id;
        const response = await manager.deleteFavorite(deleteFavoritesID);
        if (response.ok) {
          console.log("Deleted Favourite successfully");
          // Remove the favorite from the frontend
          const updatedFavorites = ceo.states.favouritesResults.filter(
            (favorite) => favorite.id !== deleteFavoritesID
          );
          ceo.actions.setFavouritesResults(updatedFavorites);
          ceo.actions.setMembersResults((prevStates) => ({
            ...prevStates,
            is_favorite: false,
          }));
        } else {
          console.log("Did not get delete response");
        }
      } else {
        console.log("Favorite not found");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchFavouritesListing();
    ceo.actions.setSearchResults({ people: [], unions: [] });
    ceo.actions.setSearchQuery("");
  }, []);

  const hasSearchQuery = ceo.states.searchQuery === "";

  // Getting the User Role
  const userProfile = ceo.states.userProfile;
  const checkRole = userProfile ? userProfile.role : null;

  const hasSearchResults =
    ceo.states.searchResults.people.length > 0 ||
    ceo.states.searchResults.unions.length > 0;

  //copy email to clipboard
  const handleCopyEmail = (email) => {
    navigator.clipboard.writeText(email);
    toast.success("Email copied to clipboard!");
  };

  return (
    <>
      <Navbar />
      <Box ml={{ base: "90px", md: "250px" }} marginRight="1%" mt="20">
        {!isLoading ? (
          <>
            {!hasSearchResults && hasSearchQuery ? (
              <>
                {ceo.states.favouritesResults.length > 0 ? (
                  <>
                    <Box overflowX="auto">
                      <Table
                        variant="simple"
                        size="sm"
                        borderWidth="1px"
                        borderRadius="md"
                        mb="4"
                        borderBottom="1px"
                        borderColor="gray.300"
                        mt="5"
                      >
                        <Thead>
                          <Tr>
                            <Th
                              borderBottom="1px"
                              borderColor="gray.300"
                              textAlign="center"
                            >
                              Profile Photo
                            </Th>
                            <Th
                              borderBottom="1px"
                              borderColor="gray.300"
                              textAlign="center"
                            >
                              Class
                            </Th>
                            <Th
                              borderBottom="1px"
                              borderColor="gray.300"
                              textAlign="center"
                            >
                              Description
                            </Th>
                            <Th
                              borderBottom="1px"
                              borderColor="gray.300"
                              textAlign="center"
                            >
                              Name
                            </Th>
                            <Th
                              borderBottom="1px"
                              borderColor="gray.300"
                              textAlign="center"
                            >
                              Phone
                            </Th>
                            <Th
                              borderBottom="1px"
                              borderColor="gray.300"
                              textAlign="center"
                            >
                              Email
                            </Th>

                            {(checkRole === "admin" ||
                              checkRole === "producer") && (
                              <Th
                                borderBottom="1px"
                                borderColor="gray.300"
                                textAlign="center"
                              >
                                Actions
                              </Th>
                            )}
                          </Tr>
                        </Thead>
                        <Tbody>
                          {ceo.states.favouritesResults.map((favorite) => (
                            <Tr key={favorite.id}>
                              <Td
                                borderBottom="1px"
                                borderColor="gray.300"
                                textAlign="center"
                              >
                                <Link
                                  key={favorite.id}
                                  to={`/person-detail/${favorite.person_receiver.user.id}`}
                                  style={{
                                    textDecoration: "none",
                                    width: "100%",
                                  }}
                                >
                                  <Avatar src={favorite.profilePhoto} />
                                </Link>
                              </Td>
                              <Td
                                borderBottom="1px"
                                borderColor="gray.300"
                                textAlign="center"
                              >
                                <Link
                                  key={favorite.id}
                                  to={`/person-detail/${favorite.person_receiver.user.id}`}
                                  style={{
                                    textDecoration: "none",
                                    width: "100%",
                                  }}
                                >
                                  {favorite.person_receiver.job_classification}
                                </Link>
                              </Td>
                              <Td
                                borderBottom="1px"
                                borderColor="gray.300"
                                textAlign="center"
                              >
                                <Link
                                  key={favorite.id}
                                  to={`/person-detail/${favorite.person_receiver.id}`}
                                  style={{
                                    textDecoration: "none",
                                    width: "100%",
                                  }}
                                >
                                  {favorite.person_receiver.bio}
                                </Link>
                              </Td>
                              <Td
                                borderBottom="1px"
                                borderColor="gray.300"
                                textAlign="center"
                              >
                                <Link
                                  key={favorite.id}
                                  to={`/person-detail/${favorite.person_receiver.user.id}`}
                                  style={{
                                    textDecoration: "none",
                                    width: "100%",
                                  }}
                                >
                                  {favorite.person_receiver.user.first_name}{" "}
                                  {favorite.person_receiver.user.last_name}
                                </Link>
                              </Td>
                              <Td
                                borderBottom="1px"
                                borderColor="gray.300"
                                textAlign="center"
                              >
                                <Link
                                  key={favorite.id}
                                  to={`/person-detail/${favorite.person_receiver.user.id}`}
                                  style={{
                                    textDecoration: "none",
                                    width: "100%",
                                  }}
                                >
                                  {favorite.person_receiver.phone_number}
                                </Link>
                              </Td>
                              <Td
                                borderBottom="1px"
                                borderColor="gray.300"
                                textAlign="center"
                              >
                                <Tooltip label="Click to copy">
                                  <Text
                                    _hover={{
                                      textDecoration: "underline",
                                    }}
                                    onClick={() =>
                                      handleCopyEmail(
                                        favorite.person_receiver.user.email
                                      )
                                    }
                                    style={{ cursor: "pointer" }}
                                  >
                                    {favorite.person_receiver.user.email}
                                  </Text>
                                </Tooltip>
                              </Td>

                              {checkRole === "producer" && (
                                <Td
                                  borderBottom="1px"
                                  borderColor="gray.300"
                                  textAlign="center"
                                >
                                  {checkRole === "producer" && (
                                    <Button
                                      onClick={() =>
                                        toggleFavourite(
                                          favorite.person_receiver.id
                                        )
                                      }
                                      fontSize="35px"
                                      color="#f3c677"
                                    >
                                      <MdStar />
                                    </Button>
                                  )}
                                </Td>
                              )}
                            </Tr>
                          ))}
                        </Tbody>
                      </Table>
                    </Box>
                  </>
                ) : (
                  <Flex align="center" justify="center" mt="4">
                    <Text fontSize="lg" color="gray.500">
                      No Favorites Member Found.
                    </Text>
                  </Flex>
                )}
              </>
            ) : (
              <>
                <Box overflowX="auto">
                  {/* Search Results Table */}
                  <SearchResultTable searchResults={ceo.states.searchResults} />
                </Box>
              </>
            )}
          </>
        ) : (
          <Loader />
        )}
      </Box>
      {/* <DefaultAuth /> */}
    </>
  );
};

export default Favourites;
