import { useState, useEffect } from "react";
import React from "react";
import { NavLink, useLocation, Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
// Chakra imports
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Text,
  useColorModeValue,
  Button,
} from "@chakra-ui/react";
// Custom components
import DefaultAuth from "layouts/auth/Default";
// Assets

import illustrationNight from "assets/img/auth/london.jpg";
import illustrationDay from "assets/img/auth/london-day.jpg";
// import Cookie from "js-cookie";
import manager from "helpers/manager";
import { useApp } from "AppContext/AppProvider";
import { toast } from "react-hot-toast";

function EmailConfirmation() {
  const {
    states: { updateStatus },
  } = useApp();
  const ceo = useApp();
  // Chakra color mode
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  //consts or variables
  const navigate = useNavigate();
  const textColor = useColorModeValue("rgb(77, 146, 154)", "white");
  const textColorBrand = useColorModeValue("rgb(77, 146, 154)", "white");

  const textColorSecondary = "gray.400";
  const brandStars = useColorModeValue("rgb(77, 146, 154)", "brand.400");

  const sendResetEmail = async (e) => {
    e.preventDefault();
    const email = e.target[0].value;
    setIsLoading(true);
    try {
      const response = await manager.resetPasswordEmail(email);
      if (response.ok) {
        const data = await response.json();
        toast.success("email sent successfully");
      } else {
        toast.error("Email not sent");
      }
    } catch (error) {
      console.err(error, "Error in sending email");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <DefaultAuth
      illustrationBackgroundDay={illustrationDay}
      illustrationBackgroundNight={illustrationNight}
    >
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w="100%"
        mx={{ base: "auto", lg: "0px" }}
        me="auto"
        h="100%"
        alignItems="start"
        justifyContent="center"
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection="column"
      >
        <Box me="auto">
          <Heading color={textColor} fontSize="36px" mb="10px">
            Email Confirmation
          </Heading>
          <Text
            mb="36px"
            ms="4px"
            color={textColorSecondary}
            fontWeight="400"
            fontSize="md"
          >
            Enter your email to reset password
          </Text>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: "100%", md: "420px" }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
          mb={{ base: "20px", md: "20px" }}
        >
          <form onSubmit={sendResetEmail}>
            <FormControl>
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                Email<Text color={brandStars}>*</Text>
              </FormLabel>
              <Input
                isRequired={true}
                variant="auth"
                fontSize="sm"
                ms={{ base: "0px", md: "0px" }}
                type="email"
                placeholder="example@mail.com"
                mb="24px"
                fontWeight="500"
                size="lg"
              />
              <Button
                isLoading={isLoading}
                type="submit"
                fontSize="sm"
                variant="brand"
                fontWeight="500"
                w="100%"
                background="rgb(77, 146, 154)"
                h="50"
                // mb="24px"
              >
                Confirm Email
              </Button>
              {error ? <font color="red">{error}</font> : <font></font>}
            </FormControl>
          </form>
        </Flex>
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="start"
          maxW="100%"
          mt="0px"
        >
          <Text color="gray.400" fontWeight="400" fontSize="14px">
            Back To
            <NavLink to="/auth">
              <Text color={textColorBrand} as="span" ms="5px" fontWeight="500">
                Sign in{" "}
              </Text>
            </NavLink>
          </Text>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default EmailConfirmation;
