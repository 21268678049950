import React, { useState, useEffect } from "react";
import {
  Box,
  Text,
  Flex,
  HStack,
  Avatar,
  Button,
  Stack,
  Input,
  useDisclosure,
  Tooltip,
} from "@chakra-ui/react";
import Navbar from "components/navbar/NavbarAdmin";
import { useApp } from "../../AppContext/AppProvider";
import manager from "../../helpers/manager";
import Loader from "components/table/loader";
import { AddIcon } from "@chakra-ui/icons";
import CreditsModal from "components/AddCreditsModal/AddCreditsModal";
import { toast } from "react-hot-toast";
import { Link, useParams } from "react-router-dom";
import DeleteConfirmationPop from "views/members/DeleteConfirmationPop/DeleteConfirmationPop";
import { LuExternalLink } from "react-icons/lu";
import { MdModeEditOutline, MdEdit } from "react-icons/md";
import { SearchResultTable } from "components/table/SearchResultsTable";

const UserProfileComponent = () => {
  const { states } = useApp();
  const {
    states: { userProfile, searchResults },
  } = useApp();

  const ceo = useApp();

  const { id: personDetailID } = useParams();

  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const [profileDetails, setProfileDetails] = useState([]);
  const [creditDeleteId, setCreditDelete] = useState([]);
  const [selectedCredit, setSelectedCredit] = useState({});
  const [updatedCredit, setUpdatedCredit] = useState(false);
  const [originalProfileDetails, setOriginalProfileDetails] = useState([]);
  const [unionsDetails, setUnionsDetails] = useState(null);
  const [avatarPhoto, setAvatarPhoto] = useState(null);
  const [avatarPreview, setAvatarPreview] = useState(null);

  const [personID, setPersonID] = useState(null);
  const [UserProfileID, setUserProfileID] = useState(null);
  const [creditDetailForPerson, setCreditDetailForPerson] = useState(null);
  const [personUnions, setPersonUnions] = useState(null);
  const [checkRole, setCheckRole] = useState("");
  const [isAvailable, setIsAvailable] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [isLoadingPhoto, setIsLoadingPhoto] = useState(null);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isDeleteConfirmationOpen,
    onOpen: openDeleteConfirmation,
    onClose: closeDeleteConfirmation,
  } = useDisclosure();

  useEffect(() => {
    if (!personDetailID) {
      fetchProfileDetails(userProfile.id);
      setUserProfileID(userProfile.id);
      setPersonID(null);
      fetchUnionsListing();
    } else {
      fetchProfileDetails(personDetailID);
      fetchPersonUnion(personDetailID);
      setUserProfileID(null);
      setPersonID(personDetailID);
    }
    // setIsLoading(true);
    // setTimeout(() => {
    //   setIsLoading(false);
    // }, 2000);

    ceo.actions.setSearchResults({ people: [], unions: [] });
    ceo.actions.setSearchQuery("");
  }, [personDetailID]);

  const fetchPersonUnion = async (id) => {
    try {
      setIsLoading(true);
      const response = await manager.getPersonUnions(id);
      if (!response.ok) {
        throw new Error("Failed to fetch user profile");
      }

      const data = await response.json();
      setPersonUnions(data.unions);
    } catch (error) {
      console.log(error.message || "Error fetching perosn Unions");
    } finally {
      setIsLoading(false);
    }
  };

  const fetchUnionsListing = async () => {
    try {
      setIsLoading(true);
      const response = await manager.getUnions();

      if (!response) {
        throw new Error("Failed to fetch Unions information");
      } else {
        setUnionsDetails(response);
      }
    } catch (error) {
      setError(error.message || "Error fetching unions details");
    } finally {
      setIsLoading(false);
    }
  };

  const fetchCredits = async () => {
    try {
      setIsLoading(true);
      const response = await manager.getCredits();
      if (!response.ok) {
        throw new Error("Failed to fetch credits information");
      }

      const data = await response.json();
      ceo.actions.setCreditsDetails(data);
    } catch (error) {
      console.log(error, "Error fetching credits");
    } finally {
      setIsLoading(false);
    }
  };

  const fetchCreditsForPerson = async (personID) => {
    ceo.actions.setCreditsDetails("");
    try {
      setIsLoading(true);
      const response = await manager.getCreditsForPerson(personID);
      if (!response.ok) {
        throw new Error("Failed to fetch credits information");
      }

      const data = await response.json();
      setCreditDetailForPerson(data);
    } catch (error) {
      console.log(error, "Error fetching credits");
    } finally {
      setIsLoading(false);
    }
  };
  const memberStatusUpdate = async () => {
    const memberId = userProfile.id;

    try {
      setIsLoading(true);
      const response = await manager.updateAvailabilityStatus(
        !isAvailable,
        memberId
      );
      if (response.ok) {
        // const data = await response.json();

        setIsAvailable(!isAvailable);
        toast.success("Thank you! your availability has been updated");
      } else {
        console.log("Error fetching status");
      }
    } catch (error) {
      console.log(error, "Error in fetching the status");
    } finally {
      setIsLoading(false);
    }
  };
  const handleDeleteCredit = async () => {
    try {
      setIsLoading(true);
      const response = await manager.deleteCredits(creditDeleteId);
      if (response.ok) {
        closeDeleteConfirmation();
        toast.success("Credit deleted successfully");
        // Fetch credits again after deletion
        const updatedCredit = ceo.states.creditsDetails.filter(
          (credit) => credit.id !== creditDeleteId
        );
        ceo.actions.setCreditsDetails(updatedCredit);
        // fetchCredits();
      }
    } catch (error) {
      console.error("Error deleting credit:", error);
      // toast.error("Failed to delete credit");
    } finally {
      setIsLoading(false);
    }
  };

  const fetchProfileDetails = async (id) => {
    try {
      setIsLoading(true);
      const response = await manager.getPersonDetails(id);
      setIsLoadingPhoto(true);

      if (!response.ok) {
        throw new Error("Failed to fetch user profile");
      }
      const data = await response.json();
      setProfileDetails(data);
      setIsAvailable(data.is_active);
      setCheckRole(data.user.role);
      setOriginalProfileDetails(data);
      // setAvatarPhoto(data.profile_photo);
      setAvatarPreview(data.profile_photo);
      if (personDetailID) {
        fetchCreditsForPerson(data.id);
      } else {
        fetchCredits();
      }
    } catch (error) {
      setError(error.message || "Error fetching user profile");
    } finally {
      setIsLoadingPhoto(false);
      setIsLoading(false);
    }
  };

  const handleAvatarChange = (e) => {
    const file = e.target.files[0];

    const allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;

    if (file.size > 200 * 1024) {
      toast.error("File size should be less than 200KB");
      return;
    }

    if (!allowedExtensions.exec(file.name)) {
      // Check if the file extension is not allowed
      toast.error(
        "Only JPG, JPEG, and PNG files are allowed for profile photo"
      );
    } else {
      setAvatarPhoto(file);
      setAvatarPreview(URL.createObjectURL(file));
    }
  };

  const handleEidtProfile = () => {
    setProfileDetails(originalProfileDetails);
    if (avatarPhoto) {
      setAvatarPreview(originalProfileDetails.profile_photo);
    }
    setUpdatedCredit(false);
    setEditMode(!editMode);
  };

  const handleSaveChanges = async () => {
    try {
      if (avatarPhoto) {
        setIsUploading(true);
        setIsLoadingPhoto(true);

        const responsePhoto = await manager.createProfilePhoto(
          profileDetails.user.id,
          avatarPhoto
        );
        if (responsePhoto.ok) {
          const dataPhoto = await responsePhoto.json();
          setOriginalProfileDetails(dataPhoto);
          setAvatarPhoto(null);
          setAvatarPreview(dataPhoto.profile_photo);

          setProfileDetails(dataPhoto);
          setEditMode(!editMode);
        } else {
          console.error("Failed to upload photo");
        }
      }

      const updatedDetails = { ...profileDetails };
      delete updatedDetails.profile_photo; // Remove profile photo from the update object
      const hasChanges = Object.keys(updatedDetails).some(
        (key) => updatedDetails[key] !== originalProfileDetails[key]
      );
      if (hasChanges) {
        setIsUploading(true);
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if(!profileDetails.user.email){
          toast.error("Email is required");
          return;
        }

        if (!emailRegex.test(profileDetails.user.email)) {
          toast.error("Invalid email format");
          return;
        }

        const response = await manager.updateProfileDetaitls(
          profileDetails.user.id,
          profileDetails
        );
        if (response.ok) {
          toast.success("Profile updated successfully");
          const data = await response.json();

          setProfileDetails(data);
          setOriginalProfileDetails(data);
          setEditMode(!editMode);
        } else {
          const data = await response.json();

          if (data.error === "IMDB profile link is invalid") {
            toast.error("IMDB profile link is invalid");
          }
        }
      }
    } catch (error) {
      console.log("Error updating profile:", error);
    } finally {
      setIsUploading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    // setDataForUpdate((prevData) => ({ ...prevData, [name]: value }));

    if (name === "first_name" || name === "last_name" || name === "email") {
      setProfileDetails((prevProfile) => ({
        ...prevProfile,
        user: { ...prevProfile.user, [name]: value },
      }));
    } else {
      setProfileDetails((prevProfile) => ({
        ...prevProfile,
        [name]: value,
      }));
    }
  };
  //copy email to clipboard
  const handleCopyEmail = (email) => {
    navigator.clipboard.writeText(email);
    toast.success("Email copied to clipboard!");
  };

  return (
    <>
      <Navbar />
      <Box ml={{ base: "90px", md: "250px" }} marginTop="90px">
        {searchResults.people.length === 0 &&
        searchResults.unions.length === 0 &&
        !states.searchQuery ? (
          <>
            {!isLoading ? (
              <>
                {" "}
                <Flex
                  flexDirection={{ base: "column", md: "row" }} // Stack vertically on small screens, horizontally on medium and larger screens
                >
                  <HStack align="start" spacing="8">
                    {profileDetails && (
                      <>
                        {UserProfileID && editMode ? (
                          <label
                            htmlFor="avatarInput"
                            style={{ position: "relative" }}
                          >
                            <Box
                              cursor="pointer"
                              boxSize={{ base: "140px", md: "180px" }}
                              borderRadius="full"
                              overflow="hidden"
                              position="relative"
                            >
                              {isUploading ? ( // Show loader if uploading
                                <Loader />
                              ) : (
                                <Avatar
                                  size="2xl"
                                  boxSize="100%"
                                  src={avatarPreview}
                                  _hover={{
                                    bg: "black",
                                  }}
                                />
                              )}
                            </Box>
                            <Input
                              name="image"
                              id="avatarInput"
                              type="file"
                              accept="image/*"
                              display="none"
                              onChange={handleAvatarChange}
                            />
                            <MdEdit
                              style={{
                                fontSize: "30px",
                                position: "relative",
                                bottom: "10px",
                                right: "5px",
                                left: "10px",
                                backgroundColor: "gray",
                                borderRadius: "50%",
                                padding: "5px",
                                color: "white",
                              }}
                            />
                          </label>
                        ) : (
                          <>
                            <Avatar
                              onLoad={() => {
                                // Check if profile photo exists or is null, if yes, set isLoadingPhoto to false
                                if (
                                  profileDetails.profile_photo === null ||
                                  profileDetails.profile_photo
                                ) {
                                  setIsLoadingPhoto(false);
                                }
                              }}
                              size="2xl"
                              boxSize={{ base: "140px", md: "180px" }}
                              display={isLoadingPhoto ? "none" : "block"}
                              name={
                                `${profileDetails.user?.first_name || ""} ${
                                  profileDetails.user?.last_name || ""
                                }` || ""
                              }
                              src={profileDetails.profile_photo}
                            />

                            {isLoadingPhoto && (
                              <Box boxSize={{ base: "140px", md: "180px" }}>
                                <Loader />
                              </Box>
                            )}
                          </>
                        )}
                      </>
                    )}

                    <Stack align="start">
                      {profileDetails && (
                        <>
                          {UserProfileID && editMode ? (
                            <>
                              <Input
                                size="sm"
                                type="text"
                                name="bio"
                                value={profileDetails.bio || ""}
                                placeholder={"Enter a Job Title"}
                                onChange={handleChange}
                              />
                              <Input
                                size="sm"
                                type="text"
                                name="first_name"
                                placeholder="Enter Your First Name"
                                value={profileDetails?.user?.first_name || ""}
                                onChange={handleChange}
                              />
                              <Input
                                size="sm"
                                type="text"
                                name="last_name"
                                placeholder="Enter Your Last Name"
                                value={profileDetails?.user?.last_name || ""}
                                onChange={handleChange}
                              />
                              <Input
                                size="sm"
                                type="email"
                                name="email"
                                placeholder="Enter Your Email"
                                value={profileDetails?.user?.email || ""}
                                onChange={handleChange}
                              />
                              <Input
                                size="sm"
                                type="link"
                                name="imdb_profile"
                                placeholder="Enter Your IMDB Profile"
                                value={profileDetails.imdb_profile || ""}
                                onChange={handleChange}
                              />

                              <Input
                                size="sm"
                                type="text"
                                name="phone_number"
                                value={profileDetails.phone_number || ""}
                                placeholder={"Enter a Phone Number"}
                                onChange={handleChange}
                              />
                              <Input
                                size="sm"
                                type="text"
                                name="address"
                                value={profileDetails.address || ""}
                                placeholder={"Enter a City"}
                                onChange={handleChange}
                              />
                              <Flex>
                                <Button
                                  mt="5px"
                                  colorScheme="black"
                                  variant="outline"
                                  size="sm"
                                  borderRadius="xl"
                                  mr="1"
                                  // ml={{ base: 0, md: 2 }}
                                  color="white"
                                  _hover={{
                                    bgColor: "transparent",
                                    color: "#4d929a",
                                    borderColor: "#4d929a",
                                    borderRadius: "lg",
                                  }}
                                  backgroundColor="#4d929a"
                                  onClick={handleSaveChanges}
                                  isDisabled={isUploading}
                                >
                                  Save Profile
                                </Button>
                                <Button
                                  mt="5px"
                                  colorScheme="black"
                                  variant="outline"
                                  size="sm"
                                  borderRadius="xl"
                                  ml={{ base: 0, md: 2 }}
                                  onClick={handleEidtProfile}
                                  _hover={{
                                    borderRadius: "lg",
                                    color: "blackAlpha.600",
                                  }}
                                >
                                  Cancel
                                </Button>
                              </Flex>
                            </>
                          ) : (
                            <>
                              <div>
                                <Text fontSize="2xl" fontWeight="bold">
                                  {profileDetails.user?.first_name
                                    .charAt(0)
                                    .toUpperCase() +
                                    profileDetails.user?.first_name.slice(
                                      1
                                    )}{" "}
                                  {profileDetails.user?.last_name
                                    ? profileDetails.user?.last_name
                                        .charAt(0)
                                        .toUpperCase() +
                                      profileDetails.user?.last_name.slice(1)
                                    : ""}
                                </Text>
                                <Text ml="5px" mt="2px" fontSize="sm">
                                  {profileDetails.bio}
                                </Text>
                                <Tooltip label="Click to copy">
                                  <Text
                                    ml="5px"
                                    mt="2px"
                                    fontSize="sm"
                                    _hover={{
                                      textDecoration: "underline",
                                    }}
                                    onClick={() =>
                                      handleCopyEmail(
                                        profileDetails.user?.email
                                      )
                                    }
                                    style={{ cursor: "pointer" }}
                                  >
                                    {profileDetails.user?.email}
                                  </Text>
                                </Tooltip>

                                <Link
                                  target="_blank"
                                  to={profileDetails.imdb_profile}
                                  key={profileDetails.id}
                                  style={{
                                    color: "blue",
                                    cursor: "pointer",
                                    textDecoration: "underline",
                                    fontStyle: "italic", // Initially no underline
                                  }}
                                >
                                  <Text ml="5px" mt="2px" fontSize="sm">
                                    {profileDetails.imdb_profile &&
                                      "IMDB Profile"}
                                  </Text>
                                </Link>

                                <Text ml="5px" mt="2px" fontSize="sm">
                                  {profileDetails.phone_number}
                                </Text>

                                <Text ml="5px" mt="2px" fontSize="sm">
                                  {profileDetails.address}
                                </Text>

                                {UserProfileID && userProfile.id && (
                                  <>
                                    {checkRole === "member" && (
                                      <Button
                                        mt="5px"
                                        size="sm"
                                        borderRadius="xl"
                                        color="white"
                                        mr="1"
                                        bgColor={
                                          isAvailable ? "teal.500" : "red.500"
                                        }
                                        _hover={{
                                          bgColor: isAvailable
                                            ? "teal.600"
                                            : "red.600",
                                        }}
                                        onClick={() =>
                                          memberStatusUpdate(isAvailable)
                                        }
                                      >
                                        {isAvailable
                                          ? "Available To Work"
                                          : "Not Available To Work"}
                                      </Button>
                                    )}

                                    <Button
                                      mt="5px"
                                      colorScheme="black"
                                      variant="outline"
                                      size="sm"
                                      borderRadius="xl"
                                      ml={{ base: 0, md: 2 }}
                                      onClick={handleEidtProfile}
                                      _hover={{
                                        borderRadius: "lg",
                                        color: "blackAlpha.600",
                                      }}
                                    >
                                      Edit Profile
                                    </Button>
                                  </>
                                )}
                              </div>
                            </>
                          )}
                        </>
                      )}
                    </Stack>
                  </HStack>
                </Flex>
                <Box>
                  <Text mt="5" fontWeight="bold" fontSize="25px">
                    Information
                  </Text>
                  <Box ml="15px">
                    <Box>
                      {!editMode && (
                        <>
                          <Text
                            style={{ fontWeight: "bold", display: "inline" }}
                            fontSize="13px"
                          >
                            Union Memberships:
                          </Text>
                          {UserProfileID && unionsDetails ? (
                            <>
                              {checkRole === "admin" ||
                              checkRole === "member" ? (
                                <>
                                  {unionsDetails.map((union) => (
                                    <>
                                      <Link
                                        to={`/union-profile/${union.id}`}
                                        style={{
                                          color: "blue",
                                          cursor: "pointer",
                                          textDecoration: "underline", // Initially no underline
                                        }}
                                        key={union.id}
                                      >
                                        <Text
                                          style={{ display: "inline" }}
                                          fontSize="13px"
                                          ml="2"
                                        >
                                          {union.name}
                                        </Text>
                                      </Link>
                                    </>
                                  ))}
                                </>
                              ) : (
                                <>
                                  {unionsDetails?.is_member?.map((union) => (
                                    <>
                                      <Link
                                        to={`/union-profile/${union.id}`}
                                        style={{
                                          color: "blue",
                                          cursor: "pointer",
                                          textDecoration: "underline", // Initially no underline
                                        }}
                                        key={union.id}
                                      >
                                        <Text
                                          style={{ display: "inline" }}
                                          fontSize="13px"
                                          ml="2"
                                        >
                                          {union.name}
                                        </Text>
                                      </Link>
                                    </>
                                  ))}
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              {personUnions?.map((union) => (
                                <>
                                  <Link
                                    to={`/union-profile/${union.id}`}
                                    style={{
                                      color: "blue",
                                      cursor: "pointer",
                                      textDecoration: "underline",
                                    }}
                                    key={union.id}
                                  >
                                    <Text
                                      style={{ display: "inline" }}
                                      fontSize="13px"
                                      ml="2"
                                    >
                                      {union.name}
                                    </Text>
                                  </Link>
                                </>
                              ))}
                            </>
                          )}
                        </>
                      )}
                    </Box>
                    <Flex align="center">
                      {" "}
                      {/* Use Flex container */}
                      {(profileDetails.certification || editMode) && (
                        <Text
                          style={{ fontWeight: "bold", display: "inline" }}
                          fontSize="13px"
                          mr="8" // Add margin-right for spacing
                        >
                          Certifications:
                        </Text>
                      )}
                      <Box width="40%">
                        {editMode ? (
                          <Input
                            size="sm"
                            type="text"
                            name="certification"
                            value={profileDetails.certification || ""}
                            placeholder="Enter Your Certifications"
                            onChange={handleChange}
                          />
                        ) : (
                          <Text fontSize="13px" style={{ display: "inline" }}>
                            {profileDetails.certification}
                          </Text>
                        )}
                      </Box>
                    </Flex>

                    <Flex align="center">
                      <Box>
                        {(profileDetails.job_classification || editMode) && (
                          <Text
                            fontSize="13px"
                            style={{ fontWeight: "bold", display: "block" }}
                            mr="2" // Add margin-right for spacing
                          >
                            Job Classification:
                          </Text>
                        )}
                      </Box>
                      <Box width="40%">
                        {editMode ? (
                          <Input
                            size="sm"
                            type="text"
                            name="job_classification"
                            value={profileDetails.job_classification}
                            placeholder="Enter Your Job Classification"
                            onChange={handleChange}
                          />
                        ) : (
                          <Text fontSize="13px" style={{ display: "inline" }}>
                            {profileDetails.job_classification}
                          </Text>
                        )}
                      </Box>
                    </Flex>

                    <Flex align="center">
                      {(profileDetails.specialities || editMode) && (
                        <Text
                          fontSize="13px"
                          style={{ fontWeight: "bold", display: "inline" }}
                          mr="12" // Add margin-right for spacing
                        >
                          Specialties:
                        </Text>
                      )}

                      <Box width="40%">
                        {editMode ? (
                          <Input
                            size="sm"
                            type="text"
                            name="specialities"
                            value={profileDetails.specialities}
                            placeholder="Enter Your Specialities"
                            onChange={handleChange}
                          />
                        ) : (
                          <Text fontSize="13px" style={{ display: "inline" }}>
                            {profileDetails.specialities}
                          </Text>
                        )}
                      </Box>
                    </Flex>
                  </Box>

                  <Box mt="5" fontWeight="bold" fontSize="25px">
                    <Flex>
                      Credits
                      {UserProfileID && editMode && (
                        <Button
                          color="black"
                          onClick={() => {
                            onOpen();
                            setUpdatedCredit(false);
                            setSelectedCredit({});
                          }}
                          ml="4"
                        >
                          <AddIcon />
                        </Button>
                      )}
                    </Flex>
                  </Box>
                  {personID && (
                    <>
                      {creditDetailForPerson &&
                      creditDetailForPerson.length > 0 ? (
                        <>
                          {" "}
                          <Box
                            overflow="auto"
                            display="flex"
                            flexWrap="wrap"
                            ml="10"
                            mr="10px"
                            // maxWidth="80%"
                          >
                            {" "}
                            {creditDetailForPerson.map((credit) => (
                              <Box
                                key={credit.id}
                                w={{ base: "100%", sm: "xs", md: "sm" }}
                                borderWidth="1px"
                                borderColor="black"
                                borderRadius="2xl"
                                overflow="hidden"
                                p={{ base: "4", md: "6" }}
                                bg="white"
                                mt="4"
                                mr="15px"
                              >
                                <Flex justifyContent={"space-between"}>
                                  <Box>
                                    <Text
                                      fontSize={{ base: "md", md: "lg" }}
                                      fontWeight="semibold"
                                      marginRight="3"
                                      style={{ display: "inline" }}
                                    >
                                      {credit.production?.title},
                                    </Text>

                                    <Text style={{ display: "inline" }}>
                                      {credit.production?.title_type
                                        .charAt(0)
                                        .toUpperCase() +
                                        credit.production?.title_type.slice(1)}
                                    </Text>
                                  </Box>
                                  <Box>
                                    <Link
                                      to={credit.production?.link}
                                      target="_blank"
                                    >
                                      <LuExternalLink
                                        style={{ fontSize: "20px" }}
                                      />
                                    </Link>
                                  </Box>
                                </Flex>
                                <Box>
                                  <Text
                                    mt="2px"
                                    fontSize={{ base: "md", md: "lg" }}
                                    style={{ display: "inline" }}
                                  >
                                    {credit.start_year
                                      ? new Date(
                                          credit.start_year
                                        ).toDateString()
                                      : "No Start Date"}{" "}
                                    -
                                    {credit.end_year
                                      ? new Date(credit.end_year).toDateString()
                                      : "No End Date"}
                                  </Text>
                                </Box>
                                <Box
                                  display="flex"
                                  justifyContent="space-between"
                                  mt="2px"
                                >
                                  <Text
                                    style={{ display: "inline" }}
                                    fontSize={{ base: "md", md: "lg" }}
                                  >
                                    {credit.role?.title}
                                  </Text>
                                </Box>
                              </Box>
                            ))}
                          </Box>
                        </>
                      ) : (
                        <>
                          <Flex align="center" justify="center" mt="4">
                            <Text fontSize="lg" color="gray.500">
                              Do not have any Credits
                            </Text>
                          </Flex>
                        </>
                      )}
                    </>
                  )}

                  {UserProfileID && (
                    <>
                      {ceo.states.creditsDetails.length > 0 ? (
                        <>
                          {" "}
                          <Box
                            overflow="auto"
                            display="flex"
                            flexWrap="wrap"
                            ml="10"
                            mr="10px"
                            // maxWidth="80%"
                          >
                            {" "}
                            {ceo.states.creditsDetails.map((credit) => (
                              <Box
                                key={credit.id}
                                w={{ base: "100%", sm: "xs", md: "sm" }}
                                borderWidth="1px"
                                borderColor="black"
                                borderRadius="2xl"
                                overflow="hidden"
                                p={{ base: "4", md: "6" }}
                                bg="white"
                                mt="4"
                                mr="15px"
                              >
                                <Flex justifyContent={"space-between"}>
                                  <Box>
                                    <Text
                                      fontSize={{ base: "md", md: "lg" }}
                                      fontWeight="semibold"
                                      marginRight="3"
                                      style={{ display: "inline" }}
                                    >
                                      {credit.production?.title},
                                    </Text>

                                    <Text style={{ display: "inline" }}>
                                      {credit.production?.title_type
                                        .charAt(0)
                                        .toUpperCase() +
                                        credit.production?.title_type.slice(1)}
                                    </Text>
                                  </Box>
                                  <Box>
                                    {editMode ? (
                                      <Flex>
                                        <MdModeEditOutline
                                          style={{
                                            fontSize: "20px",
                                            cursor: "pointer",
                                          }}
                                          onClick={() => {
                                            setUpdatedCredit(true);
                                            setSelectedCredit(credit);
                                            // openUpdateCredit();
                                            onOpen();
                                          }}
                                        />
                                      </Flex>
                                    ) : (
                                      <>
                                        {credit.production?.link && (
                                          <Link
                                            to={credit.production?.link}
                                            target="_blank"
                                          >
                                            <LuExternalLink
                                              style={{ fontSize: "20px" }}
                                            />
                                          </Link>
                                        )}
                                      </>
                                    )}
                                  </Box>
                                </Flex>
                                <Box>
                                  <Text
                                    mt="2px"
                                    fontSize={{ base: "md", md: "lg" }}
                                    style={{ display: "inline" }}
                                  >
                                    {credit.start_year
                                      ? credit.start_year
                                      : "No Start Date"}{" "}
                                    -
                                    {credit.currently_working
                                      ? "Currently Working"
                                      : credit.end_year
                                      ? credit.end_year
                                      : "No End Date"}
                                    {/* {credit.end_year
                                      ? credit.end_year
                                      : "Currently Working "} */}
                                  </Text>
                                </Box>
                                <Box
                                  display="flex"
                                  justifyContent="space-between"
                                  mt="2px"
                                >
                                  <Text
                                    style={{ display: "inline" }}
                                    fontSize={{ base: "md", md: "lg" }}
                                  >
                                    {credit.role?.title}
                                  </Text>

                                  {UserProfileID && editMode && (
                                    <Text
                                      style={{ display: "inline" }}
                                      color="red"
                                      onClick={() => {
                                        setCreditDelete(credit.id);
                                        openDeleteConfirmation();
                                      }}
                                      _hover={{
                                        textDecoration: "underline",
                                        cursor: "pointer",
                                      }}
                                    >
                                      Delete
                                    </Text>
                                  )}
                                </Box>
                              </Box>
                            ))}
                          </Box>
                        </>
                      ) : (
                        <>
                          <Flex align="center" justify="center" mt="4">
                            <Text fontSize="lg" color="gray.500">
                              You do not have any Credits
                            </Text>
                          </Flex>
                        </>
                      )}
                    </>
                  )}
                </Box>
                {UserProfileID && (
                  <>
                    {" "}
                    <CreditsModal
                      isOpen={isOpen}
                      onClose={onClose}
                      selectedCredit={selectedCredit}
                      updatedCredit={updatedCredit}
                      setUpdatedCredit={setUpdatedCredit}
                      setSelectedCredit={setSelectedCredit}
                    />
                    <DeleteConfirmationPop
                      isOpen={isDeleteConfirmationOpen}
                      onClose={closeDeleteConfirmation}
                      onConfirm={handleDeleteCredit}
                    />{" "}
                  </>
                )}
              </>
            ) : (
              <>
                <Loader />
              </>
            )}
          </>
        ) : (
          <>
            <SearchResultTable searchResults={searchResults} />
          </>
        )}
      </Box>
    </>
  );
};

export default UserProfileComponent;
