
import { useApp } from "AppContext/AppProvider";
import SideBarForProducer from "./SideBarForProducer";
import SideBarForAdmin from "./SideBarForAdmin";
import SideBarForMember from "./SideBarForMember";
import SideBarForNone from "./SidebarForNone";

const SidebarComponent = ({ ...rest }) => {
  const ceo = useApp();
  const userProfile = ceo.states.userProfile;
  const checkRole = userProfile ? userProfile.role : null;

 

  return ceo.states.isAuthenticated === 0 ?  (
    ""
  ) : (
    <>
      {checkRole === null ? (
        <SideBarForAdmin />
      ) : (
        <>
          {checkRole === "producer" && <SideBarForProducer />}
          {checkRole === "admin" && <SideBarForAdmin />}
          {checkRole === "member" &&  <SideBarForMember />}
          {checkRole === "None" && <SideBarForNone />}
        </>
      )}
    </>
  );
};

export default SidebarComponent;
