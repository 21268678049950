import React, { useState, useEffect } from "react";
import {
  Box,
  Avatar,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  Flex,
  TableContainer,
  Tooltip,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import { IoMdAdd } from "react-icons/io";
import { FaCaretDown } from "react-icons/fa";
import { CheckIcon, AddIcon } from "@chakra-ui/icons";

import Loader from "components/table/loader";
import { IoPersonAddSharp } from "react-icons/io5";
import { IoAddOutline } from "react-icons/io5";
import Navbar from "components/navbar/NavbarAdmin";
import manager from "../../helpers/manager";
import { useApp } from "AppContext/AppProvider";
import { SearchResultTable } from "components/table/SearchResultsTable";
import { toast } from "react-hot-toast";
import { Link } from "react-router-dom";

const Unions = () => {
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(null);
  const [unionsDetails, setUnionsDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isRequested, setIsRequested] = useState({});
  const [isRequesting, setIsRequesting] = useState(false);
  // const [isMemberOf, setIsMemberOf] = useState(false);
  const ceo = useApp();

  const fetchUnionsListing = async () => {
    try {
      setIsLoading(true);
      const response = await manager.getUnions();

      if (!response) {
        throw new Error("Failed to fetch Unions information");
      } else {
        setUnionsDetails(response);

        // Initialize isRequested state based on invite_sent property of each union
        const initialRequestedState = {};
        response.forEach((union) => {
          initialRequestedState[union.id] = union.invite_sent;
        });
        setIsRequested(initialRequestedState);
      }
    } catch (error) {
      setError(error.message || "Error fetching unions details");
    } finally {
      setIsLoading(false);
    }
  };
  const requestAdminUnion = async (unionId) => {
    try {
      setIsRequesting(true);
      const response = await manager.requestUnionAdmin(unionId);
      if (response.ok) {
        const data = await response.json();
        if (data.message === "Invite requested") {
          setIsRequested((prevRequested) => ({
            ...prevRequested,
            [unionId]: true,
          }));

          toast.success("Request Initiated");
        } else if (data.error === "Invite already sent") {
          toast.error("Request Already Submitted");
        }
      } else {
        throw new Error("Failed to fetch Unions information");
      }
    } catch (error) {
      setError(error.message || "Error fetching unions details");
    } finally {
      setIsRequesting(false);
    }
  };

  useEffect(() => {
    fetchUnionsListing();
    ceo.actions.setSearchResults({ people: [], unions: [] });
    ceo.actions.setSearchQuery("");
  }, []);
  useEffect(() => {
    if (
      unionsDetails &&
      unionsDetails.is_member &&
      unionsDetails.is_member.length >= 1
    ) {
      const memberIds = unionsDetails.is_member.map((member) => member.id);
      ceo.actions.setIsMemberOf(memberIds);
    }
  }, [unionsDetails]);

  const hasSearchResults =
    ceo.states.searchResults.people.length > 0 ||
    ceo.states.searchResults.unions.length > 0;

  const hasSearchQuery = ceo.states.searchQuery === "";

  const userProfile = ceo.states.userProfile;
  const checkRole = userProfile ? userProfile.role : null;
  // console.log("Checking unions details:", unionsDetails);

  const tableHeading = (
    <Thead>
      <Tr>
        <Th
          borderBottom="1px"
          borderColor="gray.300"
          textAlign="center"
          minWidth="150px"
        >
          Profile Photo
        </Th>
        <Th
          borderBottom="1px"
          borderColor="gray.300"
          textAlign="center"
          minWidth="150px"
        >
          Name
        </Th>
        <Th
          borderBottom="1px"
          borderColor="gray.300"
          textAlign="center"
          minWidth="150px"
        >
          Description
        </Th>
        <Th
          borderBottom="1px"
          borderColor="gray.300"
          textAlign="center"
          minWidth="150px"
        >
          Actions
        </Th>
      </Tr>
    </Thead>
  );

  return (
    <>
      <Navbar />

      <Box ml={{ base: "90px", md: "250px" }} marginRight="1%" mt="20">
        {!isLoading ? (
          <>
            {!hasSearchResults && hasSearchQuery ? (
              <>
                {unionsDetails && unionsDetails.length === 0 ? (
                  <Flex align="center" justify="center" mt="4">
                    <Text fontSize="lg" color="gray.500">
                      No Unions Found.
                    </Text>
                  </Flex>
                ) : (
                  <Box overflowX="auto">
                    {((checkRole === "admin" || checkRole === "member") &&
                      unionsDetails && (
                        <Table
                          variant="simple"
                          size="sm"
                          borderWidth="1px"
                          borderRadius="md"
                          mb="4"
                          borderBottom="1px"
                          borderColor="gray.300"
                          mt="5"
                        >
                          {tableHeading}
                          <Tbody>
                            {unionsDetails.map((union, index) => (
                              <Tr key={union.id}>
                                <Td
                                  borderBottom="1px"
                                  borderColor="gray.300"
                                  textAlign="center"
                                >
                                  <Link to={`/union-profile/${union.id}`}>
                                  <Avatar src={union.profile_photo} />
                                  </Link>
                                </Td>
                                <Td
                                  borderBottom="1px"
                                  borderColor="gray.300"
                                  textAlign="center"
                                >
                                  <Link
                                    to={`/union-profile/${union.id}`}
                                    style={{
                                      // textDecoration: "underline",
                                      color: "blue",
                                      cursor: "pointer",
                                    }}
                                  >
                                    {union.name}
                                  </Link>
                                </Td>
                                <Td
                                  borderBottom="1px"
                                  borderColor="gray.300"
                                  textAlign="center"
                                >
                                  <Link to={`/union-profile/${union.id}`}>
                                    {union.description
                                      ? union.description
                                      : "N/A"}
                                  </Link>
                                </Td>
                                <Td
                                  borderBottom="1px"
                                  borderColor="gray.300"
                                  textAlign="center"
                                >
                                  {"-"}
                                </Td>
                              </Tr>
                            ))}
                          </Tbody>
                        </Table>
                      )) ||
                      null}
                    {checkRole === "producer" && (
                      <Box overflowX="auto" mt="4">
                        {unionsDetails.is_member &&
                        unionsDetails.is_member.length === 0 ? (
                          <Flex align="center" justify="center" mt="4">
                            <Text fontSize="lg" color="gray.500">
                              Not a member of any Union.
                            </Text>
                          </Flex>
                        ) : (
                          <TableContainer>
                            <Text as="b">MY UNIONS</Text>
                            <Table
                              variant="simple"
                              size="sm"
                              borderWidth="1px"
                              borderRadius="md"
                              mb="10"
                              borderBottom="1px"
                              borderColor="gray.300"
                            >
                              {tableHeading}
                              <Tbody>
                                {unionsDetails &&
                                  unionsDetails.is_member.map(
                                    (union, index) => (
                                      <Tr key={union.id}>
                                        <Td
                                          borderBottom="1px"
                                          borderColor="gray.300"
                                          textAlign="center"
                                          // wordWrap="break-word"
                                          minWidth="150px"
                                        >
                                          <Link
                                            to={`/union-profile/${union.id}`}
                                          >
                                            <Avatar src={union.profile_photo} />
                                          </Link>
                                        </Td>
                                        <Td
                                          borderBottom="1px"
                                          borderColor="gray.300"
                                          textAlign="center"
                                          // wordWrap="break-word"
                                          minWidth="150px"
                                        >
                                          <Link
                                            to={`/union-profile/${union.id}`}
                                            style={{
                                              // textDecoration: "underline",
                                              color: "blue",
                                              cursor: "pointer",
                                            }}
                                          >
                                            {union.name}
                                          </Link>
                                        </Td>
                                        <Td
                                          borderBottom="1px"
                                          borderColor="gray.300"
                                          textAlign="center"
                                          // wordWrap="break-word"
                                          minWidth="150px"
                                        >
                                          <Link
                                            to={`/union-profile/${union.id}`}
                                          >
                                            {union.description}
                                          </Link>
                                        </Td>
                                        <Td
                                          borderBottom="1px"
                                          borderColor="gray.300"
                                          textAlign="center"
                                          // wordWrap="break-word"
                                          minWidth="150px"
                                        >
                                          {"-"}
                                        </Td>
                                      </Tr>
                                    )
                                  )}
                              </Tbody>
                            </Table>
                          </TableContainer>
                        )}
                        {unionsDetails.other &&
                        unionsDetails.other.length === 0 ? (
                          <Flex align="center" justify="center" mt="4">
                            <Text fontSize="lg" color="gray.500">
                              No Other Unions Found.
                            </Text>
                          </Flex>
                        ) : (
                          <TableContainer>
                            <Text as="b">ALL UNIONS</Text>
                            <Table
                              variant="simple"
                              size="sm"
                              borderWidth="1px"
                              borderRadius="md"
                              borderBottom="1px"
                              borderColor="gray.300"
                            >
                              {tableHeading}
                              <Tbody>
                                {unionsDetails &&
                                  unionsDetails.other.map((union, index) => (
                                    <Tr key={union.id}>
                                      <Td
                                        borderBottom="1px"
                                        borderColor="gray.300"
                                        textAlign="center"
                                      >
                                        <Link to={`/union-profile/${union.id}`}>
                                          <Avatar src={union.profilePhoto} />
                                        </Link>
                                      </Td>
                                      <Td
                                        borderBottom="1px"
                                        borderColor="gray.300"
                                        textAlign="center"
                                        minWidth="150px"
                                      >
                                        <Link
                                          to={`/union-profile/${union.id}`}
                                          style={{
                                            // textDecoration: "underline",
                                            color: "blue",
                                            cursor: "pointer",
                                          }}
                                        >
                                          {union.name}
                                        </Link>
                                      </Td>
                                      <Td
                                        borderBottom="1px"
                                        borderColor="gray.300"
                                        textAlign="center"
                                        minWidth="150px"
                                      >
                                        <Link to={`/union-profile/${union.id}`}>
                                          {union.description}
                                        </Link>
                                      </Td>
                                      <Td
                                        borderBottom="1px"
                                        borderColor="gray.300"
                                        textAlign="center"
                                        minWidth="50px"
                                        maxWidth="100px"
                                      >
                                        {isRequested[union.id] ||
                                        union.invite_sent ? (
                                          <Tooltip
                                            label={"Request already sent"}
                                          >
                                            <CheckIcon />
                                          </Tooltip>
                                        ) : (
                                          <>
                                            <Menu>
                                              <MenuButton
                                                as={Button}
                                                size="sm"
                                                style={{ border: "1px solid " }}
                                              >
                                                <Flex align="center">
                                                  Actions <FaCaretDown />
                                                </Flex>
                                              </MenuButton>
                                              <MenuList>
                                                <MenuItem
                                                  onClick={() =>
                                                    requestAdminUnion(union.id)
                                                  }
                                                  isDisabled={
                                                    isRequested[union.id] ||
                                                    union.invite_sent ||
                                                    isRequesting
                                                  }
                                                >
                                                  {/* <Tooltip
                                                    label={
                                                      "Request to join union"
                                                    }
                                                  > */}
                                                  <Flex align="center">
                                                    <Button
                                                      color="white"
                                                      bgColor="teal.500"
                                                      variant="outline"
                                                      _hover={{
                                                        bgColor: "teal.600",
                                                      }}
                                                      size="sm"
                                                      borderRadius="xl"
                                                    >
                                                      <AddIcon />
                                                    </Button>
                                                    <Text
                                                      ml="2"
                                                      textAlign="center"
                                                      fontSize="md"
                                                    >
                                                      Request
                                                    </Text>
                                                  </Flex>
                                                  {/* </Tooltip> */}
                                                </MenuItem>
                                              </MenuList>
                                            </Menu>
                                          </>
                                        )}
                                      </Td>
                                    </Tr>
                                  ))}
                              </Tbody>
                            </Table>
                          </TableContainer>
                        )}
                      </Box>
                    )}
                  </Box>
                )}
              </>
            ) : (
              <>
                <Box overflowX="auto">
                  {/* Search Results Table */}
                  <SearchResultTable searchResults={ceo.states.searchResults} />
                </Box>
              </>
            )}
          </>
        ) : (
          <Loader />
        )}
      </Box>
    </>
  );
};

export default Unions;
