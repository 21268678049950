import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  useColorModeValue,
} from "@chakra-ui/react";
import { SearchIcon, SmallCloseIcon } from "@chakra-ui/icons";
import manager from "helpers/manager";
import { useApp } from "AppContext/AppProvider";

export function SearchBar({ ...props }) {
  const ceo = useApp();
  const { background, placeholder, borderRadius, ...rest } = props;
  const searchIconColor = useColorModeValue("gray.700", "gray.700");
  const inputBg = useColorModeValue("secondaryGray.300", "rgb(77, 146, 154)");
  const inputText = useColorModeValue("gray.700", "gray.100");

  const [valueChange, setValueChange] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const userProfile = ceo.states.userProfile;
  const checkRole = userProfile ? userProfile.role : null;

  const executeSearch = () => {
    if (checkRole === "None") {
      return null;
    }
    setIsLoading(true);

    manager.combinedSearch({ searchQuery: valueChange }).then(response => {
      if (response.ok) {
        response.json().then(data => {
          // Update global context with search results
          ceo.actions.setSearchResults(data);
          setIsLoading(false);
        });
      } else {
        console.error('Search failed', response);
        setIsLoading(false);
      }
    });
  };

  const handleSearchKeyPress = (event) => {
    if (event.key === 'Enter') {
      executeSearch();
      ceo.actions.setSearchQuery(valueChange);

    }
  };

  const handleClearSearch = () => {
    ceo.actions.setSearchQuery("");
    setValueChange("");
  };

  return (
    <InputGroup w={{ base: "40%", md: "30%" }} {...rest}>
      <InputLeftElement>
        <IconButton
          aria-label="Search database"
          bg='inherit'
          _hover={{ bg: 'inherit' }}
          _active={{
            bg: "inherit",
            transform: "none",
            borderColor: "transparent",
          }}
          _focus={{ boxShadow: "none" }}
          icon={<SearchIcon color={searchIconColor} />}
          onClick={executeSearch}
          isLoading={isLoading}
        />
      </InputLeftElement>
      <Input
        variant='search'
        fontSize='sm'
        bg={background || inputBg}
        color={inputText}
        fontWeight='500'
        _placeholder={{ color: "gray.400", fontSize: "14px" }}
        borderRadius={borderRadius || "10px"}
        placeholder={placeholder || "Search"}
        value={valueChange}
        onKeyDown={handleSearchKeyPress}
        onChange={(e) => setValueChange(e.target.value)}
      />
      {ceo.states.searchQuery && (
        <InputRightElement>
          <IconButton
            aria-label="Clear search"
            bg="inherit"
            _hover={{ bg: "inherit" }}
            _active={{
              bg: "inherit",
              transform: "none",
              borderColor: "transparent",
            }}
            _focus={{ boxShadow: "none" }}
            icon={<SmallCloseIcon />}
            onClick={handleClearSearch}
          />
        </InputRightElement>
      )}
    </InputGroup>
  );
}

SearchBar.propTypes = {
  // isActive: PropTypes.bool.isRequired,
  background: PropTypes.string,
  placeholder: PropTypes.string,
  borderRadius: PropTypes.string,
};
