import React, { useState, useEffect } from "react";
import Autosuggest from "react-autosuggest";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
} from "@chakra-ui/react";
import PropTypes from "prop-types";
import Loader from "components/table/loader";
import manager from "helpers/manager";
import { useApp } from "AppContext/AppProvider";
import autosuggestStyles from "./AutosuggestStyles/AutosuggestStyles"; // Import AutosuggestStyles
import toast from "react-hot-toast";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
function CreditsModal({
  isOpen,
  onClose,
  selectedCredit,
  updatedCredit,
  setUpdatedCredit,
  setSelectedCredit,
}) {
  const ceo = useApp();

  const [isLoading, setIsLoading] = useState(false);
  const [suggestionsProduction, setSuggestionsProduction] = useState([]);
  const [suggestionsRole, setSuggestionsRole] = useState([]);
  const [productionTitle, setProductionTitle] = useState("");
  const [productionStartDate, setProductionStartDate] = useState("");
  const [productionEndDate, setProductionEndDate] = useState("");
  const [productionRole, setProductionRole] = useState("");
  const [createNewProd, setCreateNewProd] = useState(false);
  const [createNewRole, setCreateNewRole] = useState(false);
  const [productionLink, setProductionLink] = useState("");
  const [productionTitleType, setProductionTitleType] = useState("");
  const [enteredProduction, setEnteredProduction] = useState(""); // State to hold entered production details
  const [sendingTrueNewProd, setSendingTrueNewProd] = useState(false);
  const [sendingTrueNewRole, setSendingTrueNewRole] = useState(false);
  const [productionID, setProductionID] = useState();
  const [roleID, setRoleID] = useState();
  const [checkProductionFields, setCheckProductionFields] = useState(false);
  const [checkCreditFields, setCheckCreditField] = useState(false);
  const [creditId, setCreditId] = useState(null);
  const [productionTimer, setProductionTimer] = useState(null); // Timer for production search
  const [roleTimer, setRoleTimer] = useState(null); // Timer for production search
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [currentlyWorking, setCurrentlyWorking] = useState(false);

  // useEffect(() => {
  //   const timeout = setTimeout(() => {
  //     setIsLoading(false);
  //   }, 2000);

  //   return () => clearTimeout(timeout);
  // }, []);

  useEffect(() => {
    checkProductionFieldsFilled();
  }, [
    productionEndDate,
    productionLink,
    productionStartDate,
    productionTitle,
    productionTitleType,
    productionRole,
    roleID,
    enteredProduction,
    
  ]);

  useEffect(() => {
    checkCreditFieldsFilled();
  }, [productionID, roleID, enteredProduction, productionRole, startDate]);

  const checkCreditFieldsFilled = () => {
    if (
      (productionID && roleID && startDate) ||
      (enteredProduction && productionRole && startDate) ||
      (enteredProduction && roleID && startDate) ||
      (productionID && productionRole && startDate)
    ) {
      setCheckCreditField(true);
    } else {
      setCheckCreditField(false);
    }
  };

  const checkProductionFieldsFilled = () => {
    if (
      (productionTitle && productionTitleType && productionRole) ||
      (productionRole && enteredProduction) ||
      // productionEndDate &&
      (productionLink) ||
      // productionStartDate &&
      (productionTitle && productionTitleType && roleID)
    ) {
      setCheckProductionFields(true);
    } else {
      setCheckProductionFields(false);
    }
  };

  useEffect(() => {
    if (updatedCredit === false) {
      setEnteredProduction("");
      setProductionRole("");
    }
    if (selectedCredit.id) {
      settingStates();
      setCreditId(selectedCredit.id);
    }
  }, [selectedCredit, updatedCredit]);

  const settingStates = () => {
    setProductionTitle(selectedCredit.production.title || "");
    setProductionEndDate(
      selectedCredit.production.end_year
        ? new Date(selectedCredit.production.end_year,0,1)
        : ""
    );
    setProductionLink(
      selectedCredit.link ? selectedCredit.production.link : ""
    );
    setProductionStartDate(
      selectedCredit.production.start_year
        ? new Date(selectedCredit.production.start_year, 0, 1)
        : ""
    );
    setProductionTitleType(selectedCredit.production.title_type || "");
    setProductionRole(selectedCredit.role.title || "");
    setStartDate(
      selectedCredit.start_year
        ? new Date(selectedCredit.start_year, 0, 1)
        : null
    );

    if (
      selectedCredit.end_year !== null &&
      !selectedCredit.currently_working 
    ) {
      setEndDate(
        selectedCredit.end_year ? new Date(selectedCredit.end_year, 0, 1) : null
      );
    }

    setCurrentlyWorking(selectedCredit.currently_working);
  };

  let formatProduction;
  
  // Extract only the date part
  if (selectedCredit && updatedCredit) {
    formatProduction = `${productionTitle} (${productionTitleType})`;
    if (productionStartDate && productionEndDate) {
      formatProduction += ` ${productionStartDate?.getFullYear()} - ${productionEndDate?.getFullYear()}`;
    } else if (productionStartDate) {
      formatProduction += ` ${productionStartDate?.getFullYear()}`;
    } else if (productionEndDate) {
      formatProduction += ` ${productionEndDate?.getFullYear()}`;
    }
  }
  useEffect(() => {
    if (
      selectedCredit &&
      selectedCredit.id &&
      formatProduction &&
      updatedCredit
    ) {
      setEnteredProduction(formatProduction);
    }
  }, [selectedCredit, formatProduction, updatedCredit]);

  const handleClose = () => {
    formatProduction = "";
    setSelectedCredit({});
    setProductionID(null);
    setRoleID(null);
    setSendingTrueNewProd(false);
    setSendingTrueNewRole(false);
    setProductionEndDate("");
    setProductionStartDate("");
    setProductionLink("");
    setProductionRole("");
    setEnteredProduction("");
    setStartDate(null);
    setEndDate(null);
    setProductionTitle("");
    setProductionTitleType("");
    setCreateNewProd(false);
    setCreateNewRole(false);
    setCurrentlyWorking(false);
    onClose();
  };

  const onSuggestionsFetchRequestedProduction = ({ value }) => {
    clearTimeout(productionTimer); // Clear previous timer
    setProductionTimer(
      setTimeout(async () => {
        const inputValue = value.trim().toLowerCase();
        const searchProductions = await manager.searchProductions(inputValue);
        const data = await searchProductions.json();

        const formattedProductions = data.map((production) => {
          const { id, title, title_type, start_year, end_year } = production;
          
          let formattedStartDate = start_year ?  (new Date(start_year,0,1))?.getFullYear() : null;
          let formattedEndDate = end_year ? (new Date(end_year,0,1))?.getFullYear() : null;

          let addFormatProduction = `${title} (${title_type})`;

          if (formattedStartDate && formattedEndDate) {
            addFormatProduction += ` ${formattedStartDate} - ${formattedEndDate}`;
          } else if (formattedStartDate) {
            addFormatProduction += ` ${formattedStartDate}`;
          } else if (formattedEndDate) {
            addFormatProduction += ` ${formattedEndDate}`;
          }

          return {
            id,
            text: addFormatProduction,
          };
        });

        setSuggestionsProduction([
          ...formattedProductions,
          { id: null, text: "+ Something missing? Add New Production" },
        ]);
      }, 1000)
    );
  };

  const onSuggestionsClearRequestedProduction = () => {
    setSuggestionsProduction([]);
  };

  const onChangeForProduction = (_, { newValue }) => {
    setEnteredProduction(newValue);
    if (newValue.text === "+ Something missing? Add New Production") {
      setCreateNewProd(true);
      setSendingTrueNewProd(true);
    } else {
      setCreateNewProd(false);
    }
  };

  const onSuggestionSelectedProduction = (_, { suggestion }) => {
    if (suggestion.text === "+ Something missing? Add New Production") {
      setCreateNewProd(true);
      setSendingTrueNewProd(true);
    } else {
      setCreateNewProd(false);
      setProductionID(suggestion.id);
    }
  };

  const onSuggestionsFetchRequestedRole = async ({ value }) => {
    clearTimeout(roleTimer);
    setRoleTimer(
      setTimeout(async () => {
        const inputValue = value.trim().toLowerCase();

        const searchRole = await manager.searchRole(inputValue);
        const data = await searchRole.json();

        const formattedRoles = data
          // .filter((role) => role.title.toLowerCase().includes(inputValue))
          .map((role) => {
            const { id, title } = role;
            return {
              id,
              text: `${title}`,
            };
          });

        setSuggestionsRole([
          ...formattedRoles,
          { id: null, text: "+ Something missing? Add New Role" },
        ]);
      }, 1000)
    );
  };

  const onSuggestionsClearRequestedRole = () => {
    setSuggestionsRole([]);
  };

  const onChangeForRole = (_, { newValue }) => {
    setProductionRole(newValue);
    if (newValue.text === "+ Something missing? Add New Role") {
      setCreateNewRole(true);
      setSendingTrueNewRole(true);
    } else {
      setCreateNewRole(false);
    }
  };

  const onSuggestionSelectedRole = (_, { suggestion }) => {
    if (suggestion.text === "+ Something missing? Add New Role") {
      setCreateNewRole(true);
      setSendingTrueNewRole(true);
    } else {
      setCreateNewRole(false);

      setRoleID(suggestion.id);
    }
  };

  const inputPropsForProduction = {
    placeholder: "Enter or select a production title",
    value: enteredProduction,
    onChange: onChangeForProduction,
  };

  const inputPropsForRole = {
    placeholder: "Enter or select a role title",
    value: productionRole,
    onChange: onChangeForRole,
  };

  const handleProducitonAdd = () => {
    if (!productionID) {
      const formattedStartDate = productionStartDate
        ? productionStartDate.getFullYear()
        : null;
      const formattedEndDate = productionEndDate
        ? productionEndDate.getFullYear()
        : null;
      let formatProduction = `${productionTitle} (${productionTitleType})`;

      if (formattedStartDate && formattedEndDate) {
        formatProduction += ` ${formattedStartDate} - ${formattedEndDate}`;
      } else if (formattedStartDate) {
        formatProduction += ` ${formattedStartDate}`;
      } else if (formattedEndDate) {
        formatProduction += ` ${formattedEndDate}`;
      }

      setEnteredProduction(formatProduction);
    }

    setCreateNewProd(false);
    setCreateNewRole(false);
  };

  const handleCredits = async () => {

    // Extract years from startDateObj and endDateObj
    const credit_start_year = startDate?.getFullYear();
    const credit_end_year = endDate?.getFullYear();
  

    const prod_start_year = productionStartDate ? productionStartDate.getFullYear(): "";
    const prod_end_year = productionEndDate ? productionEndDate.getFullYear(): "";
    setIsLoading(true)
    try {
      const response = await manager.createCredits(
        productionID,
        roleID,
        sendingTrueNewProd,
        sendingTrueNewRole,
        productionTitle,
        prod_start_year,
        prod_end_year,                                     
        productionRole,
        productionLink,
        productionTitleType,
        credit_start_year,
        credit_end_year,
        currentlyWorking
      );
      if (response.ok) {
        const data = await response.json();

        ceo.actions.setCreditsDetails([...ceo.states.creditsDetails, data]);
        handleClose();
        onClose();
        toast.success("Credits added successfully");
      } else {
        toast.error("Error Credit Not Added");
      }
    } catch (error) {
      console.log("Error in adding the Credits : ", error);
    }finally{
      setIsLoading(false)
    }
  };

  const handleCreditUpdate = async () => {
    try {
      setIsLoading(true)
      const startDateObj = new Date(startDate);
      let credit_end_year;
      if (endDate) {
        const endDateObj = new Date(endDate);
        credit_end_year = endDateObj.getFullYear();
      }
      const credit_start_year = startDateObj.getFullYear();
      if(currentlyWorking){
        credit_end_year= "";
      }
     
      const prod_start_year = productionStartDate ? productionStartDate.getFullYear(): "";
      const prod_end_year = productionEndDate ? productionEndDate.getFullYear(): "";
      const response = await manager.UpdateCredits(
        creditId,
        productionID,
        roleID,
        sendingTrueNewProd,
        sendingTrueNewRole,
        productionTitle,
        prod_start_year,
        prod_end_year,
        productionRole,
        productionLink,
        productionTitleType,
        credit_start_year,
        credit_end_year,
        currentlyWorking
      );
      if (response.ok) {
        const data = await response.json();
        const index = ceo.states.creditsDetails.findIndex(
          (credit) => credit.id === creditId
        );

        const updatedCreditsArray = [...ceo.states.creditsDetails];
        updatedCreditsArray[index] = data;
        ceo.actions.setCreditsDetails(updatedCreditsArray);

        handleClose();
        onClose();
        toast.success("Credit Updated Successfully");
      } else {
        toast.error("Credit is not updated");
      }
    } catch (error) {
      console.log("Error in updating the Credits : ", error);
    }
    finally{
      setIsLoading(false)
    }
  };
  const handleCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setCurrentlyWorking(true);
    } else {
      setCurrentlyWorking(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        {isLoading ? (
          <ModalOverlay>
            <Loader />
          </ModalOverlay>
        ) : null}
        <ModalHeader>{updatedCredit ? "Update Credit":"Add Credit"} </ModalHeader>

        <ModalCloseButton onClick={handleClose} />
        <ModalBody pb={6}>
          {!createNewProd && (
            <FormControl mt={4} isRequired>
              <FormLabel>Production Title</FormLabel>
              <Autosuggest
                suggestions={suggestionsProduction}
                onSuggestionsFetchRequested={
                  onSuggestionsFetchRequestedProduction
                }
                onSuggestionsClearRequested={
                  onSuggestionsClearRequestedProduction
                }
                getSuggestionValue={(suggestionProduction) =>
                  suggestionProduction.text
                }
                renderSuggestion={(suggestionProduction) =>
                  suggestionProduction.text
                }
                inputProps={inputPropsForProduction}
                onSuggestionSelected={onSuggestionSelectedProduction}
                theme={autosuggestStyles}
              />
            </FormControl>
          )}

          {createNewProd && (
            <>
              <FormControl isRequired>
                <FormLabel>Production Title</FormLabel>
                <Input
                  type="text"
                  placeholder="Enter Production Title"
                  value={productionTitle}
                  onChange={(e) => setProductionTitle(e.target.value)}
                />
              </FormControl>

              <FormControl mt={4} isRequired>
                <FormLabel>Production Type</FormLabel>
                <Input
                  type="text"
                  placeholder="Enter Production Type"
                  value={productionTitleType}
                  onChange={(e) => setProductionTitleType(e.target.value)}
                />
              </FormControl>

              <FormControl mt={4}>
                <FormLabel>Production Start Date</FormLabel>
                <DatePicker
                    selected={productionStartDate}
                    
                    onChange={(date) => setProductionStartDate(date)}
                    dateFormat="yyyy"
                    showYearPicker
                    className="custom-date-picker"
                    placeholderText="Select Start Year"

                  />
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>Production End Date</FormLabel>
                <DatePicker
                    selected={productionEndDate}
                    // value={productionEndDate}
                    onChange={(date) => setProductionEndDate(date)}
                    dateFormat="yyyy"
                    showYearPicker
                    className="custom-date-picker"
                    minDate={productionStartDate}
                    placeholderText="Select End Year"

                  />
                
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>IMDB Link</FormLabel>
                <Input
                  placeholder="Enter the IMDB Link"
                  type="text"
                  value={productionLink}
                  onChange={(e) => setProductionLink(e.target.value)}
                />
              </FormControl>
            </>
          )}
          {!createNewRole && (
            <FormControl mt={4} isRequired>
              <FormLabel> Your Role</FormLabel>
              <Autosuggest
                suggestions={suggestionsRole}
                onSuggestionsFetchRequested={onSuggestionsFetchRequestedRole}
                onSuggestionsClearRequested={onSuggestionsClearRequestedRole}
                getSuggestionValue={(suggestionRole) => suggestionRole.text}
                renderSuggestion={(suggestionRole) => suggestionRole.text}
                inputProps={inputPropsForRole}
                onSuggestionSelected={onSuggestionSelectedRole}
                theme={autosuggestStyles} // Use the autosuggestStyles here
              />
            </FormControl>
          )}

          {createNewRole && (
            <FormControl mt={4} isRequired>
              <FormLabel>Your Role</FormLabel>
              <Input
                type="text"
                placeholder="Enter Your Role"
                // value={productionRole}
                onChange={(e) => setProductionRole(e.target.value)}
              />
            </FormControl>
          )}

          {!createNewRole && !createNewProd && (
            <>
              <FormControl mt={4} isRequired>
                <FormLabel> Start Year</FormLabel>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  dateFormat="yyyy"
                  // value={startDate}
                  showYearPicker
                  // scrollableYearDropdown
                  // yearDropdownItemNumber={8}
                  className="custom-date-picker"
                  placeholderText="Select Start Year"
                  // dropdownMode="select"
                />
              </FormControl>
              <FormControl mt={4} isRequired>
                <FormLabel>
                  I am currently working on this Project
                  <Checkbox
                    ml="2"
                    mt="1"
                    onChange={handleCheckboxChange}
                    defaultChecked={currentlyWorking}
                  />
                </FormLabel>
              </FormControl>

              {!currentlyWorking && (
                <FormControl mt={4}>
                  <FormLabel> End Year</FormLabel>
                  <DatePicker
                    selected={endDate}
                    placeholderText="Select End Year"
                    onChange={(date) => setEndDate(date)}
                    dateFormat="yyyy"
                    showYearPicker
                    className="custom-date-picker"
                    minDate={startDate}
                  />
                </FormControl>
              )}
            </>
          )}
        </ModalBody>
        <ModalFooter>
          {createNewProd || createNewRole ? (
            <>
              {updatedCredit ? (
                <>
                  {" "}
                  <Button
                    colorScheme="teal"
                    mr={3}
                    disabled={!checkProductionFields || isLoading}
                    onClick={handleProducitonAdd}
                  >
                    {createNewProd && !createNewRole && "Update Production"}
                    {createNewRole && !createNewProd && "Update Role"}
                    {(createNewProd && createNewRole) && "Update Production & Role "}

                    
                  </Button>
                </>
              ) : (
                <>
                  {" "}
                  <Button
                    colorScheme="teal"
                    mr={3}
                    disabled={!checkProductionFields || isLoading}
                    onClick={handleProducitonAdd}
                  >
                    {createNewProd && !createNewRole && "Add Production"}
                    {createNewRole && !createNewProd && "Add Role"}
                    {(createNewProd && createNewRole) && "Add Production & Role "}
                   
                  </Button>
                </>
              )}
            </>
          ) : (
            <>
              {updatedCredit ? (
                <>
                  {" "}
                  <Button
                    colorScheme="teal"
                    mr={3}
                    disabled={!checkCreditFields || isLoading}
                    onClick={handleCreditUpdate}
                  >
                    Update Credit
                  </Button>
                </>
              ) : (
                <>
                  {" "}
                  <Button
                    colorScheme="teal"
                    mr={3}
                    disabled={!checkCreditFields || isLoading}
                    onClick={handleCredits}
                  >
                    Add Credit
                  </Button>
                </>
              )}
            </>
          )}

          <Button variant="ghost" onClick={handleClose} disabled={isLoading}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

CreditsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  selectedCredit: PropTypes.object,
  updatedCredit: PropTypes.bool,
  setUpdatedCredit: PropTypes.func,
  setSelectedCredit: PropTypes.func,
};

export default CreditsModal;
