import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  Spinner, // Import Spinner component from Chakra UI for the loading indicator
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import manager from '../../helpers/manager'; // Adjust the import path as necessary
import Loader from 'components/table/loader';

// Define the membership type options
const MEMBERSHIP_TYPE_OPTIONS = [
  { value: 1, label: 'admin' },
  { value: 2, label: 'member' },
  { value: 3, label: 'producer' },
];

function InviteRequestModal({ isOpen, onClose, onSubmit }) {
  const [email, setEmail] = useState('');
  const [description, setDescription] = useState('');
  const [link, setLink] = useState('');
  const [unions, setUnions] = useState([]);
  const [selectedUnionId, setSelectedUnionId] = useState('');
  const [selectedMembershipType, setSelectedMembershipType] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [isLoading, setIsLoading] = useState(false); // New state for loading indicator

  // Fetch unions when modal opens
  useEffect(() => {
    const fetchUnions = async () => {
      const response = await manager.getUnions();
      if (response) {
        setUnions(response)

      } else {
        // Handle error fetching unions
        console.error("Failed to fetch unions");
      }
    };

    if (isOpen) {
      fetchUnions();
    }
  }, [isOpen]);

  const handleSubmit = async () => {
    if (!email || !selectedUnionId || !selectedMembershipType) {
      alert("Please enter all required fields.");
      return;
    }

    setIsLoading(true); // Start loading

    // Simulate a delay of 2 seconds before submitting
    setTimeout(() => {
      setIsLoading(false); // Stop loading after 2 seconds
      // console.log("Submitting with membership type:", selectedMembershipType);
      // Include selectedUnionId and selectedMembershipType in the submission payload
      onSubmit({
        email,
        description,
        link,
        unionId: selectedUnionId,
        membershipType: selectedMembershipType,
        firstName,
        lastName,
      });

      // Reset the form fields and selections after submitting
      setEmail('');
      setDescription('');
      setLink('');
      setSelectedUnionId('');
      setSelectedMembershipType('');
      setFirstName('');
      setLastName('');
    }, 2000);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />

      <ModalContent>
        {isLoading ? (
          <ModalOverlay >
            <Loader />
          </ModalOverlay >
        ) : null}
        <ModalHeader>Send Invite</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <FormControl isRequired>
            <FormLabel>Email</FormLabel>
            <Input
              placeholder="example@mail.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>First Name</FormLabel>
            <Input
              placeholder="First Name (Optional)"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>Last Name</FormLabel>
            <Input
              placeholder="Last Name (Optional)"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </FormControl>
          <FormControl mt={4} isRequired>
            <FormLabel>Union</FormLabel>
            <Select
              placeholder="Select a union"
              value={selectedUnionId}
              onChange={(e) => setSelectedUnionId(e.target.value)}
            >
              {unions.map((union) => (
                <option key={union.id} value={union.id}>{union.name}</option>
              ))}
            </Select>
          </FormControl>
          <FormControl mt={4}>
            <FormControl mt={4} isRequired>
              <FormLabel>Membership Type</FormLabel>
              <Select
                placeholder="Select membership type"
                value={selectedMembershipType}
                onChange={(e) => setSelectedMembershipType(e.target.value)}
              >
                {MEMBERSHIP_TYPE_OPTIONS.map((type) => (
                  <option key={type.value} value={type.value}>{type.label}</option>
                ))}
              </Select>
            </FormControl>
            <FormLabel>Description</FormLabel>
            <Input
              placeholder="Description (Optional)"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </FormControl>

          <FormControl mt={4}>
            <FormLabel>Link</FormLabel>
            <Input
              placeholder="Link (Optional)"
              value={link}
              onChange={(e) => setLink(e.target.value)}
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="teal" mr={3} onClick={handleSubmit} disabled={isLoading}>
            Send
          </Button>
          <Button variant="ghost" onClick={onClose} disabled={isLoading}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

InviteRequestModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default InviteRequestModal;
