// Chakra Imports
import {
  Flex,
  Menu,
  MenuButton,
  Text,
  // useColorModeValue,
  // useColorMode,
  VStack,
} from "@chakra-ui/react";
import { TbLogout } from "react-icons/tb";


// Custom Components

import PropTypes from "prop-types";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useApp } from "AppContext/AppProvider";
// Assets
export default function HeaderLinks({ open, setSelected }) {

  // const { secondary } = props;
  // Chakra Color Mode
  // const navbarIcon = useColorModeValue("gray.400", "white");
  // let menuBg = useColorModeValue("white", "#151418");
  // let hoverBg = useColorModeValue("#f2f2f2", "#0d0d0d");
  // const textColor = useColorModeValue("secondaryGray.900", "white");
  // const borderColor = useColorModeValue("#E6ECFA", "rgba(135, 140, 189, 0.3)");
  // const shadow = useColorModeValue(
  //   "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
  //   "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
  // );
  // const { colorMode, toggleColorMode } = useColorMode();
  const ceo = useApp();
  const navigate = useNavigate();
  const handleClick = () => {
    setSelected("Home"); // Set selected state to "Home"
    ceo.actions.emptyAllStates();
    navigate("/auth");
  };

  return (
    <VStack
      id="master_flex_header_navbar"
      justifyContent={"start"}
      alignItems="flex-start"
      spacing={4}
    >
      {
        open ? (
          <Menu>
            <MenuButton onClick={handleClick}>
              <Flex  >
                <TbLogout style={{ width: "25px", height: "25px", color: "white" }}
                />

                <Text fontSize="md" color="white" ml="2" >
                  Sign Out
                </Text>
              </Flex>
            </MenuButton>
          </Menu>
        ) : (
          <Menu>
            <MenuButton onClick={handleClick}>
              <Flex  >
                <TbLogout style={{ width: "25px", height: "25px", color: "white" }}
                />
              </Flex>
            </MenuButton>
          </Menu>)
      }
    </VStack>
  );
}

HeaderLinks.propTypes = {
  setSelected: PropTypes.func.isRequired, // Add setSelected prop validation
  open: PropTypes.bool, // Added 'open' prop validation
  variant: PropTypes.string,
  fixed: PropTypes.bool,
  secondary: PropTypes.bool,
  onOpen: PropTypes.func,
};